import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, forwardRef } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// Material Table Icons Imports
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,

} from '@mui/material';

import ApplyLeave from './ApplyLeave';
import EditAppliedLeave from './EditAppliedLeave';
import LeaveCertificate from './LeaveCertificate';
import MaterialTable from 'material-table';
// components
// import Label from '../../components/label';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from 'src/components/Scrollbar';


// ----------------------------------------------------------------------
// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// ----------------------------------------------------------------------


export default function LeavePage() {
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [attendanceFrom, setAttendanceFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [attendanceTo, setAttendanceTo] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [editData, setEditData] = useState(null);



  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // --------------------- Edit Modal--------------------------
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  // --------------------- PDF Modal--------------------------
    const [openPDFModal, setOpenPDFModal] = useState(null);
    const handleOpenPDFModal = () => setOpenPDFModal(true);
    const handleClosePDFModal = () => setOpenPDFModal(false);


  const handleDelete = (_id) => {
    fetch(`http://192.168.18.135:3000/delete/${_id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Employee was successfully deleted
        console.log(' User Deleted Successfully !');
      })
      .catch((error) => {
        // Handle error
        console.error('There was an error deleting the employee:', error);
      });
  };



  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetch('http://192.168.18.135:3000/show').then((result) => {
      result.json().then((response) => {
        console.log(response);
        setEmployees(response.message);
      });
    });
  }, []);
  // console.log("Employees are:", employees)

  return (
    <>
      <Helmet>
        <title> AIODOCK | Employee </title>
      </Helmet>

      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Employees
            </Typography>
            <Button variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
              Apply Leave
            </Button>
            {openModal && <ApplyLeave onClose={handleCloseModal} />}
          </Stack>
        </LocalizationProvider>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>

              <MaterialTable
                title="Leaves Applied"
                icons={tableIcons}
                data={tableData}
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Branch', field: 'branch' },
                  { title: 'Designation', field: 'designation' },
                  { title: 'Department', field: 'department' },
                  { title: 'No of Days', field: 'noOfDays' },
                  {
                    title: 'Actions',
                    field: '',
                    render: (rowData) => (
                      <>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={() => {
                            setEditData(rowData);
                            handleOpenEditModal();
                          }}
                        >
                          {openEditModal && <EditAppliedLeave data={editData} onClose={handleCloseEditModal} />}
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>
                        <IconButton
                          sx={{ color: 'primary' }}
                          size="large"
                          color="inherit"
                        onClick={() => {
                          setEditData(rowData);
                          handleOpenPDFModal();
                        }}
                        >
                          {openPDFModal && <LeaveCertificate data={editData} onClose={handleClosePDFModal} />}

                          <Iconify icon={'material-symbols:print'} />
                        </IconButton>
                        <IconButton
                          sx={{ color: 'error.main' }}
                          size="large"
                          color="inherit"
                          onClick={() => handleDelete(rowData._id)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />

            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}

                
         
const tableData = [
  { name: 'John Doe', branch: 'ABC Branch', designation: 'Manager', department: 'Sales', noOfDays:'3' },
  { name: 'Jane Smith', branch: 'XYZ Branch', designation: 'Supervisor', department: 'Marketing', noOfDays:'1' },
  // Add more employee objects as needed
];