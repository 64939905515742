import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({deleteCatData,deleteCategory, setDeleteCat}) {
  const [open, setOpen] = React.useState(false);
  // const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    if(!open){
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteCat(false);
  };
  const deleteSpecficCat=()=>{
    deleteCategory(deleteCatData)
  }

  return (
    <div>
     
      <Dialog
        // open={open}
        open={handleClickOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Category"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {/* Are you Sure? You want to delete category */}
           Are you sure? You want to delete this category.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteSpecficCat} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
