// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Most Selling Items",
    path: "/dashboard/topSales",
    icon: getIcon("flat-color-icons:sales-performance"),
  },
  {
    title: "Daily Report",
    path: "/dashboard/dailyReport",
    icon: getIcon("ph:calendar-bold"),
  },
  {
    title: "Minimum Stock",
    path: "/dashboard/minimumStock",
    icon: getIcon("healthicons:rdt-result-out-stock"),
  },
  {
    title: "Stock Management",
    path: "/dashboard/stock",
    icon: getIcon("bi:inboxes-fill"),
  },
  {
    title: "Manage Items & Categories",
    path: "/dashboard/manageItem",
    icon: getIcon("icon-park:ad-product"),
  },
  {
    title: "Manage Clients",
    path: "/dashboard/clientManage",
    icon: getIcon("raphael:customer"),
  },
  {
    title: "Sales & Purchases",

    icon: getIcon("fluent:tray-item-add-24-filled"),
    children: [
      {
        title: "Purchase",
        path: "/dashboard/purchase",
        icon: getIcon("fluent:tray-item-add-24-filled"),
      },
      {
        title: "Purchase Return",
        path: "/dashboard/purchasereturn",
        icon: getIcon("fontisto:arrow-return-right"),
      },
      {
        title: "Sale",
        path: "/dashboard/sale",
        icon: getIcon("fluent:tray-item-remove-20-filled"),
      },
      {
        title: "Sale Return",
        path: "/dashboard/salereturn",
        icon: getIcon("fontisto:arrow-return-left"),
      },
    ],
  },

  {
    title: "Accounting",

    icon: getIcon("bi:receipt"),
    children: [
      {
        title: "Receipt",
        path: "/dashboard/receipt",
        icon: getIcon("bi:receipt"),
      },
      {
        title: "Payment",
        path: "/dashboard/payment",
        icon: getIcon("fluent:payment-28-filled"),
      },
      {
        title: "JV",
        path: "/dashboard/jv",
        icon: getIcon("fluent:receipt-money-24-regular"),
      },
      {
        title: "Chart of Accounts",
        path: "/dashboard/chartOfAccounts",
        icon: getIcon("bi:bar-chart-steps"),
      },
    ],
  },

  {
    title: "Reporting",

    icon: getIcon("emojione-v1:ledger"),
    children: [
      {
        title: "General Ledger",
        path: "/dashboard/generalLedger",
        icon: getIcon("emojione-v1:ledger"),
      },
      {
        title: "Trial Balance",
        path: "/dashboard/trailBalance",
        icon: getIcon("emojione:balance-scale"),
      },
      {
        title: "Profit & Loss",
        path: "/dashboard/profitLoss",
        icon: getIcon("entypo:line-graph"),
      },
      {
        title: "Balance Sheet",
        path: "/dashboard/balanceSheet",
        icon: getIcon("flat-color-icons:data-sheet"),
      },
      {
        title: "Purchase Report",
        path: "/dashboard/purchaseReport",
        icon: getIcon("fluent:tray-item-add-24-filled"),
      },
      {
        title: "Sale Report",
        path: "/dashboard/saleReport",
        icon: getIcon("fluent:tray-item-remove-20-filled"),
      },
      {
        title: "Received Report",
        path: "/dashboard/receivedReport",
        icon: getIcon("fluent:tray-item-remove-20-filled"),
      },
      {
        title: "Payment Report",
        path: "/dashboard/paymentReport",
        icon: getIcon("fluent:tray-item-remove-20-filled"),
      },
      {
        title: "Profit Analytics",
        path: "/dashboard/profitAnalysis",
        icon: getIcon("ant-design:stock-outlined"),
      },
    ],
  },

  {
    title: "CASH IN HAND",
    path: "/dashboard/cashInHand",
    icon: getIcon("bi:cash-coin"),
  },
  // {
  //   title: "Configuration",
  //   path: "/dashboard/configuration",
  //   icon: getIcon("icon-park-outline:config"),
  // },
  // {
  //   title: "Users & Roles",
  //   path: "/dashboard/userRoles",
  //   icon: getIcon("carbon:user-role"),
  // },
  {
    title: "Logout",
    path: "/dashboard/logout",
    icon: getIcon("material-symbols:logout"),
  },
  // {
  //   title: "HRM",
  //   path: "/dashboard/hrm",
  //   icon: getIcon("raphael:customer"),
  //   children: [
  //     {
  //       title: "employee",
  //       path: "/dashboard/hrm/employee",
  //       icon: getIcon("eva:pie-chart-2-fill"),
  //     },
  //     {
  //       title: "leaves",
  //       path: "/dashboard/hrm/leaves",
  //       icon: getIcon("eva:pie-chart-2-fill"),
  //     },
  //     {
  //       title: "attendance",
  //       path: "/dashboard/hrm/attendance",
  //       icon: getIcon("eva:pie-chart-2-fill"),
  //     },
  //   ],
  // },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
