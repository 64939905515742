import React, { Component } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, TextField, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import url from "src/serverURL";
import Assets from "./Assets";
import Liabilities from "./Liabilities";
import OwnersEquity from "./OwnersEuity";

class Navigation extends Component {
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  constructor(props) {
    super(props);
    this.state = {
      revenueData: [],
      expenseData: [],
      analyticsData: [],
      value: "1",
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Account Title",
          field: "title",
          sort: "asc",
        },
        {
          label: "Debit (OB)",
          field: "opening_debit",
          sort: "asc",
        },
        {
          label: "Credit (OB)",
          field: "opening_credit",
          sort: "asc",
        },
        {
          label: "Debit (During)",
          field: "process_debit",
          sort: "asc",
        },
        {
          label: "Credit (During)",
          field: "process_credit",
          sort: "asc",
        },
        {
          label: "Debit (CB)",
          field: "closing_debit",
          sort: "asc",
        },
        {
          label: "Credit (CB)",
          field: "closing_credit",
          sort: "asc",
        },
      ],
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date().setMonth(new Date().getMonth() - 1),
      date_to: new Date(),
      final_flag: "",
      total_opening_debit: 0,
      total_opening_credit: 0,
      total_process_debit: 0,
      total_process_credit: 0,
      total_closing_debit: 0,
      total_closing_credit: 0,
      cash: true,
      bank: false,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      save: false,
      avGbyInvoice: {},

      revenue: [],
      expense: [],
      analytics: [],
      asset: [],
      libilities: [],

      total_assets: 0,
      total_libilities: 0,

      total_owner_equity: 0,
      owner_equity: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount() {}
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentDidMount() {
    this.fetchData();
  }
  onSubmit(e) {
    e.preventDefault();
    this.fetchData();
  }

  fetchData() {
    this.setState({ total_opening_credit: 0 });
    this.setState({ total_opening_debit: 0 });
    this.setState({ total_process_credit: 0 });
    this.setState({ total_process_debit: 0 });
    var data = {
      from: this.state.date_from,
      to: this.state.date_to,
    };
    this.setState({ save: true });

    data.clienttype = "";

    var OpeningDebit_COS = 0;
    var OpeningCredit_COS = 0;
    var ProcessDebit = 0;
    var ProcessCredit = 0;

    fetch(url + "inventry/reporting/avGbyInvoice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((avGbyInvoice) => {
        this.setState({ avGbyInvoice: avGbyInvoice });

        fetch(url + "inventry/reporting/costofsales", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((transactions) => {
            //  console.log(transactions)
            var Opening1 = transactions.Opening;
            var During1 = transactions.During;

            //////////////////// Sorting Opening Data
            var key = Object.keys(Opening1).sort(function order(key1, key2) {
              if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date))
                return -1;
              else if (
                new Date(Opening1[key1].date) > new Date(Opening1[key2].date)
              )
                return +1;
              else return 0;
            });

            var Opening = {};

            for (var i = 0; i < key.length; i++) {
              Opening[key[i]] = Opening1[key[i]];
              delete Opening1[key[i]];
            }

            ///////////////////

            //////////////////// Sorting During Data
            var key = Object.keys(During1).sort(function order(key1, key2) {
              if (new Date(During1[key1].date) < new Date(During1[key2].date))
                return -1;
              else if (
                new Date(During1[key1].date) > new Date(During1[key2].date)
              )
                return +1;
              else return 0;
            });

            var During = {};

            for (var i = 0; i < key.length; i++) {
              During[key[i]] = During1[key[i]];
              delete During1[key[i]];
            }

            ///////////////////

            var openingBalance = 0;
            var OpeningCredit = 0;
            var OpeningDebit = 0;
            // Opening

            Object.keys(Opening).map((key) => {
              if (key.includes("WV-S-") || key.includes("WV-PR-")) {
                OpeningDebit = OpeningDebit + this.state.avGbyInvoice[key];
              }
            });

            var openDiff = OpeningDebit;
            // SetUp the Opening of Cost of Sales
            if (openDiff > 0) {
              OpeningDebit_COS = openDiff;
            } else {
              OpeningCredit_COS = Math.abs(openDiff);
            }
            this.setState({ opening_flag: "Dr" });

            this.setState({ opening_balance: Math.abs(openDiff) });

            var OpeningBalanceValue = openDiff;
            var DuringCreditBalance = 0;
            var DuringDebitBalance = 0;
            var the_rows = [];

            var totalDebit = 0;
            var totalCredit = 0;
            var totalBalance = 0;
            var openDiff = 0;
            var flag = "";
            var counter = 0;
            // During

            Object.keys(During).map((key) => {
              var debit = 0;
              var credit = 0;
              var type = "";

              if (key.includes("WV-S-") || key.includes("WV-PR-")) {
                if (key.includes("WV-S-")) {
                  type = "Sale";
                }
                if (key.includes("WV-PR-")) {
                  type = "Purchase Return";
                }
                totalDebit = totalDebit + this.state.avGbyInvoice[key];
                if (counter == 0) {
                  DuringDebitBalance =
                    DuringDebitBalance +
                    this.state.avGbyInvoice[key] +
                    OpeningBalanceValue;
                } else {
                  DuringDebitBalance =
                    DuringDebitBalance + this.state.avGbyInvoice[key];
                }
                counter++;
                debit = this.state.avGbyInvoice[key];
              }

              var date = new Date(During[key].date);
              var d = date.getDate();
              var m = date.getMonth() + 1;
              var y = date.getFullYear();

              openDiff = DuringDebitBalance;

              flag = "Dr";

              //  the_rows.push(put_data)
            });
            if (flag == "Dr") {
              ProcessCredit = totalCredit;
              ProcessDebit = totalDebit;
              // ClosingDebit = Math.abs(openDiff);
            } else {
              ProcessCredit = totalCredit;
              ProcessDebit = totalDebit;
              // ClosingCredit = Math.abs(openDiff);
            }

            //////////////////////////////////////////////// Calling Up the Trail Balance
            var revenue = [];
            var expense = [];
            var owner_equity = [];
            var analytics = [];

            // 16 Apr 2022
            // The Previous Calculation of Profit & Loss will give us the gross profit.
            var asset = [];
            var libilities = [];

            fetch(url + "inventry/reporting/trial", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((transactions) => {
                var the_rows = [];
                var total_opening_credit = 0;
                var total_opening_debit = 0;
                var total_process_debit = 0;
                var total_process_credit = 0;
                var total_closing_debit = 0;
                var total_closing_credit = 0;
                transactions.push({
                  detail: { id: "C-1", name: "Cost of Sales", type: "COS" },
                  opening: {
                    credit: OpeningCredit_COS,
                    debit: OpeningDebit_COS,
                  },
                  process: { credit: ProcessCredit, debit: ProcessDebit },
                });

                var total_revenue = 0;
                var total_expense = 0;
                var total_owner_equity = 0;

                var total_assets = 0;
                var total_libilities = 0;

                var analytics = [];

                analytics.push({
                  title: "Cost of Sales",
                  value: this.numberWithCommas(
                    (ProcessDebit == "0"
                      ? ProcessCredit
                      : ProcessDebit
                    ).toFixed(2)
                  ),
                });

                transactions.map((data) => {
                  if (
                    data.detail.name == "Credit Sales" ||
                    data.detail.name == "Cash Sales"
                  ) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        data.process.credit?.toFixed(2) || 0
                      ),
                    };
                    total_revenue = total_revenue + data.process.credit;
                    revenue.push(put_data);
                  }

                  // Enable the oTHER iNCOME
                  if (
                    data.detail.name == "Discount" ||
                    data.detail.name == "Miscellaneous Income"
                  ) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        data.process.credit !== null &&
                          data.process.credit !== undefined
                          ? data.process.credit.toFixed(2)
                          : 0
                      ),
                    };
                    total_revenue = total_revenue + data.process.credit;
                    revenue.push(put_data);
                  }
                  if (
                    (data.detail.id + "").includes("E-") &&
                    (data.detail.id + "").split("-").length == 4
                  ) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        Math.abs(
                          data.process.debit - data.process.credit
                        ).toFixed(2)
                      ),
                    };
                    total_expense =
                      total_expense + parseFloat(data.process.debit);
                    expense.push(put_data);
                  }
                  // Owner Equity
                  if (
                    (data.detail.id + "").includes("O-1") &&
                    (data.detail.id + "").split("-").length == 4
                  ) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        Math.abs(
                          data.process.debit - data.process.credit
                        ).toFixed(2)
                      ),
                    };
                    total_owner_equity =
                      total_owner_equity +
                      parseFloat(
                        Math.abs(data.process.debit - data.process.credit)
                      );
                    owner_equity.push(put_data);
                  }
                  // Asset Component
                  if (
                    data.detail.type.includes("CASH") ||
                    data.detail.type.includes("BANK") ||
                    data.detail.type.includes("Non-Current Assets") ||
                    data.detail.type.includes("customer")
                  ) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        Math.abs(
                          data.process.debit - data.process.credit
                        ).toFixed(2)
                      ),
                    };
                    total_assets =
                      total_assets +
                      parseFloat(data.process.debit - data.process.credit);
                    asset.push(put_data);
                  }
                  // Inventory - Reverse Transaction - Cost of Sales
                  if (data.detail.type.includes("Inventory")) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        Math.abs(
                          data.process.debit - parseFloat(ProcessDebit)
                        ).toFixed(2)
                      ),
                    };
                    total_assets =
                      total_assets +
                      parseFloat(data.process.debit - parseFloat(ProcessDebit));
                    asset.push(put_data);
                  }

                  // Liabilities
                  if (
                    data.detail.type.includes("vendor") ||
                    data.detail.type.includes("Short Term Loan") ||
                    ((data.detail.id + "").includes("L-2") &&
                      (data.detail.id + "").split("-").length == 4)
                  ) {
                    var put_data = {
                      id: data.detail.id,
                      title: data.detail.name,
                      type: data.detail.type,
                      value: this.numberWithCommas(
                        Math.abs(
                          data.process.debit - data.process.credit
                        ).toFixed(2)
                      ),
                    };
                    total_libilities =
                      total_libilities +
                      parseFloat(
                        Math.abs(data.process.debit - data.process.credit)
                      );
                    libilities.push(put_data);
                  }
                });
                var gross_profit =
                  total_revenue -
                  (ProcessDebit == "0" ? ProcessCredit : ProcessDebit);
                analytics.push({
                  title: "Gross Profit",
                  value: this.numberWithCommas(gross_profit.toFixed(2)),
                });
                analytics.push({
                  title: "Total Expense",
                  value: this.numberWithCommas(total_expense.toFixed(2)),
                });
                var net_profit_before_depreciation =
                  gross_profit - total_expense;
                analytics.push({
                  title: "Net Profit Before Depreciation",
                  value: this.numberWithCommas(
                    net_profit_before_depreciation.toFixed(2)
                  ),
                });
                analytics.push({
                  title: "Depreciation",
                  value: 0,
                });
                analytics.push({
                  title: "Net Profit After Depreciation",
                  value: this.numberWithCommas(
                    net_profit_before_depreciation.toFixed(2)
                  ),
                });
                analytics.push({
                  title: "Net Profit Before Tax",
                  value: this.numberWithCommas(
                    net_profit_before_depreciation.toFixed(2)
                  ),
                });
                analytics.push({
                  title: "Income Tax",
                  value: 0,
                });
                analytics.push({
                  title: "Net Profit",
                  value: this.numberWithCommas(
                    net_profit_before_depreciation.toFixed(2)
                  ),
                });

                // Adding Net Profit to Owner's Equity
                var put_data = {
                  id: "P-1",
                  title: "Profit & Loss",
                  type: "Profit & Loss",
                  value: this.numberWithCommas(
                    Math.abs(net_profit_before_depreciation).toFixed(2)
                  ),
                };
                total_owner_equity =
                  total_owner_equity +
                  parseFloat(Math.abs(net_profit_before_depreciation));
                owner_equity.push(put_data);
                this.setState({ revenue: revenue });
                this.setState({ expense: expense });
                this.setState({ analytics: analytics });
                this.setState({ libilities: libilities });
                this.setState({ asset: asset });
                this.setState({ owner_equity: owner_equity });
                this.setState({ total_owner_equity: total_owner_equity });
                this.setState({ total_assets: total_assets });
                this.setState({ total_libilities: total_libilities });
                this.setState({ save: false });
              });
          })
          .catch(() => {
            this.setState({ save: false });
          });
      });
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };

  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };

  render() {
    const { value } = this.state;

    return (
      <>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderColor: "divider", marginBottom: "0.5em" }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TabList
                    onChange={this.handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .css-6d3pg0-MuiTabs-indicator": {
                        backgroundColor:
                          value === "1"
                            ? "#01579b !important"
                            : value === "2"
                            ? "#FF0000 !important"
                            : value === "3"
                            ? "#069837 !important"
                            : "",
                      },
                    }}
                  >
                    <Tab
                      label="Assests"
                      value="1"
                      sx={{
                        "&:hover": { color: "#01579b" },
                        "&.Mui-selected": { color: "#01579b" },
                      }}
                    />
                    <Tab
                      label="Liabilties"
                      value="2"
                      sx={{
                        "&:hover": { color: "#FF0000" },
                        "&.Mui-selected": { color: "#FF0000" },
                      }}
                    />
                    <Tab
                      label="Owners Equity"
                      value="3"
                      sx={{
                        "&:hover": { color: "#069837" },
                        "&.Mui-selected": { color: "#069837" },
                      }}
                    />
                  </TabList>
                </Grid>
                <Grid item xs={6}>
                  <form onSubmit={this.onSubmit} className="Notifications">
                    <Grid container spacing={2}>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={5}>
                        <b>UpTo :</b>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            value={this.state.date_to}
                            onChange={(date_to) => {
                              this.setState({ date_to: date_to });
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                            maxDate={new Date()}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={2}>
                        <br />
                        <Button
                          variant="contained"
                          size="md"
                          block
                          type="submit"
                          // disabled={this.state.save}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Box>
            <TabPanel value="1" style={{ padding: 0 }}>
              <Assets
                assetsData={this.state.asset}
                loading={this.state.save}
                totalAssets={this.state.total_assets}
              />
            </TabPanel>
            <TabPanel value="2" style={{ padding: 0 }}>
              <Liabilities
                libilitiesData={this.state.libilities}
                loading={this.state.save}
                totalLiabilities={this.state.total_libilities}
              />
            </TabPanel>
            <TabPanel value="3" style={{ padding: 0 }}>
              <OwnersEquity
                analyticsData={this.state.owner_equity}
                loading={this.state.save}
                totalOwnerEquity={this.state.total_owner_equity}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </>
    );
  }
}

export default Navigation;
