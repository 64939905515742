import * as React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Button, Container, Typography } from '@mui/material';
// Calendar
import RevoCalendar from 'revo-calendar';
import Iconify from '../../../components/iconify/Iconify';
// ----------------------------------------------------------------------

const eventList = [
  {
    name: 'Kashmir Day',
    description: 'Vacation  for 1 day.',
    date: new Date('Feb 05 2023'),
    allDay: true,
  },
  {
    name: 'Reservation',
    date: new Date('Feb 01 2023'),
    allDay: true,
  },
];
export default function Attendance() {
  return (
    <>
      <Helmet>
        <title> Attendance| Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Attendance
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Mark Attendance
          </Button>
        </Stack>
        <RevoCalendar
          events={eventList}
          style={{
            borderRadius: '5px',
            border: '5px solid #4F6995',
          }}
          lang="en"
          primaryColor="#374E5C"
          secondaryColor="#FFFFFF"
          todayColor="#3B3966"
          textColor="#333333"
          indicatorColor="orange"
          sidebarWidth={180}
          detailWidth={280}
        />
      </Container>
    </>
  );
}
