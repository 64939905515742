import React, { Component, useEffect } from "react";
// routes
import Router from "./routes";

import { useLocation } from "react-router-dom";
// theme
import ThemeProvider from "./theme";

// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { useCallbackPrompt } from "./useCallbackPrompt";
import DialogBox from "./DialogBox";
import Scrollbar from "./ColoredScrollbar";

export default function App() {
  let location = useLocation();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  useEffect(() => {
    var arr = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    arr.map(() => {
      window.history.pushState(null, null, null);
    });

    console.log("assas");
  }, [location]);

  return (
    <ThemeProvider>
      <Scrollbar>
        <Router />
        <ScrollToTop />
        <BaseOptionChartStyle />
      </Scrollbar>
    </ThemeProvider>
  );
}
