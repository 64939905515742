import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";

const StyledImgPreview = styled("img")({
  maxWidth: "100%",
  maxHeight: "200px",
  marginTop: "10px",
});

const FormValidationTooltip = styled(Tooltip)({
  fontSize: "14px",
});

export default function Config() {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    logo: null,
  });

  const [ownerInfo, setOwnerInfo] = useState({
    firstName: "",
    lastName: "",
    ownerPhone: "",
  });

  const [accountInfo, setAccountInfo] = useState({
    ownerEmail: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const allowedExtensions = [".jpg", ".jpeg", ".png"];
    const fileExtension = file.name.slice(
      ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
    );

    if (allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      setCompanyInfo((prevInfo) => ({ ...prevInfo, logo: file }));
    } else {
      alert("Please select a valid image file (jpg, jpeg, or png).");
    }
  };

  const handleOwnerInfoChange = (e) => {
    const { name, value } = e.target;
    setOwnerInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleAccountInfoChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo((prevInfo) => ({ ...prevInfo, [name]: value }));

     // Email validation for ownerEmail
  if (name === "ownerEmail") {
    setEmailError(!validateEmail(value) ? "Invalid email" : "");
  }

    // Password validation
  if (name === "password") {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (value.length === 0) {
      setPasswordError("Password is required");
    } else if (!passwordRegex.test(value)) {
      setPasswordError(
        "Password should be at least 8 characters, include at least 1 uppercase letter, and 1 number"
      );
    } else {
      setPasswordError("");
    }
  }

  // Confirm password validation
  if (name === "confirmPassword") {
    if (value.length === 0) {
      setConfirmPasswordError("Confirm Password is required");
    } else if (value !== accountInfo.password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^[0-9]+$/;
    return regex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError(!validateEmail(companyInfo.email) ? "Invalid email" : "");
    setPhoneError(!validatePhone(companyInfo.phone) ? "Invalid phone" : "");

    if (
      validateEmail(companyInfo.email) &&
      validatePhone(companyInfo.phone) &&
      !passwordError &&
      !confirmPasswordError
    ) {
      // Handle form submission logic here
      console.log("Company Info:", companyInfo);
      console.log("Owner Info:", ownerInfo);
      console.log("Account Info:", accountInfo);
    }
  };
  
  return (
    <div>
      <h2>Company Configuration</h2>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Company Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Company Name"
                  variant="outlined"
                  name="companyName"
                  value={companyInfo.companyName}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Company Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  value={companyInfo.email}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                  helperText={emailError}
                  error={emailError !== ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Company Phone No."
                  variant="outlined"
                  type="tel"
                  name="phone"
                  value={companyInfo.phone}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                  helperText={phoneError}
                  error={phoneError !== ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  variant="outlined"
                  name="country"
                  value={companyInfo.country}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company Address"
                  variant="outlined"
                  name="address"
                  value={companyInfo.address}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="logo">Logo:</InputLabel>
                <Input
                  type="file"
                  name="logo"
                  onChange={handleFileChange}
                  accept=".jpg, .jpeg, .png"
                  style={{ marginBottom: "10px" }}
                />
                {companyInfo.logo && (
                  <StyledImgPreview
                    src={URL.createObjectURL(companyInfo.logo)}
                    alt="Logo Preview"
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ marginTop: "1em" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Owner Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={ownerInfo.firstName}
                  onChange={handleOwnerInfoChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={ownerInfo.lastName}
                  onChange={handleOwnerInfoChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  name="ownerPhone"
                  value={ownerInfo.ownerPhone}
                  onChange={handleOwnerInfoChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ marginTop: "1em" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <TextField
                  label="Email"
                  variant="outlined"
                  name="ownerEmail"
                  value={accountInfo.ownerEmail}
                  onChange={handleAccountInfoChange}
                  fullWidth
                  margin="normal"
                  required
                  helperText={emailError}
                  error={emailError !== ""}
                />
              </Grid>
              <Grid item sm={4} data-testid="password-input">
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  value={accountInfo.password}
                  onChange={handleAccountInfoChange}
                  fullWidth
                  margin="normal"
                  required
                  helperText={passwordError}
                  error={passwordError !== ""}
                />
              </Grid>
              <Grid item sm={4} data-testid="confirm-password-input">
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  value={accountInfo.confirmPassword}
                  onChange={handleAccountInfoChange}
                  fullWidth
                  margin="normal"
                  required
                  helperText={confirmPasswordError}
                  error={confirmPasswordError !== ""}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "1em", float: "right" }}
        //   disabled={
        //     !companyInfo.companyName ||
        //     !companyInfo.email ||
        //     !companyInfo.phone ||
        //     !companyInfo.country ||
        //     !companyInfo.address ||
        //     !companyInfo.logo ||
        //     !ownerInfo.firstName ||
        //     !ownerInfo.lastName ||
        //     !ownerInfo.ownerPhone ||
        //     !accountInfo.ownerEmail ||
        //     !accountInfo.password ||
        //     !accountInfo.confirmPassword ||
        //     passwordError !== "" ||
        //     confirmPasswordError !== "" ||
        //     emailError !== "" ||
        //     phoneError !== ""
        //   }
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
