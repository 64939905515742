import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { Grid, Container, Typography } from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import url from "../../serverURL.js";

export default function Edit({
  editData,
  editClose,
  handleClickError,
  handleClick,
}) {
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState("paper");
  const [form, setForm] = React.useState(editData);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    editClose();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleInputChange = (fieldName, value) => {
    console.log(fieldName);
    console.log(value);
    // Remove leading spaces and allow only one space
    const sanitizedValue = value.replace(/^\s+/g, "").replace(/\s+/g, " ");
    setForm({
      ...form,
      [fieldName]: sanitizedValue,
    });
    setTimeout(() => {
      console.log("I am that data" + form.email);
    }, 1000);
  };

  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };
  const submit = () => {
    if (form.name === undefined || form.name === "") {
      handleClickError("Invalid Client Name");
      return;
    }

    if (form.type === undefined || form.type === "") {
      handleClickError("Invalid Client Type");
      return;
    }

    if (form.email === undefined || form.email === "") {
      handleClickError("Invalid Client Email");
      return;
    }

    if (form.mobile === undefined || form.mobile === "") {
      handleClickError("Invalid Mobile Number");
      return;
    }

    if (form.ntn === undefined || form.ntn === "") {
      handleClickError("Invalid NTN Number");
      return;
    }
    if (form.stn === undefined || form.stn === "") {
      handleClickError("Invalid STN Number");
      return;
    }
    if (form.address === undefined || form.address === "") {
      handleClickError("Invalid Client Address");
      return;
    }
    if (form.debit === undefined || form.debit === "") {
      handleClickError("Invalid Client Debit");
      return;
    }
    if (form.credit === undefined || form.credit === "") {
      handleClickError("Invalid Client Credit");
      return;
    }

    var data = {
      id: form.id,
      name: form.name == undefined ? "" : form.name,
      mobile: form.mobile == undefined ? "" : form.mobile,
      type: form.type == undefined ? "" : form.type,
      email: form.email == undefined ? "" : form.email,
      ntn: form.ntn == undefined ? "" : form.ntn,
      stn: form.stn == undefined ? "" : form.stn,
      address: form.address == undefined ? "" : form.address,
      debit: form.debit == undefined ? "" : form.debit,
      credit: form.credit == undefined ? "" : form.credit,
    };
    fetch(url + "inventry/client", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        handleClickError();

        return;
      }
      handleClick("Updated Successfully!");

      handleClose();
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit Client</DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ maxWidth: "500px" }}
        >
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                label="Date"
                variant="filled"
                disabled
                value={getDate()}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-"
                label="Name"
                required
                variant="filled"
                value={form.name === undefined ? "" : form.name}
                // onChange={(e) => {
                //     setForm({
                //         ...form,
                //         name: e.target.value
                //     })

                // }}
                onChange={(e) => handleInputChange("name", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl sx={{ minWidth: 210 }}>
                {/* <InputLabel id="demo-simple-select-filled-label">
                  Type
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  variant="filled"
                  value={form.type}
                  required
                  label="Type"
                  style={{ marginTop: 0 }}
                  //   onChange={(e) => {
                  //     setForm({
                  //       ...form,
                  //       type: e.target.value,
                  //     });
                  //   }}
                  onChange={(e) => handleInputChange("type", e.target.value)}
                >
                  <MenuItem disabled value={undefined}>
                    Select Type
                  </MenuItem>
                  <MenuItem value={"vendor"}>Vendor</MenuItem>
                  <MenuItem value={"customer"}>Customer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Email"
                variant="filled"
                required
                value={form.email === undefined ? "" : form.email}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     email: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Mobile"
                variant="filled"
                required
                value={form.mobile === undefined ? "" : form.mobile}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     mobile: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("mobile", e.target.value)}
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="NTN No."
                variant="filled"
                required
                value={form.ntn === undefined ? "" : form.ntn}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     ntn: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("ntn", e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="STN No."
                variant="filled"
                required
                value={form.stn === undefined ? "" : form.stn}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     stn: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("stn", e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Address"
                variant="filled"
                required
                value={form.address === undefined ? "" : form.address}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     address: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("address", e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Debit"
                variant="filled"
                required
                value={form.debit === undefined ? "" : form.debit}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     debit: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("debit", e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-basic"
                label="Credit"
                variant="filled"
                required
                value={form.credit === undefined ? "" : form.credit}
                // onChange={(e) => {
                //   setForm({
                //     ...form,
                //     credit: e.target.value,
                //   });
                // }}
                onChange={(e) => handleInputChange("credit", e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submit}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
