import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { Grid, Container, Typography } from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";

import url from "../../serverURL.js";
export default function Edit({
  editData,
  editClose,
  handleClickError,
  handleClick,
}) {
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState("paper");
  const [form, setForm] = React.useState(editData);
  const [category, setCategories] = React.useState([]);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    editClose();
  };

  const descriptionElementRef = React.useRef(null);

  const handleInputChange = (fieldName, value) => {
    // Remove leading spaces and allow only one space
    const sanitizedValue = value.replace(/^\s+/g, "").replace(/\s+/g, " ");
    setForm({
      ...form,
      [fieldName]: sanitizedValue,
    });
  };

  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };
  const submit = () => {
    if (form.name === undefined || form.name.trim() === "") {
      handleClickError("Invalid User Name");
      return;
    }
    if (form.role === undefined || form.role === "") {
      handleClickError("Please Select a Role");
      return;
    }
    if (form.email === undefined || form.email.trim() === "") {
      handleClickError("Invalid Email");
      return;
    }
    if (form.password === undefined || form.password.trim() === "") {
      handleClickError("Invalid Password");
      return;
    }

    var data = {
      id:editData.id,
      name: form.name,
      password: form.password,
      email: form.email,
      role: form.role,
      userId: localStorage.getItem("id"),
    };

    fetch(url + "inventry/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        handleClickError();

        return;
      }
      handleClick("Updated Successfully!");

      handleClose();
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit User</DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          style={{ maxWidth: "500px" }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                fullWidth
                id="filled-required"
                required
                label="Name"
                variant="filled"
                value={form.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                id="filled-required"
                required
                label="Email"
                variant="filled"
                value={form.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />{" "}
            </Grid>
            <Grid item md={12}>
              <FormControl >
                <TextField
                  fullWidth
                  margin="normal"
                  id="outlined-select-currency"
                  select
                  label="Select Category"
                  variant="filled"
                  style={{ marginTop: 0 }}
                  value={form.role}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      role: e.target.value,
                    });
                  }}
                  // autoFocus
                >
                  {/* <MenuItem value={undefined}>Select</MenuItem> */}
                  <MenuItem disabled value={undefined}>
                    Select Role
                  </MenuItem>

                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Operator">Operator</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <TextField
                fullWidth
                id="filled-required"
                label="Password"
                required
                type="text"
                variant="filled"
                value={form.password}
                onChange={(e) => handleInputChange("password", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submit} variant="contained">
            Update User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
