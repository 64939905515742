import React from "react";
import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

import MaterialTable from "material-table";
import { Grid } from "@mui/material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Revenue = ({ revenueData, loading }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <MaterialTable
          isLoading={loading}
          title={<b>{"REVENUE"}</b>}
          icons={tableIcons}
          columns={[
            { title: "ID", field: "id" },
            {
              title: "Type",
              field: "type",
              defaultGroupSort: "desc",
              cellStyle: {
                color: "blue",
                fontWeight: "bold",
              },
            },
            {
              title: "Account Title",
              field: "title",
              cellStyle: {
                color: "red",
                fontWeight: "bold",
              },
            },
            {
              title: "PKR",
              field: "value",
              cellStyle: {
                color: "black",
                fontWeight: "bold",
              },
            },
          ]}
          data={revenueData}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
              whiteSpace: "nowrap",
              fontSize: "0.8em",
            },
            rowStyle: (rowData, index) => ({
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
              fontSize: "0.8em",
            }),
            paging: true,
            pageSize: 10,
            pageSizeOptions: false,
            showFirstLastPageButtons: false,
            exportButton: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Revenue;
