import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// @mui

import {
  Card,
  Box,
  FormHelperText,
  Divider,
  Table,
  Stack,
  Paper,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Avatar,
  Button,
  Popover,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Grid,
} from '@mui/material';
// components

export default function EditAppliedLeave(props) {
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const [openEditModal, setOpenEditModal] = useState(true);



  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openEditModal} onClose={props.onClose}>
              <Box sx={style}>
                <Typography sx={{ textAlign: 'center' }} id="modal-modal-title" variant="h3" component="h2">
                  Leave Application Form
                </Typography>
                {/* <Button onClick={handleCloseModal}></Button> */}
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <form>
                    <Grid lg={12} item container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          sx={{ width: 250 }}
                          id="stabdard-name-input"
                          label="Name"
                          value={props.data.name}
                          type="text"
                          autoComplete="current-text"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="standard-designation-input"
                          label="Designation"
                          value={props.data.role}
                          type="text"
                          autoComplete="current-designation"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="standard-department-input"
                          label="Department"
                          value={props.data.company}
                          type="text"
                          autoComplete="current-department"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="standard-phone-input"
                          label="Phone"
                          type="text"
                          autoComplete="current-phone"
                          variant="standard"
                        />
                        <br />
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">Leave Status</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value="full"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel value="full" control={<Radio />} label="Full Day" />
                            <FormControlLabel value="short" control={<Radio />} label="Short Day" />
                          </RadioGroup>
                        </FormControl>
                        <br />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Leave From
                        </Typography>{' '}
                        <DateTimePicker
                          value={leaveFrom}
                          onChange={(newValue) => {
                            setLeaveFrom(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" sx={{ width: 300 }} {...params} />}
                        />{' '}
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Leave To
                        </Typography>{' '}
                        <DateTimePicker
                          value={leaveTo}
                          onChange={(newValue) => {
                            setLeaveTo(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" sx={{ width: 300 }} {...params} />}
                        />{' '}
                        <br />
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="outlined-days-input"
                          label="No. of Days"
                          type="text"
                          value={props.data.days}
                          autoComplete="current-days"
                          variant="outlined"
                          size="small"
                        />
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Description
                        </Typography>{' '}
                        <TextField
                          sx={{ width: 300 }}
                          id="standard-description-input"
                          type="text"
                          variant="outlined"
                          placeholder="Reason of Leave"
                          multiline
                          rows={3}
                          rowsMax={10}
                        />
                        <br />
                        <br />
                        <Button variant="contained">Submit</Button>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
