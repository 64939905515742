import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditPurchaseComponent from "./editPurchaseComponent";

// ServerURL
import url from "../../serverURL.js";

export default function Edit({ editData, editClose }) {
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState("paper");

  const handleClose = () => {
    setOpen(false);
    editClose();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullScreen={true}
        fullWidth={true}
        onClose={handleClose}
        style={{ maxHeight: "100vh" }}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* <DialogTitle id="scroll-dialog-title">
          Edit Purchase - {editData.invoiceno}
        </DialogTitle> */}
        <DialogContent style={{ padding: 0, maxHeight: "100vh" }}>
          <EditPurchaseComponent handleClose={handleClose} data={editData} />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
