import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";

// ServerURL
import url from "src/serverURL.js";
const useStyles = makeStyles({
  dialog: {
    position: "fixed",
    top: "0",
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddItem({
  handleClick,
  handleClickError,
  handleCloseModal,
  isOpen,
}) {
  const [form, setForm] = useState({});
  const [category, setCategories] = useState([]);
  // State for modal
  const [openModal, setOpenModal] = useState(false);

  // Function to open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const classes = useStyles();

  /// -->> Function to manage all the Inputs
  const handleInputChange = (fieldName, value) => {
    // Remove leading spaces and allow only one space
    const sanitizedValue = value.replace(/^\s+/g, "").replace(/\s+/g, " ");
    setForm({
      ...form,
      [fieldName]: sanitizedValue,
    });
  };

  // -->> Date Function
  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };

  // -->> Submit Data onto the Server
  const submit = () => {
    if (form.name === undefined || form.name.trim() === "") {
      handleClickError("Invalid User Name");
      return;
    }
    if (form.role === undefined || form.role === "") {
      handleClickError("Please Select a Role");
      return;
    }
    if (form.email === undefined || form.email.trim() === "") {
      handleClickError("Invalid Description");
      return;
    }
    if (form.password === undefined || form.password.trim() === "") {
      handleClickError("Invalid Password");
      return;
    }

    var data = {
      name: form.name,
      password: form.password,
      email: form.email,
      role: form.role,
      userId: localStorage.getItem("id"),
    };
    fetch(url + "inventry/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 409) {
        handleClickError("User Already Exists !");
        return;
      }
      if (res.status == 400) {
        handleClickError();
        return;
      }
      setForm({});
      handleClick("User Added Successfully !");
      handleCloseModal();
    });
  };

  return (
    <>
      <Dialog open={isOpen} TransitionComponent={Transition}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent style={{ maxWidth: "500px" }}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <TextField
                fullWidth
                id="filled-required"
                required
                label="Name"
                variant="filled"
                value={form.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                id="filled-required"
                required
                label="Email"
                variant="filled"
                value={form.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />{" "}
            </Grid>
            <Grid item md={12}>
              <FormControl sx={{ minWidth: 215 }}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="outlined-select-currency"
                  select
                  label="Select Category"
                  variant="filled"
                  style={{ marginTop: 0 }}
                  value={form.role}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      role: e.target.value,
                    });
                  }}
                  // autoFocus
                >
                  {/* <MenuItem value={undefined}>Select</MenuItem> */}
                  <MenuItem disabled value={undefined}>
                    Select Role
                  </MenuItem>

                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Operator">Operator</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <TextField
                fullWidth
                id="filled-required"
                label="Password"
                required
                type="text"
                variant="filled"
                value={form.password}
                onChange={(e) => handleInputChange("password", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={submit} variant="contained">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
