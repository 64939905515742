import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddItem from "./addItem";
import Category from "./category";

export default function Navigation() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Manage Items" value="1" />
            <Tab label="Manage Category" value="2" />
          </TabList>
        </Box>
        <TabPanel style={{ padding: 0 }} value="1">
          <AddItem />
        </TabPanel>
        <TabPanel style={{ padding: 0 }}  value="2">
          <Category />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
