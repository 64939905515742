import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

export default class ColoredScrollbars extends Component {
  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: "rgb(1, 87, 155)",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    return (
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        {...this.props}
      />
    );
  }
}