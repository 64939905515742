import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// @mui

import {
  Box,
  FormHelperText,
  Stack,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
} from '@mui/material';
// components

export default function EditEmployee(props) {
  // date n timne
  const [joining, setJoining] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [attendanceFrom, setAttendanceFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [attendanceTo, setAttendanceTo] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [result, setResult] = useState(null);
  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  const [formData, setFormData] = useState(props.data);
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...formData,
      Joining: joining,
    
    };
    console.log("ID is: ", formData._id)
    fetch(`http://192.168.18.135:3000/update/${formData._id}`, {
      method: 'PUT',
      body: JSON.stringify(updatedData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Update successful:', data);
        // handle success response
      })
      .catch((error) => {
        console.error('Update failed:', error);
        // handle error response
      });
  };
    


  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
      Joining: joining.toISOString(),
    }));
  };
  // --------------------- Edit Modal-------------------------------
  const [openEditModal, setOpenEditModal] = useState(true);
  const [closeEditModal, setCloseEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openEditModal} onClose={props.onClose}>
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Update Employee Information
                </Typography>
                {result && <p className={`${result.success ? 'success' : 'error'}`}>{result.message}</p>}
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <form onSubmit={handleSubmit}>
                    <Grid lg={12} item container spacing={2}>
                      <Grid item xs={12} lg={4}>
                        <MobileDatePicker
                          id="stabdard-date-input"
                          label="Joined"
                          name='joining'
                          inputFormat="MM/DD/YYYY"
                          value={formData.joining}
                          onChange={(newValue) => {
                            setJoining(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} variant="standard" />}
                        />
                        <br />
                        <TextField
                          id="stabdard-branch-input"
                          label="branch"
                          value={formData.branch}
                          type="text"
                          name="branch"
                          onChange={handleChangeData}
                          autoComplete="current-text"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          id="stabdard-name-input"
                          label="Name"
                          type="text"
                          name="name"
                          onChange={handleChangeData}
                          value={formData.name}
                          autoComplete="current-text"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          id="standard-cnic-input"
                          label="CNIC"
                          type="text"
                          name="cnic"
                          onChange={handleChangeData}
                          value={formData.cnic}
                          autoComplete="current-password"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          id="standard-father-input"
                          label="Father Name"
                          name="fatherName"
                          onChange={handleChangeData}
                          type="text"
                          value={formData.fatherName}
                          autoComplete="current-father"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          style={{ width: '80%' }}
                          id="standard-add-input"
                          label="Address"
                          name="address"
                          onChange={handleChangeData}
                          value={formData.address}
                          type="text"
                          autoComplete="current-address"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          id="standard-phone-input"
                          label="Phone"
                          name="phone"
                          onChange={handleChangeData}
                          value={formData.phone}
                          type="text"
                          autoComplete="current-phone"
                          variant="standard"
                        />
                        <br />
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value="female"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="female"
                              name="gender"
                              defaultValue={formData.gender}
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="male"
                              name="gender"
                              defaultValue={formData.gender}
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Male"
                            />
                          </RadioGroup>
                        </FormControl>{' '}
                        <br />
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">Martial Status</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value="married"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Married"
                              name="martialStatus"
                              defaultValue={formData.martialStatus}
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Married"
                            />
                            <FormControlLabel
                              value="Unmarried"
                              name="martialStatus"
                              defaultValue={formData.martialStatus}
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Unmarried"
                            />
                          </RadioGroup>
                        </FormControl>{' '}
                        <br />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="standard-designation-input"
                          label="Designation"
                          name="designation"
                          value={formData.designation}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-designation"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          id="standard-department-input"
                          label="Department"
                          onChange={handleChangeData}
                          type="text"
                          name="department"
                          value={formData.department}
                          autoComplete="current-department"
                          variant="standard"
                        />
                        <br />
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Attendance Timing
                        </Typography>{' '}
                        <MobileTimePicker
                          value={attendanceFrom}
                          onChange={(newValue) => {
                            setAttendanceFrom(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" sx={{ width: 100 }} {...params} />}
                        />{' '}
                        &nbsp; <b>TO</b> &nbsp;
                        <MobileTimePicker
                          value={attendanceTo}
                          onChange={(newValue) => {
                            setAttendanceTo(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" sx={{ width: 100 }} {...params} />}
                        />{' '}
                        <br />
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Salary &nbsp;
                          <sub>( Package Structure )</sub>
                        </Typography>{' '}
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Basic</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            value={formData.basicSalary}
                            name="BasicSalary"
                            onChange={handleChangeData}
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">H.R.M</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            name="hrm"
                            value={formData.hrm}
                            onChange={handleChangeData}
                            size="small"
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Conveyance</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            name="conveyance"
                            value={formData.conveyance}
                            onChange={handleChangeData}
                            label="Amount"
                            size="small"
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Medical</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            name="medical"
                            value={formData.medical}
                            onChange={handleChangeData}
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Gross Salary</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            name="grossSalary"
                            value={formData.grossSalary}
                            onChange={handleChangeData}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Box mt={2} mb={2} height={120} width={120} border={2} textAlign="center">
                          {imageUrl && selectedImage && <img src={imageUrl} alt={selectedImage.name} height="100px" />}
                        </Box>
                        <input
                          accept="image/"
                          type="file"
                          id="select-image"
                          style={{ display: 'none' }}
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                        <label htmlFor="select-image">
                          <Button size="small" color="primary" component="span">
                            Upload Image
                          </Button>{' '}
                        </label>
                        <br />
                        <br />
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Overtime</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            aria-describedby="standard-amount-helper-text"
                            inputProps={{
                              'aria-label': 'amount',
                            }}
                            label="Amount"
                            name="overTime"
                            value={formData.overTime}
                            size="small"
                            onChange={handleChangeData}
                          />
                          <FormHelperText id="standard-weight-helper-text">Rate per Hour</FormHelperText>
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Provident Fund</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            aria-describedby="standard-amount-helper-text"
                            inputProps={{
                              'aria-label': 'amount',
                            }}
                            label="Amount"
                            size="small"
                            name="providentFund"
                            value={formData.providentFund}
                            onChange={handleChangeData}
                          />
                          <FormHelperText id="standard-weight-helper-text">Monthly Deductable</FormHelperText>
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">No. of Leaves</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Leaves #</InputAdornment>}
                            aria-describedby="standard-amount-helper-text"
                            inputProps={{
                              'aria-label': 'amount',
                            }}
                            label="Amount"
                            size="small"
                            value={formData.annualLeavesAllowed}
                            name="annualLeavesAllowed"
                            onChange={handleChangeData}
                          />
                          <FormHelperText id="standard-weight-helper-text">Anually Allowed</FormHelperText>
                        </FormControl>
                        <br />
                        <Button type="submit" variant="contained" color="primary">
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
