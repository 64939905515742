import React from "react";
import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

import MaterialTable from "material-table";
import { Grid, TextField } from "@mui/material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Expenses = ({ libilitiesData, totalLiabilities, loading }) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Grid>
      <Grid md={12}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <MaterialTable
              isLoading={loading}
              title={"Liabilities"}
              icons={tableIcons}
              columns={[
                { title:  "ID", field: "id" },
                {
                  title:  "Type",
                  field: "type",
                  defaultGroupSort: "desc",
                  cellStyle: {
                    color: "blue",
                    fontWeight: "bold",
                  },
                 
                },
                {
                  title:  "Account Title",
                  field: "title",
                  cellStyle: {
                    color: "red",
                    fontWeight: "bold",
                  },
                },
                {
                  title:  "PKR",
                  field: "value",
                  cellStyle: {
                    color: "black",
                    fontWeight: "bold",
                  },
                },
              ]}
              data={libilitiesData}
              options={{
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  whiteSpace: "nowrap",
                  fontSize: "0.8em",
                },
                rowStyle: (rowData, index) => ({
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",fontSize: "0.8em",
                }),
                paging: true,
                pageSize: 10,
                pageSizeOptions: false,
                showFirstLastPageButtons: false,
                exportButton: true,
              }}
            />
            <div style={{ display: "flex", flexDirection: "column", marginTop: "1em", }}>
              <b class="Total Liabilities">Total Liabilities</b>
              <TextField
                id="filled-basic"
                variant="filled"
                type="text"
                value={
                  totalLiabilities
                    ? numberWithCommas(totalLiabilities.toFixed(2))
                    : ""
                }
                style={{
                  fontWeight: "bold",
                }}
                required
                placeholder="Liabilities..."
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Expenses;
