import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";

// ServerURL
import url from "src/serverURL.js";
const useStyles = makeStyles({
  dialog: {
    position: "fixed",
    top: "auto",
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddItem({
  handleClick,
  handleClickError,
  handleCloseModal,
  isOpen,
}) {
  const [form, setForm] = useState({});
  const [name, setName] = useState("");
  const [category, setCategories] = useState([]);
  // State for modal
  const [openModal, setOpenModal] = useState(false);

  // Function to open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const classes = useStyles();

  /// -->> Function to manage all the Inputs
  // const handleInputChange = (fieldName, value) => {
  //   const sanitizedValue = value.replace(/^\s+/g, "").replace(/\s+/g, " ");
  //   setName(sanitizedValue);
  // };

  const handleInputChange = (fieldName, value) => {
    // Remove leading and trailing spaces
    const trimmedValue = value.trim();
    const sanitizedValue = trimmedValue.replace(/[^a-zA-Z0-9 ]/g, "");

    setName(sanitizedValue);
  };

  // -->> Date Function
  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };

  // -->> Submit Data onto the Server
  const submit = () => {
    if (name === undefined || name == "") {
      handleClickError("Invalid Category Name");
      return;
    }

    var category = {
      name: name,
      userId: localStorage.getItem("id"),
    };
    fetch(url + "inventry/category", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(category),
    }).then((res) => {
      if (res.status === 409) {
        handleClickError("Category Already Exists !");
        return;
      } else if (res.status === 400) {
        handleClickError("Category Creation Error !");
        return;
      }
      setForm({});
      handleClick("Category Added Successfully !");
      handleCloseModal();
    });
  };

  return (
    <>
      <Dialog
        // open={openModal}
        open={isOpen}
        // onClose={handleCloseModal}
        // fullWidth
        // maxWidth="lg"
        classes={{ paper: classes.dialog }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <Grid container>
            {/* <Grid item xs={1}></Grid> */}
            <Grid item md={6}>
              <TextField
                label="Date"
                required
                variant="filled"
                style={{ width: "95%" }}
                disabled
                value={getDate()}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                label="Name"
                required
                variant="filled"
                value={name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={submit} variant="contained">
            Add Category
          </Button>
        </DialogActions>
      </Dialog>

      {/* Existing code... */}
    </>
  );
}
