import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ChartofAccounts from "./ChartOfAccounts";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Scrollbar from "src/ColoredScrollbar";

export default function Navigation() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        "&::-webkit-scrollbar": {
          width: 0,
          height: 0,
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "95vh",
          typography: "body1",
        }}
      >
        <TabContext value={value}>
          <Box sx={{  }}>
            <TabList
              onChange={handleChange}
              sx={{
                "& .css-6d3pg0-MuiTabs-indicator": {
                  backgroundColor:
                    value === "1"
                      ? "#01579b !important"
                      : value === "2"
                      ? "#FF5600 !important"
                      : value === "3"
                      ? "#7000FF !important"
                      : value === "4"
                      ? "#069837 !important"
                      : value === "5"
                      ? "#C100BA !important"
                      : value === "6"
                      ? "#FF0000 !important"
                      : value === "7"
                      ? "#03CB17 !important"
                      : "",
                },
              }}
              aria-label="lab API tabs example"
            >
              <Tab
                label="Assets"
                value="1"
                sx={{
                  "&:hover": { color: "#01579b" },
                  "&.Mui-selected": { color: "#01579b" },
                }}
              />
              <Tab
                label="Revenue"
                value="2"
                sx={{
                  "&:hover": { color: "#FF5600" },
                  "&.Mui-selected": {
                    color: "#FF5600",
                  },
                }}
              />
              <Tab
                label="Current Liabilities"
                value="3"
                sx={{
                  "&:hover": { color: "#7000FF" },
                  "&.Mui-selected": { color: "#7000FF" },
                }}
              />
              <Tab
                label="Long Term Liabilities"
                value="4"
                sx={{
                  "&:hover": { color: "#069837" },
                  "&.Mui-selected": { color: "#069837" },
                }}
              />
              <Tab
                label="Owner's Equity"
                value="5"
                sx={{
                  "&:hover": { color: "#C100BA" },
                  "&.Mui-selected": { color: "#C100BA" },
                }}
              />
              <Tab
                label="Expenses"
                value="6"
                sx={{
                  "&:hover": { color: "#FF0000" },
                  "&.Mui-selected": { color: "#FF0000" },
                }}
              />
              <Tab
                label="Cost of Sales"
                value="7"
                sx={{
                  "&:hover": { color: "#03CB17" },
                  "&.Mui-selected": { color: "#03CB17" },
                }}
              />
            </TabList>
          </Box>

          {/* Assets */}
          <TabPanel value="1" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #01579b",
                maxHeight: "80vh",
                overflow: "auto",
                // position: "relative",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#01579b",
                  color: "#fff",
                  textAlign: "center",
                }}
                id={"assetData"}
              >
                <Typography>ASSETS</Typography>
              </Box>
              <Box id={"assetData"}>
                <ChartofAccounts type="asset" id={"assetData"} />
              </Box>
            </Paper>
          </TabPanel>

          {/* The Revenue */}
          <TabPanel value="2" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #FF5600",
                maxHeight: "80vh",
                overflow: "auto",
              }}
              id={"revenueData"}
            >
              <Box
                style={{
                  backgroundColor: "#FF5600",
                  color: "#fff",
                  textAlign: "center",
                }}
                id={"revenueData"}
              >
                <Typography>REVENUE</Typography>
              </Box>

              <Box id={"revenueData"}>
                <ChartofAccounts type="revenue" id={"revenueData"} />
              </Box>
            </Paper>
          </TabPanel>

          {/* Current Liabilities */}
          <TabPanel value="3" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #7000FF",
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#7000FF",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>CURRENT LIABILTIES</Typography>
              </Box>

              <Box>
                <ChartofAccounts
                  type="currentliabilities"
                  id={"liabilityData"}
                />
              </Box>
            </Paper>
          </TabPanel>

          {/* Long Term Liabilities */}
          <TabPanel value="4" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #069837",
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#069837",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>LONG TERM LIABILTIES</Typography>
              </Box>

              <Box>
                <ChartofAccounts
                  type="longtermliabilities"
                  id={"longtermliabilitiesData3"}
                />
              </Box>
            </Paper>
          </TabPanel>

          {/* Owners Equity */}
          <TabPanel value="5" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #C100BA",
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#C100BA",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>OWNERS EQUITY</Typography>
              </Box>

              <Box>
                <ChartofAccounts type="ownerequity" id={"ownerEquityData3"} />
              </Box>
            </Paper>
          </TabPanel>

          {/* Expenses */}
          <TabPanel value="6" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #FF0000",
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#FF0000",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>EXPENSES</Typography>
              </Box>

              <Box>
                <ChartofAccounts type="expense" id={"revenueData3"} />
              </Box>
            </Paper>
          </TabPanel>

          {/* Cost of Sales */}
          <TabPanel value="7" style={{ padding: 0 }}>
            <Paper
              variant="outlined"
              style={{
                border: "2px solid #03CB17",
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#03CB17",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>COST OF SALES</Typography>
              </Box>

              <Box>
                <ChartofAccounts type="costofsales" id={"revenueData3"} />
              </Box>
            </Paper>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
