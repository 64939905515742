import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";

import { Card, CardHeader, CardBody, Input } from "reactstrap";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";

// ServerURL
import url from "src/serverURL.js";

import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Autocomplete from "@mui/material/Autocomplete";
import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import TextField from "@mui/material/TextField";

import MaterialTable from "material-table";

import { Grid, Container, Typography, Toolbar, AppBar } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputAdornment from "@mui/material/InputAdornment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class PurchaseReturn extends Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          label: "Code",
          field: "code",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Rate",
          field: "rate",
          sort: "asc",
        },
        {
          label: "Quantity",
          field: "quantity",
          sort: "asc",
        },
        {
          label: "GST",
          field: "gst",
          sort: "asc",
        },
        {
          label: "Discount",
          field: "discount",
          sort: "asc",
        },

        {
          label: "Amount",
          field: "amount",
          sort: "asc",
        },
        {
          label: "Remove",
          field: "remove",
          sort: "asc",
        },
      ],
      rows: [],
      item_columns: [
        {
          label: "Code",
          field: "code",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Category",
          field: "category",
          sort: "asc",
        },
        {
          label: "Rate",
          field: "rate",
          sort: "asc",
        },
        {
          label: "Stock",
          field: "stock",
          sort: "asc",
        },
        {
          label: "Add",
          field: "add",
          sort: "asc",
        },
      ],
      bank_accounts: [],
      bank_data: null,
      bank: false,
      gst_bank: 0,
      AccountSelectedBank: {},
      // --------------------------------------------
      vendor_rows: [],
      customer_rows: [],
      cash_rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      code: 0,
      duplicate: [],

      name: "",
      cash_accounts: [],
      cash_data: null,
      min_stock: 0,
      rate: 0,
      load: true,
      options: [],

      TempDiscount: 0,
      DiscountPrice: 0,
      aLLocatedPerDiscount: 0,

      stock: 0,
      quantity: 0,
      save: false,
      gst_cash: 0,
      gst_customer: 0,
      gst_vendor: 0,
      gst_value: 0,
      barcode: 0,
      vendor: true,
      gst: false,
      sale_rate: 0,
      remarks: "",
      customer: false,
      vendor_data: null,
      table: {},
      profit_ratio: [],
      cash_profit: 0,
      customer_data: null,
      vendor_first: [],
      customer_first: [],
      cost: 0,
      show: false,
      cash: false,
      amount: 0,
      payable: 0,
      gst_price: 0,
      net_value: 0,
      invoice_items: [],
      discount: 0,
      alert: false,
      vendor_search_items: {},
      customer_search_items: {},
      cash_search_items: {},
      original_record: [],
      category: "",
      client_vendor: [],
      client_customer: [],
      add: false,
      title: "",
      body: "",
      date: new Date(),
      // 17 Aug 2021
      searchName: "",
      searchItems: [],
      // 19 Mar 2022
      AccountToCharge: "",
      AccountSelectedVendor: {},
      AccountSelectedCustomer: {},
      AccountSelectedAcc: {},

      openSearchItem: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.Reload = this.Reload.bind(this);
    this.AddLine = this.AddLine.bind(this);
    this.Add = this.Add.bind(this);
    this.ReCalculate = this.ReCalculate.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getSearchItems = this.getSearchItems.bind(this);
  }
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  componentWillMount() {
    fetch(url + "inventry/item", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((items) => {
        var rows = [];
        items.map((data) => {
          rows.push(data.name);
        });

        this.setState({ searchItems: rows });
        this.setState({ openSearchItem: false });
      });

    fetch(url + "inventry/client/vendor", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((clients) => {
        console.log(clients);
        this.setState({ client_vendor: clients });
        var objVender = {};
        clients.filter((client) => {
          var profits = this.state.profit_ratio;
          profits[client.id] = client.profit_ratio;

          objVender[client.name] = client;

          this.setState({ profit_ratio: profits });
        });

        this.setState({ AccountSelectedVendor: objVender });

        if (clients.length > 0) {
          this.setState({ vendor_data: clients[0] });
        }
      });
    fetch(url + "inventry/accounts/cash", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((cash_accounts) => {
        this.setState({ cash_accounts: cash_accounts });

        var objAcc = {};
        cash_accounts.filter((acc) => {
          objAcc[acc.title] = acc;
        });

        this.setState({ AccountSelectedAcc: objAcc });

        if (cash_accounts.length > 0) {
          this.setState({ cash_data: cash_accounts[0] });
        }
      });

    // -->> Bank Accounts
    fetch(url + "inventry/accounts/bank", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((bank_accounts) => {
        this.setState({ bank_accounts: bank_accounts });
        console.log("Bank Accounts----", bank_accounts);
        var objAcc = {};
        bank_accounts.filter((acc) => {
          objAcc[acc.title] = acc;
        });

        this.setState({ AccountSelectedBank: objAcc });

        if (bank_accounts.length > 0) {
          this.setState({ bank_data: bank_accounts[0] });
        }
      });

    fetch(url + "inventry/client/customer", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((clients) => {
        var objCustomer = {};
        clients.filter((client) => {
          var profits = this.state.profit_ratio;
          profits[client.id] = client.profit_ratio;

          objCustomer[client.name] = client;

          this.setState({ profit_ratio: profits });
        });
        this.setState({ AccountSelectedCustomer: objCustomer });

        this.setState({ client_customer: clients });
        if (clients.length > 0) {
          this.setState({ customer_data: clients[0] });
        }
      });
    fetch(url + "inventry/configure", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((configure) => {
        this.setState({ gst_cash: configure[0].cash });
        this.setState({ gst_customer: configure[0].customer });
        this.setState({ gst_vendor: configure[0].vendor });
        this.setState({ gst_bank: configure[0].bank });
        this.setState({ cash_profit: configure[0].cash_profit });
        this.setState({ load: false });
      });
  }
  Add(code, name, category, rate, stock, quantity) {
    this.setState({ code: code });
    this.setState({ name: name });
    this.setState({ rate: rate });
    this.setState({ sale_rate: rate });
    this.setState({ stock: stock });
    this.setState({ category: category });
    this.setState({ quantity: 0 });
    this.setState({ show: false });
  }
  Reload() {
    fetch(url + "inventry/client/vendor", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((clients) => {
        console.log(clients);
        this.setState({ client_vendor: clients });
        clients.filter((client) => {
          var profits = this.state.profit_ratio;
          profits[client.id] = client.profit_ratio;
          this.setState({ profit_ratio: profits });
        });
        if (clients.length > 0) {
          this.setState({ vendor_data: clients[0] });
        }
      });
    fetch(url + "inventry/client/customer", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((clients) => {
        console.log(clients);
        clients.filter((client) => {
          var profits = this.state.profit_ratio;
          profits[client.id] = client.profit_ratio;
          this.setState({ profit_ratio: profits });
        });

        this.setState({ client_customer: clients });
        if (clients.length > 0) {
          this.setState({ customer_data: clients[0] });
        }
      });
    fetch(url + "inventry/configure", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((configure) => {
        console.log(configure);
        this.setState({ gst_cash: configure[0].cash });
        this.setState({ gst_customer: configure[0].customer });
        this.setState({ gst_vendor: configure[0].vendor });
        this.setState({ gst_bank: configure[0].bank });
        this.setState({ cash_profit: configure[0].cash_profit });
      });
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  onSubmit() {
    this.setState({ save: true });
    var array = this.state.rows;
    var book_items = [];
    var configure = {
      gst_vendor: this.state.gst_vendor,
      gst_cash: this.state.gst_cash,
      gst_customer: this.state.gst_customer,
      gst_bank: this.state.gst_bank,
    };
    if (this.state.vendor) {
      if (
        this.state.AccountToCharge == "" ||
        this.state.AccountToCharge == null
      ) {
        this.setState({ title: "Alert" });
        this.setState({ body: "Invalid Client" });
        this.setState({ alert: true });
        this.setState({ save: false });
        return;
      }
    } else if (this.state.customer) {
      if (
        this.state.AccountToCharge == "" ||
        this.state.AccountToCharge == null
      ) {
        this.setState({ title: "Alert" });
        this.setState({ body: "Invalid Client" });
        this.setState({ alert: true });
        this.setState({ save: false });
        return;
      }
    } else if (this.state.cash) {
      if (
        this.state.AccountToCharge == "" ||
        this.state.AccountToCharge == null
      ) {
        this.setState({ title: "Alert" });
        this.setState({ body: "Invalid Client" });
        this.setState({ alert: true });
        this.setState({ save: false });
        return;
      }
    } else if (this.state.bank) {
      if (
        this.state.AccountToCharge == "" ||
        this.state.AccountToCharge == null
      ) {
        this.setState({ title: "Alert" });
        this.setState({ body: "Invalid Client" });
        this.setState({ alert: true });
        this.setState({ save: false });
        return;
      }
    }
    if (isNaN(this.state.payable)) {
      this.setState({ title: "Alert" });
      this.setState({ body: "Payable is Invalid Please!" });
      this.setState({ alert: true });
      this.setState({ save: false });
      return;
    }

    if (array.length > 0 && this.state.amount != 0) {
      if (this.state.cash) {
        var data = {
          clientid:
            this.state.AccountSelectedAcc[this.state.AccountToCharge].id,
          clientname:
            this.state.AccountSelectedAcc[this.state.AccountToCharge].title,
          clienttype:
            this.state.AccountSelectedAcc[this.state.AccountToCharge].type,
          amount: this.state.amount,
          payable: this.state.payable,
          configure: configure,
          discount: this.state.discount,
          items: this.state.rows,
          net_price: this.state.net_value,
          gst_price: this.state.gst_price,
          profit_ratio: this.state.cash_profit,
          gst: this.state.gst_value,
          apply_gst: this.state.gst,
          cash: true,
          item_codes: [],
          remarks: this.state.remarks,
          date: this.state.date,
          userId: localStorage.getItem("id"),
        };
        fetch(url + "inventry/purchasereturn", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            if (res.status == 400) {
              this.setState({ code: 0 });
              this.setState({ name: "" });
              this.setState({ rate: 0 });
              this.setState({ stock: 0 });
              this.setState({ quantity: 0 });
              this.setState({ rows: [] });
              this.setState({ amount: 0 });
              this.setState({ discount: 0 });
              this.setState({ payable: 0 });
              this.setState({ title: "Purchase Return" });
              this.setState({ body: "Invoice Already Exists!!" });
              this.setState({ alert: true });
              this.setState({ save: false });
            } else {
              this.setState({ code: 0 });
              this.setState({ name: "" });
              this.setState({ rate: 0 });
              this.setState({ stock: 0 });
              this.setState({ quantity: 0 });
              this.setState({ rows: [] });
              this.setState({ amount: 0 });
              this.setState({ discount: 0 });
              this.setState({ payable: 0 });
              this.setState({ title: "Purchase Return" });
              this.setState({ body: `Invoice No: ` + res.invoice });
              this.setState({ alert: true });
              this.setState({ save: false });
            }
          });
      } else {
        if (this.state.vendor) {
          var data = {
            clientid:
              this.state.AccountSelectedVendor[this.state.AccountToCharge].id,
            clientname:
              this.state.AccountSelectedVendor[this.state.AccountToCharge].name,
            clienttype: "vendor",
            amount: this.state.amount,
            net_price: this.state.net_value,
            configure: configure,
            gst_price: this.state.gst_price,
            profit_ratio: 0,
            gst: this.state.gst_value,
            apply_gst: this.state.gst,
            payable: this.state.payable,
            discount: this.state.discount,
            items: this.state.rows,
            userId: localStorage.getItem("id"),
            item_codes: [],
            cash: false,
            remarks: this.state.remarks,
            date: this.state.date,
          };
          fetch(url + "inventry/purchasereturn", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              if (res.status == 400) {
                this.setState({ code: 0 });
                this.setState({ name: "" });
                this.setState({ rate: 0 });
                this.setState({ stock: 0 });
                this.setState({ quantity: 0 });
                this.setState({ rows: [] });
                this.setState({ amount: 0 });
                this.setState({ discount: 0 });
                this.setState({ payable: 0 });
                this.setState({ title: "Purchase Return" });
                this.setState({ body: "Invoice Already Exists!!" });
                this.setState({ alert: true });
                this.setState({ save: false });
              } else {
                this.setState({ code: 0 });
                this.setState({ name: "" });
                this.setState({ rate: 0 });
                this.setState({ stock: 0 });
                this.setState({ quantity: 0 });
                this.setState({ rows: [] });
                this.setState({ amount: 0 });
                this.setState({ discount: 0 });
                this.setState({ payable: 0 });
                this.setState({ title: "Purchase Return" });
                this.setState({ body: `Invoice No: ` + res.invoice });
                this.setState({ alert: true });
                this.setState({ save: false });
              }
            });
        } else if (this.state.bank) {
          var data = {
            clientid:
              this.state.AccountSelectedBank[this.state.AccountToCharge].id,
            clientname:
              this.state.AccountSelectedBank[this.state.AccountToCharge].title,
            clienttype:
              this.state.AccountSelectedBank[this.state.AccountToCharge].type,
            amount: this.state.amount,
            payable: this.state.payable,
            configure: configure,
            discount: this.state.discount,
            items: this.state.rows,
            net_price: this.state.net_value,
            gst_price: this.state.gst_price,
            profit_ratio: this.state.cash_profit,
            gst: this.state.gst_value,
            apply_gst: this.state.gst,
            cash: true,
            item_codes: [],
            replacementInvoice: this.state.replacementInvoice,
            remarks: this.state.remarks,
            date: this.state.date,
            userId: localStorage.getItem("id"),
          };
          fetch(url + "inventry/purchasereturn", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              if (res.status == 400) {
                this.setState({ code: 0 });
                this.setState({ name: "" });
                this.setState({ rate: 0 });
                this.setState({ stock: 0 });
                this.setState({ quantity: 0 });
                this.setState({ gst_price: 0 });
                this.setState({ remarks: "" });
                this.setState({ DiscountPrice: 0 });
                this.setState({ date: new Date() });

                this.setState({ rows: [] });
                this.setState({ amount: 0 });
                this.setState({ discount: 0 });
                this.setState({ payable: 0 });
                this.setState({ title: "Purchase Retuen" });
                this.setState({ body: "Invoice Already Exists!!" });
                this.setState({ alert: true });
                this.setState({ save: false });
              } else {
                this.setState({ code: 0 });
                this.setState({ name: "" });
                this.setState({ rate: 0 });
                this.setState({ stock: 0 });
                this.setState({ quantity: 0 });
                this.setState({ rows: [] });
                this.setState({ date: new Date() });
                this.setState({ amount: 0 });
                this.setState({ discount: 0 });
                this.setState({ payable: 0 });
                this.setState({ gst_price: 0 });
                this.setState({ remarks: "" });
                this.setState({ DiscountPrice: 0 });

                this.setState({ title: "Sale" });
                this.setState({ body: `Invoice No: ` + res.invoice });
                this.setState({ alert: true });
                this.setState({ save: false });
                this.setState({ AccountToCharge: "" });
                this.setState({ net_value: 0 });
                this.setState({ searchName: "" });
              }
            });
        } else {
          var data = {
            clientid:
              this.state.AccountSelectedCustomer[this.state.AccountToCharge].id,
            clientname:
              this.state.AccountSelectedCustomer[this.state.AccountToCharge]
                .name,
            clienttype: "customer",
            amount: this.state.amount,
            configure: configure,
            payable: this.state.payable,
            net_price: this.state.net_value,

            item_codes: [],
            gst_price: this.state.gst_price,
            profit_ratio: 0,
            gst: this.state.gst_value,
            apply_gst: this.state.gst,
            discount: this.state.discount,
            cash: false,
            items: this.state.rows,
            remarks: this.state.remarks,
            date: this.state.date,
            userId: localStorage.getItem("id"),
          };
          fetch(url + "inventry/purchasereturn", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              if (res.status == 400) {
                this.setState({ code: 0 });
                this.setState({ name: "" });
                this.setState({ rate: 0 });
                this.setState({ stock: 0 });
                this.setState({ quantity: 0 });
                this.setState({ rows: [] });
                this.setState({ title: "Purchase Return" });
                this.setState({ body: "Invoice Already Exists!!" });
                this.setState({ alert: true });
                this.setState({ save: false });
              } else {
                this.setState({ code: 0 });
                this.setState({ name: "" });
                this.setState({ rate: 0 });
                this.setState({ stock: 0 });
                this.setState({ quantity: 0 });
                this.setState({ rows: [] });

                this.setState({ title: "Purchase Return" });
                this.setState({ body: `Invoice No: ` + res.invoice });
                this.setState({ alert: true });
                this.setState({ save: false });
              }
            });
        }
      }
    } else {
      this.setState({ title: "Alert" });
      this.setState({ body: "Kindly Create Invoice First !!!" });
      this.setState({ alert: true });
      this.setState({ save: false });
    }
  }
  async ReCalculate(objArr) {
    var amount = 0;
    var total_gst = 0;
    var total_discount = 0;

    const dataArr = [...objArr];

    var res = await dataArr.map((item) => {
      var amt = parseFloat(
        (parseFloat(item.rate) * parseInt(item.quantity)).toFixed(2)
      );

      var AfterDiscount = amt - item.discount;

      total_discount = item.discount + total_discount;

      var gst = item.gst;

      var amount_gst = (AfterDiscount / 100) * gst;

      item.net_cost = parseFloat((amount_gst + AfterDiscount).toFixed(2));

      item.amount = amt;

      total_gst = total_gst + amount_gst;

      amount = parseFloat(amt) + amount;

      return amount;
    });

    Promise.all(res).then((values) => {
      this.setState({ gst_price: total_gst });
      this.setState({ net_value: amount + total_gst });
      this.setState({ payable: amount + total_gst - total_discount });
      this.setState({ amount: amount });
      this.setState({ discount: total_discount });
    });
  }
  getValue(code) {
    console.log(code);
    return this.state.table[code];
  }
  AddLine(code, name, category, rate, stock, quantity) {
    var objArr = [];

    var obj = {
      code: code,
      name: name,
      quantity: quantity,
      discount_percentage: 0,
      batch:"",
      rate: parseFloat(rate).toFixed(2),
      amount: parseFloat((rate * quantity).toFixed(2)),
      gst: this.state.gst ? this.state.gst_value : 0,
      net_cost:
        parseFloat((rate * quantity).toFixed(2)) +
        (this.state.gst
          ? (parseFloat((rate * quantity).toFixed(2)) / 100) *
            this.state.gst_value
          : 0),
      discount: 0,
    };
    objArr.push(obj);
    console.log(obj);

    this.setState({ rows: [...this.state.rows, ...objArr] });

    this.ReCalculate([...this.state.rows, ...objArr]);

    this.setState({ code: 0 });
    this.setState({ name: "" });
    this.setState({ stock: 0 });
    this.setState({ openSearchItem: true });
    setTimeout(() => {
      this.setState({ openSearchItem: false });
    }, 1000);
    // document
    //   .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
    //   .click();
    // document.getElementsByClassName("rbt-input-main")[0].focus();
  }
  Add_Item = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          openOnFocus
          value={this.state.searchName}
          // open={this.state.openSearchItem}
          onOpen={() => this.setState({ openSearchItem: true })}
          onClose={() => this.setState({ openSearchItem: false })}
          caseSensitive={false}
          id="controllable-states-demo"
          ignoreDiacritics={true}
          onInputChange={(event, text) => {
            if (text == "") {
              return;
            }
            this.setState({ searchName: text });
          }}
          fullWidth
          size="small"
          onChange={(event, text) => {
            console.log(text);
            var text = text + "";
            if (text.trim() != "") {
              var temp = {
                name: text,
              };
              fetch(url + "inventry/item/itemInfo", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(temp),
              })
                .then((res) => {
                  if (res.status == 400) {
                    alert("No Category Found");
                  } else {
                    return res.json();
                  }
                })
                .then((data) => {
                  if (data && data.code) {
                    this.AddLine(
                      data.code,
                      data.name,
                      data.category,
                      parseFloat(data.cost).toFixed(2),
                      data.stock,
                      1
                    );
                  } else {
                    console.error("Data or data.code is null or undefined");
                  }
                });
              // document.getElementById("Select_Quality").focus();
            }
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              size="small"
              inputProps={{
                style: {
                  height: `0.8em`,
                },
              }}
              {...params}
              label="Search"
            />
          )}
          clearButton={true}
          autoFocus={true}
          clearText={this.state.openSearchItem}
          clearOnEscape={this.state.openSearchItem}
          options={this.state.searchItems}
          getOptionLabel={(option) => truncateText(option, 20)} // Truncating the option label
          placeholder="Items..."
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  getSearchItems() {
    return this.state.searchItems;
  }
  getItems() {
    if (this.state.vendor) {
      var columns = this.state.item_columns;
      var rows = this.state.vendor_rows;
      return { columns, rows };
    } else if (this.state.customer) {
      var columns = this.state.item_columns;
      var rows = this.state.customer_rows;
      return { columns, rows };
    } else if (this.state.cash) {
      var columns = this.state.item_columns;
      var rows = this.state.cash_rows;
      return { columns, rows };
    }
  }

  render() {
    const { handleClose } = this.props;
    return (
      <>
        <Grid container>
          <Grid
            item
            sm={12}
            style={{
              // backgroundColor: "orange",
              height: "9vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppBar
              // position="static"
              style={{
                backgroundColor: "#01579b",
                height: "9vh",
                color: "white",
              }}
            >
              <Toolbar>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={this.state.date}
                      onChange={(date) => {
                        this.setState({ date: date });
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          inputProps={{
                            style: {
                              height: `0.8em`,
                            },
                          }}
                          style={{
                            borderColor: "#fff",
                            border: "1px solid white",
                            borderRadius: 5,
                          }}
                          sx={{
                            svg: { color: "#fff" },
                            input: { color: "#fff" },
                          }}
                          size="small"
                          InputProps={{}}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <Typography
                  variant="h6"
                  style={{ flexGrow: 1, textAlign: "center" }}
                >
                  Create Purchase Return
                </Typography>
                <Button
                  color="inherit"
                  style={{ borderColor: "#fff", border: "1px solid white" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Toolbar>
            </AppBar>
          </Grid>

       
            <Grid item lg={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody className="all-icons">
                  <form onSubmit={this.AddLine}>
                    <Grid container sm={12} style={{ height: "90vh" }}>
                      <Grid item sm={9} style={{ paddingLeft: "1em" }}>
                        <MaterialTable
                          style={{ zoom: "70%" }}
                          title=""
                          icons={tableIcons}
                          isLoading={this.state.load}
                          columns={[
                            {
                              title: (
                                <span style={{ color: "#fff" }}>Code</span>
                              ),
                              field: "code",
                              editable: false,
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>Name</span>
                              ),
                              field: "name",
                              editable: false,
                            },
                            { title: "Batch No.", field: "batch" },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>Rate</span>
                              ),
                              field: "rate",
                              grouping: false,
                              type: "numeric",
                              render: (rowData) =>
                                this.numberWithCommas(rowData.rate),
                                editComponent: (props) => (
                                  <TextField
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    value={props.value}
                                    onChange={(e) => {
                                      const inputValue = e.target.value.slice(0, 7);
                                      props.onChange(inputValue);
                                    }}
                                  />
                                ),
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>Quantity</span>
                              ),
                              field: "quantity",
                              grouping: false,
                              type: "numeric",
                              editComponent: (props) => (
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  value={props.value}
                                  onChange={(e) => {
                                    const inputValue = e.target.value.slice(0, 5);
                                    props.onChange(inputValue);
                                  }}
                                />
                              ),
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>Amount</span>
                              ),
                              field: "amount",
                              grouping: false,
                              editable: false,
                              type: "numeric",
                              render: (rowData) =>
                                this.numberWithCommas(rowData.amount),
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>GST (%)</span>
                              ),
                              field: "gst",
                              grouping: false,
                              type: "numeric",
                              editComponent: (props) => (
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  value={props.value}
                                  onChange={(e) => {
                                    const inputValue = e.target.value.slice(0, 3);
                                    props.onChange(inputValue);
                                  }}
                                />
                              ),
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>
                                  Discount (%)
                                </span>
                              ),
                              field: "discount_percentage",
                              grouping: false,
                              type: "numeric",
                              editComponent: (props) => (
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  value={props.value}
                                  onChange={(e) => {
                                    const inputValue = e.target.value.slice(0, 3);
                                    props.onChange(inputValue);
                                  }}
                                />
                              ),
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>
                                  Discount (Rs)
                                </span>
                              ),
                              field: "discount",
                              grouping: false,
                              type: "numeric",
                              editable: false,
                              render: (rowData) =>
                                this.numberWithCommas(rowData.discount),
                            },
                            {
                              title: (
                                <span style={{ color: "#fff" }}>Net Cost</span>
                              ),
                              field: "net_cost",
                              grouping: false,
                              type: "numeric",
                              editable: false,
                              render: (rowData) =>
                                this.numberWithCommas(rowData.net_cost),
                            },
                          ]}
                          data={this.state.rows}
                          options={{
                            headerStyle: {
                              backgroundColor: "#01579b",
                              color: "#FFF",
                              whiteSpace: "nowrap",
                            },
                            rowStyle: (rowData, index) => ({
                              backgroundColor:
                                index % 2 === 0 ? "#f9f9f9" : "#FFF",
                            }),

                            actionsColumnIndex: -1,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: false,
                            showFirstLastPageButtons: false,
                            maxBodyHeight: "850px",
                            selection: true,
                            search: false,
                          }}
                          actions={[
                            {
                              tooltip: "Remove All Selected Users",
                              icon: () => <DeleteOutline />,
                              onClick: (evt, data) => {
                                const confirmDelete = window.confirm(
                                  "Are you sure you want to remove all selected items?"
                                );

                                if (confirmDelete) {
                                  let _data = [...this.state.rows];
                                  data.forEach((rd) => {
                                    _data = _data.filter(
                                      (t) => t.tableData.id !== rd.tableData.id
                                    );
                                  });
                                  this.setState({ rows: [..._data] });
                                  this.ReCalculate([..._data]);
                                }
                              },
                            },
                          ]}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataUpdate = [...this.state.rows];

                                  const index = oldData.tableData.id;
                                  // Update Cost

                                  if (
                                    newData.quantity == "" ||
                                    isNaN(newData.quantity)
                                  ) {
                                    newData.quantity = 0;
                                  }
                                  if (
                                    newData.rate == "" ||
                                    isNaN(newData.rate)
                                  ) {
                                    newData.rate = 0;
                                  }
                                  if (newData.gst == "" || isNaN(newData.gst)) {
                                    newData.gst = 0;
                                  }
                                  if (
                                    newData.discount == "" ||
                                    isNaN(newData.discount)
                                  ) {
                                    newData.discount = 0;
                                  }
                                  if (
                                    newData.discount_percentage == "" ||
                                    isNaN(newData.discount_percentage)
                                  ) {
                                    newData.discount_percentage = 0;
                                  }

                                  var discount = parseFloat(
                                    (
                                      ((newData.quantity * newData.rate) /
                                        100) *
                                      newData.discount_percentage
                                    ).toFixed(2)
                                  );
                                  newData.discount = discount;

                                  var gst = newData.gst;
                                  var rate = newData.rate;
                                  var quantity = newData.quantity;

                                  var amount = parseFloat(
                                    (quantity * rate).toFixed(2)
                                  );

                                  console.log(
                                    "-------------Discount------------------"
                                  );
                                  console.log(newData.gst);
                                  console.log(
                                    "---------------After Discount----------------"
                                  );
                                  var AfterDiscountAmount = amount - discount;
                                  console.log(AfterDiscountAmount);
                                  console.log(
                                    "---------------After GST----------------"
                                  );
                                  var gstPercent =
                                    (AfterDiscountAmount / 100) * gst;

                                  var net_cost = parseFloat(
                                    (AfterDiscountAmount + gstPercent).toFixed(
                                      2
                                    )
                                  );
                                  console.log(net_cost);
                                  newData.amount = parseFloat(
                                    amount.toFixed(2)
                                  );

                                  newData.net_cost = net_cost;

                                  dataUpdate[index] = newData;
                                  this.setState({ rows: [...dataUpdate] });
                                  this.ReCalculate([...dataUpdate]);

                                  resolve();
                                }, 1000);
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataDelete = [...this.state.rows];
                                  const index = oldData.tableData.id;
                                  dataDelete.splice(index, 1);
                                  this.setState({ rows: [...dataDelete] });
                                  this.ReCalculate([...dataDelete]);

                                  resolve();
                                }, 1000);
                              }),
                          }}
                        />
                        <Grid item sm={12} style={{ height: "5vh" }}>
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                            Remarks
                          </b>
                          <br />
                          <Form.Control
                            as="textarea"
                            rows="4"
                            style={{ width: "100%" }}
                            maxLength={500}
                            value={this.state.remarks}
                            onChange={(e) => {
                              this.setState({ remarks: e.target.value });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        style={{ paddingLeft: "1em", paddingRight: "1.6em" }}
                      >
                        {/* /////////////////////////////////////////////// */}
                        {/* Grid for Date */}
                        {/* <Grid item sm={12} style={{ height: "9vh" }}>
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                            Date
                          </b>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="dd/MM/yyyy"
                                size="small"
                                fullWidth
                                value={this.state.date}
                                onChange={(date) => {
                                  this.setState({ date: date });
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Grid> */}
                        {/* ///////////////////////////////////////////////////////// */}
                        {/* Grid for Search */}
                        <Grid item sm={12} style={{ height: "9vh" }}>
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                            Search - {this.state.searchName}
                          </b>
                          {this.Add_Item()}
                          {/* <Button
                              variant="contained"
                              size="md"
                              onClick={() => {
                                if (this.state.searchName.trim() == "") {
                                  alert("Invalid Product Name");
                                  return;
                                }
                                var temp = {
                                  name: this.state.searchName,
                                };
                                fetch(url + "inventry/item/ItemsNameWise", {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify(temp),
                                })
                                  .then((res) => {
                                    if (res.status == 400) {
                                      alert("No Category Found");
                                    } else {
                                      return res.json();
                                    }
                                  })
                                  .then((items) => {
                                    var rows = [];
                                    items.map((data) => {
                                      rows.push(data.name);
                                    });

                                    this.setState({ searchItems: rows });
                                    this.setState({ openSearchItem: true });
                                    // document
                                    //   .getElementsByClassName("rbt-input-main")[0]
                                    //   .focus();
                                  });
                              }}
                              style={{
                                alignSelf: "center",
                                marginLeft: 5,
                              }}
                            >
                              <SearchIcon />
                            </Button> */}
                        </Grid>

                        {/* /////////////////////////////////////////////////////////////////////// */}
                        {/* Grid for Client Selection */}
                        <Grid
                          item
                          sm={12}
                          style={{
                            height: "9vh",
                          }}
                        >
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                            Client Selection
                          </b>
                          <Grid
                            container
                            id="formGridCheckbox"
                            style={{
                              justifyContent: "space-evenly",
                              // marginBottom: 10,
                            }}
                          >
                            <Form.Check
                              custom
                              inline
                              checked={this.state.vendor}
                              // disabled={this.state.cash}
                              onChange={() => {
                                this.setState({
                                  vendor: true,
                                  customer: false,
                                  cash: false,
                                  bank: false,
                                  gst_value: this.state.gst_vendor,
                                  vendor_rows: this.state.vendor_first,
                                  customer_rows: this.state.customer_first,
                                  vendor_data: null,
                                  customer_data: null,
                                  cash_data: null,
                                  bank_data: null,
                                  AccountToCharge: "",
                                });
                              }}
                              type="checkbox"
                              label={
                                <b
                                  style={{
                                    fontSize: "0.9em",
                                    color: "rgb(32,55,100)",
                                  }}
                                >
                                  {" "}
                                  Vendor{" "}
                                </b>
                              }
                              name="vendor"
                              id="vendor"
                            />
                            <Form.Check
                              custom
                              inline
                              checked={this.state.customer}
                              // disabled={this.state.cash}
                              onChange={() => {
                                this.setState({
                                  vendor: false,
                                  customer: true,
                                  cash: false,
                                  bank: false,
                                  gst_value: this.state.gst_customer,
                                  vendor_rows: this.state.vendor_first,
                                  customer_rows: this.state.customer_first,
                                  vendor_data: null,
                                  customer_data: null,
                                  cash_data: null,
                                  bank_data: null,
                                  AccountToCharge: "",
                                });
                              }}
                              type="checkbox"
                              label={
                                <b
                                  style={{
                                    fontSize: "0.9em",
                                    color: "rgb(32,55,100)",
                                  }}
                                >
                                  {" "}
                                  Customer{" "}
                                </b>
                              }
                              name="customer"
                              id="customer"
                            />
                            <Form.Check
                              custom
                              inline
                              checked={this.state.cash}
                              onChange={() => {
                                this.setState({
                                  vendor: false,
                                  customer: false,
                                  cash: true,
                                  bank: false,
                                  gst_value: this.state.gst_cash,
                                  vendor_rows: this.state.vendor_first,
                                  customer_rows: this.state.customer_first,
                                  vendor_data: null,
                                  customer_data: null,
                                  cash_data: null,
                                  bank_data: null,
                                  AccountToCharge: "",
                                });
                              }}
                              type="checkbox"
                              label={
                                <b
                                  style={{
                                    fontSize: "0.9em",
                                    color: "rgb(32,55,100)",
                                  }}
                                >
                                  {" "}
                                  Cash{" "}
                                </b>
                              }
                              name="cash"
                              id="cash"
                            />

                            <Form.Check
                              custom
                              inline
                              checked={this.state.bank}
                              onChange={() => {
                                this.setState({
                                  vendor: false,
                                  customer: false,
                                  cash: false,
                                  bank: true,
                                  gst_value: this.state.gst_bank,
                                  vendor_rows: this.state.vendor_first,
                                  customer_rows: this.state.customer_first,
                                  vendor_data: null,
                                  customer_data: null,
                                  cash_data: null,
                                  bank_data: null,
                                  AccountToCharge: "",
                                });
                              }}
                              type="checkbox"
                              label={
                                <b
                                  style={{
                                    fontSize: "0.9em",
                                    color: "rgb(32,55,100)",
                                  }}
                                >
                                  {" "}
                                  Bank{" "}
                                </b>
                              }
                              name="bank"
                              id="bank"
                            />

                            {/* ////////////////////////////////////////////////////////////////// */}
                          </Grid>
                        </Grid>
                        <Grid item sm={12} style={{ height: "6vh" }}>
                          {
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={
                                this.state.cash
                                  ? this.state.cash_accounts.map(
                                      (option) => option.title
                                    )
                                  : this.state.vendor
                                  ? this.state.client_vendor.map(
                                      (option) => option.name
                                    )
                                  : this.state.bank
                                  ? this.state.bank_accounts.map(
                                      (option) => option.title
                                    )
                                  : this.state.client_customer.map(
                                      (option) => option.name
                                    )
                              }
                              // sx={{ width: 300 }}
                              fullWidth
                              size="small"
                              inputProps={{
                                style: {
                                  height: `0.8em`,
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  inputProps={{
                                    style: {
                                      height: `0.8em`,
                                    },
                                  }}
                                  size="small"
                                  {...params}
                                  label={
                                    this.state.cash
                                      ? "Cash Slection"
                                      : this.state.bank
                                      ? "Bank Slection"
                                      : "Client Slection"
                                  }
                                />
                              )}
                              value={this.state.AccountToCharge}
                              onChange={(event, newInputValue) => {
                                this.setState({
                                  AccountToCharge: newInputValue,
                                });
                              }}
                            />
                          }
                        </Grid>
                        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                        <Grid
                          item
                          container
                          sm={12}
                          style={{ height: "7vh", backgroundColor: "white" }}
                        >
                          <Grid item xs={6}>
                            <b style={{ fontSize: "0.8em" }}>Apply GST (%)</b>
                            <Form.Check
                              custom
                              style={{ marginLeft: 15 }}
                              inline
                              checked={this.state.gst}
                              onChange={(e) => {
                                this.setState({ gst: !this.state.gst });

                                if (this.state.vendor) {
                                  this.setState({
                                    gst_value: this.state.gst_vendor,
                                  });
                                } else if (this.state.customer) {
                                  this.setState({
                                    gst_value: this.state.gst_customer,
                                  });
                                } else if (this.state.cash) {
                                  this.setState({
                                    gst_value: this.state.gst_cash,
                                  });
                                } else if (this.state.bank) {
                                  this.setState({
                                    gst_value: this.state.gst_bank,
                                  });
                                }
                                if (this.state.gst == false) {
                                  if (this.state.vendor) {
                                    Object.keys(this.state.table).filter(
                                      (key) => {
                                        document.getElementById(
                                          "gst" + key
                                        ).value = this.state.gst_vendor;
                                      }
                                    );
                                  } else if (this.state.customer) {
                                    Object.keys(this.state.table).filter(
                                      (key) => {
                                        document.getElementById(
                                          "gst" + key
                                        ).value = this.state.gst_customer;
                                      }
                                    );
                                  } else if (this.state.cash) {
                                    Object.keys(this.state.table).filter(
                                      (key) => {
                                        document.getElementById(
                                          "gst" + key
                                        ).value = this.state.gst_cash;
                                      }
                                    );
                                  } else if (this.state.bank) {
                                    Object.keys(this.state.table).filter(
                                      (key) => {
                                        document.getElementById(
                                          "gst" + key
                                        ).value = this.state.gst_bank;
                                      }
                                    );
                                  }
                                }
                              }}
                              type="checkbox"
                              label={
                                <b style={{ fontSize: "1em", marginLeft: 15 }}>
                                  GST
                                </b>
                              }
                              name="gst"
                              id="gst"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                            {this.state.gst ? (
                              <>
                                <b
                                  style={{
                                    fontSize: "0.8em",
                                    color: "rgb(32,55,100)",
                                  }}
                                >
                                  GST Percentage (%)
                                </b>
                                <TextField
                                  inputProps={{
                                    style: {
                                      height: `0.8em`,
                                    },
                                  }}
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  type="number"
                                  value={this.state.gst_value}
                                  disabled={true}
                                  onChange={(e) => {
                                    if (e.target.value >= 0) {
                                      this.setState({
                                        gst_value: e.target.value,
                                      });
                                      if (this.state.amount > 0) {
                                        var percentage =
                                          (this.state.amount / 100) *
                                          e.target.value;
                                        this.setState({
                                          gst_price: percentage,
                                        });
                                        var net =
                                          this.state.amount + percentage;
                                        this.setState({ net_value: net });
                                        this.setState({ payable: net });
                                        this.setState({ discount: 0 });
                                        this.setState({
                                          aLLocatedPerDiscount: 0,
                                        });
                                        this.setState({ TempDiscount: 0 });
                                        this.setState({ DiscountPrice: 0 });
                                      }
                                    } else {
                                      this.setState({ alert: true });
                                      this.setState({ title: "Alert" });
                                      this.setState({ body: "Invalid GST" });
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                        {/* Amount Part */}
                        <Grid item sm={12} style={{ height: "9vh" }}>
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                            Total Amount
                          </b>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              onFocus: (e) => e.preventDefault(),
                              onClick: (e) => e.preventDefault(),
                              onMouseDown: (e) => e.preventDefault(),
                            }}
                            type="text"
                            value={this.numberWithCommas(
                              this.state.amount.toFixed(2)
                            )}
                            step="any"
                            min="1"
                            required
                            placeholder="Amount..."
                          />
                        </Grid>

                        <Grid item sm={12} style={{ height: "9vh" }}>
                          <b style={{ fontSize: "0.8em" }}>
                            Discount % (line items)
                          </b>
                          <Grid container>
                            <Grid item sm={3}>
                              <TextField
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                required
                                size="small"
                                id="outlined-required"
                                label=""
                                style={{
                                  borderRadius: 5,
                                }}
                                type="number"
                                onFocus={() => {
                                  if (this.state.TempDiscount === 0) {
                                    this.setState({ TempDiscount: "" });
                                  }
                                }}
                                onBlur={() => {
                                  if (this.state.TempDiscount === "") {
                                    this.setState({ TempDiscount: 0 });
                                  }
                                }}
                                onChange={(e) => {
                                  this.setState({
                                    TempDiscount: parseFloat(e.target.value),
                                  });
                                  this.setState({ DiscountPrice: 0 });
                                }}
                                value={this.state.TempDiscount}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <b
                                style={{
                                  textAlign: "center",
                                  marginTop: 5,
                                }}
                              >
                                OR
                              </b>
                            </Grid>
                            <Grid item sm={3}>
                              <TextField
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      Rs
                                    </InputAdornment>
                                  ),
                                }}
                                required
                                id="outlined-required"
                                label=""
                                style={{
                                  borderRadius: 5,
                                }}
                                size="small"
                                type="number"
                                onFocus={() => {
                                  if (this.state.DiscountPrice === 0) {
                                    this.setState({ DiscountPrice: "" });
                                  }
                                }}
                                onBlur={() => {
                                  if (this.state.DiscountPrice === "") {
                                    this.setState({ DiscountPrice: 0 });
                                  }
                                }}
                                onChange={(e) => {
                                  this.setState({
                                    DiscountPrice: parseFloat(e.target.value),
                                  });
                                  this.setState({ TempDiscount: 0 });
                                }}
                                value={this.state.DiscountPrice}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              style={{
                                marginLeft: "0.5em",
                                marginRight: "0.5em",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  maxWidth: "30px",
                                  maxHeight: "30px",
                                  minWidth: "30px",
                                  minHeight: "30px",
                                }}

                                disabled={this.state.rows.length <= 0}
                                onClick={async () => {
                                  if (this.state.TempDiscount > 15) {
                                    this.setState({ title: "Alert" });
                                    this.setState({
                                      body: "Discount Can not be more then 15 %. ",
                                    });
                                    this.setState({ alert: true });
                                    return;
                                  }
                                  var dis = parseFloat(
                                    this.state.DiscountPrice
                                  );
                                  if (this.state.TempDiscount != 0) {
                                    dis = parseFloat(
                                      (this.state.net_value / 100) *
                                        this.state.TempDiscount
                                    );
                                    this.setState({ DiscountPrice: dis });
                                  }
                                  if (this.state.DiscountPrice != 0) {
                                    if (
                                      this.state.DiscountPrice >
                                      this.state.net_value - this.state.discount
                                    ) {
                                      alert(
                                        "Discount can't be more than Payable Amount"
                                      );
                                      return;
                                    }
                                  }

                                  // Each Test Price Calculation

                                  var temp_net_cost = 0;
                                  var payable = 0;
                                  var counter = 0;
                                  var index = 0;
                                  var calculating_discount = 0;
                                  var total_gst = 0;

                                  // Get All Rows

                                  var hold = await this.state.rows.map(
                                    (row) => {
                                      // Net Cost

                                      // Discount Value of That Row
                                      var amt =
                                        parseFloat(row.rate) *
                                        parseFloat(row.quantity);

                                      var cost = amt;

                                      var vP =
                                        (cost / this.state.net_value) * 100;

                                      var allocate_the_discount = Math.round(
                                        (vP * dis) / 100
                                      );

                                      console.log(
                                        "Allocate Discount = " +
                                          allocate_the_discount
                                      );

                                      if (this.state.rows.length == index + 1) {
                                        allocate_the_discount =
                                          dis - calculating_discount;
                                      }

                                      calculating_discount =
                                        calculating_discount +
                                        allocate_the_discount;

                                      console.log(
                                        "Calculated Discount = " +
                                          calculating_discount
                                      );

                                      if (parseFloat(cost) == 0) {
                                        return row;
                                      }

                                      temp_net_cost =
                                        parseFloat(cost) -
                                        allocate_the_discount;

                                      index = index + 1;
                                      if (temp_net_cost < 0) {
                                        counter = counter + 1;
                                        return row;
                                      }

                                      var net_final_cost = temp_net_cost;
                                      payable = payable + net_final_cost;

                                      console.log(
                                        "Allocated Discount = " +
                                          allocate_the_discount
                                      );

                                      var gst = row.gst;
                                      var amount_gst = (amt / 100) * gst;

                                      total_gst = parseFloat(
                                        parseFloat(
                                          total_gst + amount_gst
                                        ).toFixed(2)
                                      );

                                      row.discount = parseFloat(
                                        parseFloat(
                                          allocate_the_discount
                                        ).toFixed(2)
                                      );
                                      row.amount = parseFloat(amt.toFixed(2));
                                      row.discount_percentage = parseFloat(
                                        ((row.discount / amt) * 100).toFixed(2)
                                      );

                                      row.net_cost = parseFloat(
                                        parseFloat(
                                          net_final_cost + amount_gst
                                        ).toFixed(2)
                                      );

                                      return row;
                                    }
                                  );

                                  Promise.all(hold).then((values) => {
                                    if (counter == 0) {
                                      this.setState({
                                        discount: parseFloat(
                                          parseFloat(dis.toFixed(2))
                                        ),
                                      });
                                      this.setState({ TempDiscount: 0 });
                                      this.setState({
                                        aLLocatedPerDiscount: parseFloat(
                                          parseFloat(
                                            (dis / this.state.net_value) * 100
                                          ).toFixed(2)
                                        ),
                                      });
                                      this.setState({
                                        payable: parseFloat(
                                          parseFloat(
                                            (
                                              this.state.net_value - dis
                                            ).toFixed(2)
                                          )
                                        ),
                                      });
                                      this.setState({
                                        gst_price: parseFloat(
                                          parseFloat(total_gst.toFixed(2))
                                        ),
                                      });
                                    }
                                  });
                                }}
                              >
                                <CheckIcon />
                              </Button>
                            </Grid>
                            <Grid item sm={3}>
                              <TextField
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                required
                                id="outlined-required"
                                title="Applied Discount"
                                label="Applied Discount"
                                style={{
                                  borderRadius: 5,
                                  fontSize: 20,
                                  fontWeight: "bold",
                                }}
                                value={this.state.aLLocatedPerDiscount}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid item sm={12} style={{ height: "9vh" }}>
                          <b style={{ fontSize: "0.8em" }}>
                            Discount % (line items)
                          </b>
                          <Grid container>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <TextField
                                required
                                id="outlined-required"
                                title="Applied Discount"
                                label="Applied Discount"
                                style={{
                                  borderRadius: 5,
                                  fontSize: 20,
                                  width: "20em",
                                  fontWeight: "bold",
                                }}
                                value={this.state.aLLocatedPerDiscount + " %"}
                                variant="outlined"
                              />

                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  height: "50%",
                                  marginTop: "1em",
                                  marginLeft: 5,
                                }}
                                onClick={async () => {
                                  var dis = parseFloat(
                                    this.state.DiscountPrice
                                  );
                                  if (this.state.TempDiscount != 0) {
                                    dis = parseFloat(
                                      (this.state.net_value / 100) *
                                        this.state.TempDiscount
                                    );
                                    this.setState({ DiscountPrice: dis });
                                  }
                                  if (this.state.DiscountPrice != 0) {
                                    if (
                                      this.state.DiscountPrice >
                                      this.state.net_value - this.state.discount
                                    ) {
                                      alert(
                                        "Discount can't be more than Payable Amount"
                                      );
                                      return;
                                    }
                                  }

                                  // Each Test Price Calculation

                                  var temp_net_cost = 0;
                                  var payable = 0;
                                  var counter = 0;
                                  var index = 0;
                                  var calculating_discount = 0;
                                  var total_gst = 0;

                                  // Get All Rows

                                  var hold = await this.state.rows.map(
                                    (row) => {
                                      // Net Cost

                                      // Discount Value of That Row
                                      var amt =
                                        parseFloat(row.rate) *
                                        parseFloat(row.quantity);

                                      var cost = amt;

                                      var vP =
                                        (cost / this.state.net_value) * 100;

                                      var allocate_the_discount = Math.round(
                                        (vP * dis) / 100
                                      );

                                      console.log(
                                        "Allocate Discount = " +
                                          allocate_the_discount
                                      );

                                      if (this.state.rows.length == index + 1) {
                                        allocate_the_discount =
                                          dis - calculating_discount;
                                      }

                                      calculating_discount =
                                        calculating_discount +
                                        allocate_the_discount;

                                      console.log(
                                        "Calculated Discount = " +
                                          calculating_discount
                                      );

                                      if (parseFloat(cost) == 0) {
                                        return row;
                                      }

                                      temp_net_cost =
                                        parseFloat(cost) -
                                        allocate_the_discount;

                                      index = index + 1;
                                      if (temp_net_cost < 0) {
                                        counter = counter + 1;
                                        return row;
                                      }

                                      var net_final_cost = temp_net_cost;
                                      payable = payable + net_final_cost;

                                      console.log(
                                        "Allocated Discount = " +
                                          allocate_the_discount
                                      );

                                      var gst = row.gst;
                                      var amount_gst = (amt / 100) * gst;

                                      total_gst = parseFloat(
                                        parseFloat(
                                          total_gst + amount_gst
                                        ).toFixed(2)
                                      );

                                      row.discount = parseFloat(
                                        parseFloat(
                                          allocate_the_discount
                                        ).toFixed(2)
                                      );
                                      row.amount = parseFloat(amt.toFixed(2));
                                      row.discount_percentage = parseFloat(
                                        ((row.discount / amt) * 100).toFixed(2)
                                      );

                                      row.net_cost = parseFloat(
                                        parseFloat(
                                          net_final_cost + amount_gst
                                        ).toFixed(2)
                                      );

                                      return row;
                                    }
                                  );

                                  Promise.all(hold).then((values) => {
                                    if (counter == 0) {
                                      this.setState({
                                        discount: parseFloat(
                                          parseFloat(dis.toFixed(2))
                                        ),
                                      });
                                      this.setState({ TempDiscount: 0 });
                                      this.setState({
                                        aLLocatedPerDiscount: parseFloat(
                                          parseFloat(
                                            (dis / this.state.net_value) * 100
                                          ).toFixed(2)
                                        ),
                                      });
                                      this.setState({
                                        payable: parseFloat(
                                          parseFloat(
                                            (
                                              this.state.net_value - dis
                                            ).toFixed(2)
                                          )
                                        ),
                                      });
                                      this.setState({
                                        gst_price: parseFloat(
                                          parseFloat(total_gst.toFixed(2))
                                        ),
                                      });
                                    }
                                  });
                                }}
                              >
                                Apply
                              </Button>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginRight: "0.5em",
                                marginTop: "1em",
                              }}
                            >
                              <TextField
                                required
                                id="outlined-required"
                                label=""
                                style={{
                                  borderRadius: 5,
                                }}
                                type="number"
                                onChange={(e) => {
                                  this.setState({
                                    TempDiscount: parseFloat(e.target.value),
                                  });
                                  this.setState({ DiscountPrice: 0 });
                                }}
                                value={this.state.TempDiscount}
                                variant="outlined"
                              />
                              <b
                                style={{
                                  textAlign: "center",
                                  marginTop: 5,
                                }}
                              >
                                %
                              </b>
                              <TextField
                                required
                                id="outlined-required"
                                label=""
                                style={{
                                  borderRadius: 5,
                                }}
                                type="number"
                                onChange={(e) => {
                                  this.setState({
                                    DiscountPrice: parseFloat(e.target.value),
                                  });
                                  this.setState({ TempDiscount: 0 });
                                }}
                                value={this.state.DiscountPrice}
                                variant="outlined"
                              />
                              <b
                                style={{
                                  textAlign: "center",
                                  marginTop: 5,
                                }}
                              >
                                Rs.
                              </b>
                            </span>
                          </Grid>
                        </Grid>

                        <br/>
                        <br/>
                        <br/> */}

                        <Grid container item sm={12} style={{ height: "10vh" }}>
                          <Grid item xs={6}>
                            <b
                              style={{
                                fontSize: "0.8em",
                                color: "rgb(32,55,100)",
                              }}
                            >
                              Total Discount
                            </b>
                            <TextField
                              size="small"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                onFocus: (e) => e.preventDefault(),
                                onClick: (e) => e.preventDefault(),
                                onMouseDown: (e) => e.preventDefault(),
                              }}
                              id="outlined-basic"
                              variant="outlined"
                              type="text"
                              value={this.numberWithCommas(
                                this.state.discount.toFixed(2)
                              )}
                              step="any"
                              min="1"
                              required
                              placeholder="Discount..."
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <b
                              style={{
                                fontSize: "0.8em",
                                color: "rgb(32,55,100)",
                              }}
                            >
                              Total GST
                            </b>
                            <TextField
                              size="small"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                onFocus: (e) => e.preventDefault(),
                                onClick: (e) => e.preventDefault(),
                                onMouseDown: (e) => e.preventDefault(),
                              }}
                              id="outlined-basic"
                              variant="outlined"
                              type="text"
                              value={this.numberWithCommas(
                                this.state.gst_price.toFixed(2)
                              )}
                              onChange={(e) => {}}
                              step="any"
                              min="1"
                              required
                              placeholder="GST Price..."
                            />
                          </Grid>
                        </Grid>
                        {/* <Grid item sm={12} style={{ height: "9vh" }}>
                          <b
                            style={{
                              fontSize: "1em",
                              color: "rgb(32,55,100)",
                              marginLeft: 15,
                            }}
                          >
                            Total GST
                          </b>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            value={this.numberWithCommas(
                              this.state.gst_price.toFixed(2)
                            )}
                            onChange={(e) => {}}
                            step="any"
                            min="1"
                            required
                            placeholder="GST Price..."
                          />
                          <br />
                          <b
                            style={{ fontSize: "1em", color: "rgb(32,55,100)" }}
                          >
                            Discount
                          </b>
                          <br />
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            value={this.numberWithCommas(
                              this.state.discount.toFixed(2)
                            )}
                            step="any"
                            min="1"
                            required
                            placeholder="Discount..."
                          />
                        </Grid> */}
                        <Grid
                          item
                          sm={12}
                          style={{ height: "10vh", backgroundColor: "" }}
                        >
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                            Net Value (Exclude Discount)
                          </b>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                              onFocus: (e) => e.preventDefault(),
                              onClick: (e) => e.preventDefault(),
                              onMouseDown: (e) => e.preventDefault(),
                            }}
                            type="text"
                            value={this.numberWithCommas(
                              this.state.net_value.toFixed(2)
                            )}
                            onChange={(e) => {}}
                            step="any"
                            min="1"
                            required
                            fullWidth
                            size="small"
                            placeholder="Net Value..."
                          />
                        </Grid>
                        <Grid item sm={12} style={{ height: "11vh" }}>
                          <b
                            style={{
                              fontSize: "0.8em",
                              color: "rgb(32,55,100)",
                            }}
                          >
                           Total Payable
                          </b>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            InputProps={{
                              readOnly: true,
                              onFocus: (e) => e.preventDefault(),
                              onClick: (e) => e.preventDefault(),
                              onMouseDown: (e) => e.preventDefault(),
                            }}
                            value={this.numberWithCommas(
                              this.state.payable.toFixed(2)
                            )}
                            step="any"
                            min="1"
                            required
                            fullWidth
                            size="small"
                            placeholder="Rate..."
                          />
                        </Grid>

                        <Grid item sm={12} style={{ height: "9vh" }}>
                          <Button
                            style={{
                              // height: "50%",
                              // marginTop: "0.3em",
                              fontSize: "2em",
                            }}
                            fullWidth
                            variant="contained"
                            to="#"
                            size="small"
                            disabled={this.state.save}
                            onClick={() => {
                              this.onSubmit();
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>

                  <Grid container spacing={12}></Grid>

                  <Modal
                    open={this.state.alert}
                    onClose={() => this.setState({ alert: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {this.state.title}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {this.state.body}
                      </Typography>
                    </Box>
                  </Modal>
                </CardBody>
              </Card>
            </Grid>
          
        </Grid>
      </>
    );
  }
}
export default PurchaseReturn;
