import React, {useEffect} from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import  { History, Blocker, Transition } from 'history';

export function useBlocker(confirmExit, when = true) {
    const navigator = React.useContext(UNSAFE_NavigationContext).navigator;
  
    useEffect(() => {
      if (!when) {
        return;
      }
  
      const push = navigator.push;
  
      navigator.push = (...args) => {
        const result = confirmExit();
        if (result !== false) {
          push(...args);
        }
      };
  
      return () => {
        navigator.push = push;
      };
    }, [navigator, confirmExit, when]);
  }