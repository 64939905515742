import React, { Component } from "react";

import {
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
} from "./TreeOperations";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid, Container, Typography } from "@mui/material";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
// import DeleteIcon from "@material-ui/icons/Delete";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
import ReactLoading from "react-loading";
import IconButton from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import "whatwg-fetch";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// ServerURL
import url from "src/serverURL.js";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default class ChartofAccounts extends Component {
  constructor(props) {
    super(props);
    this.updateTreeData = this.updateTreeData.bind(this);
    this.addNode = this.addNode.bind(this);
    this.removeNode = this.removeNode.bind(this);
    this.Submit = this.Submit.bind(this);
    this.Update = this.Update.bind(this);
    this.getNodeKey = this.getNodeKey.bind(this);
    this.logTreeIndex = this.logTreeIndex.bind(this);
    this.state = {
      treeData: [
        // {
        //   title: "Assets",
        //   subtitle: "A-1",
        //   children: [
        //     {
        //       title: "Current Assets",
        //       subtitle: "A-1-1",
        //       children: [
        //         { title: "CASH", subtitle: "A-1-1-1", children: [] },
        //         { title: "BANK", subtitle: "A-1-1-2", children: [] },
        //         {
        //           title: "Account Recievables",
        //           subtitle: "A-1-1-3",
        //           children: [],
        //         },
        //         { title: "Stock", subtitle: "A-1-1-4", children: [] },
        //       ],
        //     },
        //     {
        //       title: "Non-Current Assets",
        //       subtitle: "A-2-1",
        //       children: [
        //         { title: "Building", subtitle: "A-2-1-1", children: [] },
        //         { title: "Motor Vehicle", subtitle: "A-2-1-2", children: [] },
        //         {
        //           title: "Long Term Investment",
        //           subtitle: "A-2-1-3",
        //           children: [],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   title: "Revenue",
        //   subtitle: "R-1",
        //   children: [
        //     {
        //       title: "Sales",
        //       subtitle: "R-1-1",
        //       children: [],
        //     },
        //     {
        //       title: "Other Income",
        //       subtitle: "R-1-2",
        //       children: [],
        //     },
        //   ],
        // },
        // {
        //   title: "Current Liabilities",
        //   subtitle: "L-1",
        //   children: [
        //     {
        //       title: "Vendors",
        //       subtitle: "L-1-2",
        //       children: [],
        //     },
        //     {
        //       title: "Short Term Loan",
        //       subtitle: "L-1-2",
        //       children: [],
        //     },
        //   ],
        // },
        // {
        //   title: "Long Term Liabilities",
        //   subtitle: "L-2",
        //   children: [],
        // },
        // {
        //   title: "Owner's Equity",
        //   subtitle: "O-1",
        //   children: [],
        // },
        // {
        //   title: "Expenses",
        //   subtitle: "E-1",
        //   children: [],
        // },
        // {
        //   title: "Cost of Sales",
        //   subtitle: "C-1",
        //   children: [
        //     {
        //       title: "CGS",
        //       subtitle: "C-1-1",
        //       children: [],
        //     },
        //   ],
        // },
      ],
      title: "",
      body: "",
      AddShow: false,
      addDebit: 0,
      addCredit: 0,
      AddRecord: {},
      account_name: "",
      alert: false,
      viewId: "",
      viewName: "",
      vewDate: "",
      viewDebit: 0,
      viewCredit: 0,
      viewAccount: false,
      add: {},
      account_id: 0,
      startDate: new Date(),
      editId: "",
      editName: "",
      editDate: "",
      editDebit: 0,
      editCredit: 0,
      editAccount: false,
      loading: true,
    };
  }
  getNodeKey = ({ treeIndex }) => treeIndex;
  logTreeIndex = ({ treeIndex, node }) => {
    console.log(`Node “${node.title}” has treeIndex: ${treeIndex}`);
  };
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentDidMount() {
    this.setState({ loading: true });
    var customers = [];
    var vendor = [];
    fetch(url + "inventry/client", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        client.map((acc) => {
          var details = {
            debit: acc.debit,
            credit: acc.credit,
            date: acc.date,
            id: acc.id,
            name: acc.name,
          };
          if (acc.type == "customer") {
            customers.push({
              title: acc.name,
              subtitle: acc.id,
              children: [],
              details: details,
            });
          } else {
            vendor.push({
              title: acc.name,
              subtitle: acc.id,
              children: [],
              details: details,
            });
          }
        });
        fetch(url + "inventry/accounts", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status == 400) {
              alert("No Category Found");
            } else {
              return res.json();
            }
          })
          .then((accounts) => {
            console.log(accounts);
            var current_assets = [];
            var cash = [];
            var bank = [];
            var inventry = {};
            var sales = [];
            var cgs = {};
            var current_liabilities = [];
            var expenses = [];
            var owner_equity = [];
            var short_term_liabilities = [];
            var long_term_liabilities = [];
            var other_income = [];
            var non_current_liabilities = [];

            var obj = {};

            accounts.map((acc) => {
              var details = {
                debit: acc.debit,
                credit: acc.credit,
                date: acc.date,
                id: acc.id,
                name: acc.title,
              };

              if (obj[acc.type] == undefined) {
                obj[acc.type] = [
                  {
                    title: acc.title,
                    subtitle: acc.id,
                    children: [],
                    details: details,
                  },
                ];
              } else {
                obj[acc.type].push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
            });
            accounts.map((acc) => {
              var details = {
                debit: acc.debit,
                credit: acc.credit,
                date: acc.date,
                id: acc.id,
                name: acc.title,
              };
              if (acc.type == "CASH") {
                cash.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "BANK") {
                bank.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "Inventory") {
                inventry = {
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                };
              }
              if (acc.type == "Sales") {
                sales.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "CGS") {
                cgs = {
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                };
              }
              if (acc.type == "Current Liabilities") {
                current_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "Expenses") {
                expenses.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Owner's Equity") {
                owner_equity.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Long Term Liabilities") {
                long_term_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Short Term Loan") {
                short_term_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Other Income") {
                other_income.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Non-Current Assets") {
                non_current_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
            });
            var tree =
              this.props.type == "asset"
                ? [
                    {
                      title: "Assets",
                      subtitle: "A-1",
                      children: [
                        {
                          title: "Current Assets",
                          subtitle: "A-1-1",
                          children: [
                            {
                              title: "CASH",
                              subtitle: "A-1-1-1",
                              children: cash,
                            },
                            {
                              title: "BANK",
                              subtitle: "A-1-1-2",
                              children: bank,
                            },
                            {
                              title: "Account Receivables",
                              subtitle: "A-1-1-3",
                              children: customers,
                            },
                            inventry,
                          ],
                        },
                        {
                          title: "Non-Current Assets",
                          subtitle: "A-2-1",
                          children: [...non_current_liabilities],
                        },
                      ],
                    },
                  ]
                : this.props.type == "revenue"
                ? [
                    {
                      title: "Revenue",
                      subtitle: "R-1",
                      children: [
                        {
                          title: "Sales",
                          subtitle: "R-1-1",
                          children: sales,
                        },
                        {
                          title: "Other Income",
                          subtitle: "R-1-2",
                          children: [...other_income],
                        },
                      ],
                    },
                  ]
                : this.props.type == "currentliabilities"
                ? [
                    {
                      title: "Current Liabilities",
                      subtitle: "L-1",
                      children: [
                        {
                          title: "Vendors",
                          subtitle: "L-1-2",
                          children: vendor,
                        },
                        {
                          title: "Short Term Loan",
                          subtitle: "L-1-2",
                          children: [...short_term_liabilities],
                        },
                        ...current_liabilities,
                      ],
                    },
                  ]
                : this.props.type == "longtermliabilities"
                ? [
                    {
                      title: "Long Term Liabilities",
                      subtitle: "L-2",
                      children: [...long_term_liabilities],
                    },
                  ]
                : this.props.type == "ownerequity"
                ? [
                    {
                      title: "Owner's Equity",
                      subtitle: "O-1",
                      children: owner_equity,
                    },
                  ]
                : this.props.type == "expense"
                ? [
                    {
                      title: "Expenses",
                      subtitle: "E-1",
                      children: [...expenses],
                    },
                  ]
                : this.props.type == "costofsales"
                ? [cgs]
                : null;

            this.setState({ treeData: tree });
            this.setState({ loading: false });
          });
      });
  }
  addNode(rowInfo, title, subtitle, details) {
    let NEW_NODE = {
      title: title,
      subtitle: subtitle,
      children: [],
      details: details,
    };
    let { node, treeIndex, path } = rowInfo;
    let parentNode = getNodeAtPath({
      treeData: this.state.treeData,
      path: path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
    });
    let getNodeKey = ({ node: object, treeIndex: number }) => {
      return number;
    };
    let parentKey = getNodeKey(parentNode);
    if (parentKey == -1) {
      parentKey = null;
    }
    let newTree = addNodeUnderParent({
      treeData: this.state.treeData,
      newNode: NEW_NODE,
      expandParent: true,
      parentKey: parentKey,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    this.setState({ treeData: newTree.treeData });
    var data = {
      debit: this.state.addDebit,
      credit: this.state.addCredit,
      date: this.state.startDate,
      id: this.state.account_id,
      title: this.state.account_name,
      type: rowInfo.node.title,
    };
    fetch(url + "inventry/accounts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 200) {
        this.setState({ AddShow: false });
        this.setState({ title: "Alert" });
        this.setState({ body: "Account Created Successfully!!" });
        this.setState({ alert: true });
        this.setState({ account_name: "" });
        this.setState({ addDebit: "" });
        this.setState({ addCredit: "" });
      } else {
        this.setState({ AddShow: false });

        this.setState({ title: "Alert" });
        this.setState({ body: "Error!!" });

        this.setState({ alert: true });
        this.setState({ account_name: "" });
        this.setState({ addDebit: "" });
        this.setState({ addCredit: "" });
      }
    });
  }

  removeNode(rowInfo) {
    let { node, treeIndex, path } = rowInfo;
    this.setState({
      treeData: removeNodeAtPath({
        treeData: this.state.treeData,
        path: path, // You can use path from here
        getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
          // console.log(number);
          return number;
        },
        ignoreCollapsed: false,
      }),
    });
  }

  updateTreeData(treeData) {
    this.setState({ treeData });
  }
  Submit(e) {
    e.preventDefault();
    var details = {
      debit: this.state.addDebit,
      credit: this.state.addCredit,
      date: this.state.startDate,
      id: this.state.account_id,
      name: this.state.account_name,
    };
    this.addNode(
      this.state.AddRecord,
      this.state.account_name,
      this.state.account_id,
      details
    );
  }

  Update(e) {
    e.preventDefault();

    var details = {
      debit: this.state.editDebit,
      credit: this.state.editCredit,
      date: this.state.editDate,
      id: this.state.editId,
      title: this.state.editName,
    };

    fetch(url + "inventry/accounts", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    }).then((res) => {
      if (res.status == 200) {
        this.setState({ editAccount: false });
        this.setState({ title: "Alert" });
        this.setState({ body: "Account Update Successfully!!" });
        this.setState({ alert: true });
        this.setState({ account_name: "" });
        this.setState({ addDebit: "" });
        this.setState({ addCredit: "" });
        this.Reload();
      } else {
        this.setState({ editAccount: false });

        this.setState({ title: "Alert" });
        this.setState({ body: "Error!!" });

        this.setState({ alert: true });
        this.setState({ account_name: "" });
        this.setState({ addDebit: "" });
        this.setState({ addCredit: "" });
      }
    });
    // this.addNode(this.state.AddRecord, this.state.account_name, this.state.account_id, details)
  }

  Reload() {
    var customers = [];
    var vendor = [];
    fetch(url + "inventry/client", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        client.map((acc) => {
          var details = {
            debit: acc.debit,
            credit: acc.credit,
            date: acc.date,
            id: acc.id,
            name: acc.name,
          };
          if (acc.type == "customer") {
            customers.push({
              title: acc.name,
              subtitle: acc.id,
              children: [],
              details: details,
            });
          } else {
            vendor.push({
              title: acc.name,
              subtitle: acc.id,
              children: [],
              details: details,
            });
          }
        });
        fetch(url + "inventry/accounts", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status == 400) {
              alert("No Category Found");
            } else {
              return res.json();
            }
          })
          .then((accounts) => {
            console.log(accounts);
            var current_assets = [];
            var cash = [];
            var bank = [];
            var inventry = {};
            var sales = [];
            var cgs = {};
            var current_liabilities = [];
            var expenses = [];
            var owner_equity = [];
            var short_term_liabilities = [];
            var long_term_liabilities = [];
            var other_income = [];
            var non_current_liabilities = [];

            var obj = {};

            accounts.map((acc) => {
              var details = {
                debit: acc.debit,
                credit: acc.credit,
                date: acc.date,
                id: acc.id,
                name: acc.title,
              };

              if (obj[acc.type] == undefined) {
                obj[acc.type] = [
                  {
                    title: acc.title,
                    subtitle: acc.id,
                    children: [],
                    details: details,
                  },
                ];
              } else {
                obj[acc.type].push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
            });
            accounts.map((acc) => {
              var details = {
                debit: acc.debit,
                credit: acc.credit,
                date: acc.date,
                id: acc.id,
                name: acc.title,
              };
              if (acc.type == "CASH") {
                cash.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "BANK") {
                bank.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "Inventory") {
                inventry = {
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                };
              }
              if (acc.type == "Sales") {
                sales.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "CGS") {
                cgs = {
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                };
              }
              if (acc.type == "Current Liabilities") {
                current_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: [],
                  details: details,
                });
              }
              if (acc.type == "Expenses") {
                expenses.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Owner's Equity") {
                owner_equity.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Long Term Liabilities") {
                long_term_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Short Term Loan") {
                short_term_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Other Income") {
                other_income.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
              if (acc.type == "Non-Current Assets") {
                non_current_liabilities.push({
                  title: acc.title,
                  subtitle: acc.id,
                  children: obj[acc.title] == {} ? [] : obj[acc.title],
                  details: details,
                });
              }
            });
            var tree =
              this.props.type == "asset"
                ? [
                    {
                      title: "Assets",
                      subtitle: "A-1",
                      children: [
                        {
                          title: "Current Assets",
                          subtitle: "A-1-1",
                          children: [
                            {
                              title: "CASH",
                              subtitle: "A-1-1-1",
                              children: cash,
                            },
                            {
                              title: "BANK",
                              subtitle: "A-1-1-2",
                              children: bank,
                            },
                            {
                              title: "Account Receivables",
                              subtitle: "A-1-1-3",
                              children: customers,
                            },
                            inventry,
                          ],
                        },
                        {
                          title: "Non-Current Assets",
                          subtitle: "A-2-1",
                          children: [...non_current_liabilities],
                        },
                      ],
                    },
                  ]
                : this.props.type == "revenue"
                ? [
                    {
                      title: "Revenue",
                      subtitle: "R-1",
                      children: [
                        {
                          title: "Sales",
                          subtitle: "R-1-1",
                          children: sales,
                        },
                        {
                          title: "Other Income",
                          subtitle: "R-1-2",
                          children: [...other_income],
                        },
                      ],
                    },
                  ]
                : this.props.type == "currentliabilities"
                ? [
                    {
                      title: "Current Liabilities",
                      subtitle: "L-1",
                      children: [
                        {
                          title: "Vendors",
                          subtitle: "L-1-2",
                          children: vendor,
                        },
                        {
                          title: "Short Term Loan",
                          subtitle: "L-1-2",
                          children: [...short_term_liabilities],
                        },
                        ...current_liabilities,
                      ],
                    },
                  ]
                : this.props.type == "longtermliabilities"
                ? [
                    {
                      title: "Long Term Liabilities",
                      subtitle: "L-2",
                      children: [...long_term_liabilities],
                    },
                  ]
                : this.props.type == "longtermliabilities"
                ? [
                    {
                      title: "Owner's Equity",
                      subtitle: "O-1",
                      children: owner_equity,
                    },
                  ]
                : this.props.type == "expense"
                ? [
                    {
                      title: "Expenses",
                      subtitle: "E-1",
                      children: [...expenses],
                    },
                  ]
                : this.props.type == "costofsales"
                ? [cgs]
                : null;
            console.log("---------");
            console.log(tree);
            this.setState({ treeData: tree });
          });
      });
  }
  render() {

    return (
      <div>
        {this.state.loading && (
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={
                (this.props.type === "asset"
                  ? "#01579b"
                  : this.props.type === "revenue"
                  ? "#FF5600"
                  : this.props.type === "currentliabilities"
                  ? "#7000FF"
                  : this.props.type === "longtermliabilities"
                  ? "#069837"
                  : this.props.type === "ownerequity"
                  ? "#C100BA"
                  : this.props.type === "expense"
                  ? "#FF0000"
                  : this.props.type === "costofsales"
                  ? "#03CB17"
                  : "")
              }
              width={50}
              height={70}
            />
          </Box>
        )}

        {!this.state.loading && (
          <Grid container style={{ height: "100%" }}>
            <Grid sm={8} style={{ height: "100em" }}>
              <div style={{ height: "100%" }}>
                <SortableTree
                  isVirtualized={false}
                  treeData={this.state.treeData}
                  onChange={this.updateTreeData}
                  id={this.props.type}
                  getNodeKey={this.getNodeKey}
                  onMoveNode={({ treeIndex, node }) =>
                    this.logTreeIndex({ treeIndex, node })
                  }
                  canDrag={false}
                  generateNodeProps={(rowInfo) => {
                    console.log(rowInfo);
                    if (
                      rowInfo.node.title == "Cost of Sales" ||
                      rowInfo.node.title == "FBR - Import Duties & Taxes" ||
                      rowInfo.node.title == "General Sales Tax"
                    ) {
                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon style={{ color: "#2196F3" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }

                    // Long Term Liabilities
                    if (
                      rowInfo.parentNode != null &&
                      rowInfo.parentNode.title == "Long Term Liabilities"
                    ) {
                      return {
                        buttons: [
                          <div>
                            <IconButton
                              style={{ color: "blue" }}
                              label="Add"
                              onClick={(event) => {
                                if (
                                  rowInfo.node.children == null ||
                                  rowInfo.node.children.length == 0
                                ) {
                                  var id = rowInfo.node.subtitle + "-1";
                                  this.setState({ account_id: id });
                                } else {
                                  var num = rowInfo.node.children.length + 1;
                                  var id = rowInfo.node.subtitle + "-" + num;
                                  this.setState({ account_id: id });
                                }
                                this.setState({ account_name: "" });
                                this.setState({ addDebit: 0 });
                                this.setState({ addCredit: 0 });
                                this.setState({ AddRecord: rowInfo });
                                this.setState({ AddShow: true });
                              }}
                            >
                              <AddCircleIcon style={{ color: "#4CAF50" }} />
                            </IconButton>
                            <IconButton
                              label="Edit"
                              // style={{ color: "green" }}
                              onClick={(event) => {
                                this.setState({
                                  editId: rowInfo.node.details.id,
                                });
                                this.setState({ editName: rowInfo.node.title });
                                this.setState({
                                  editDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  editDate: rowInfo.node.details.date,
                                });
                                this.setState({
                                  editCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ editAccount: true });
                              }}
                            >
                              <EditIcon style={{ color: "#FFC107" }} />
                            </IconButton>

                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon style={{ color: "#2196F3" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    if (
                      rowInfo.node.title == "Long Term Liabilities" ||
                      rowInfo.node.title == "Non-Current Assets"
                    ) {
                      this.state.add[rowInfo.treeIndex] = "";

                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Add"
                              onClick={(event) => {
                                if (
                                  rowInfo.node.children == null ||
                                  rowInfo.node.children.length == 0
                                ) {
                                  var id = rowInfo.node.subtitle + "-1";
                                  this.setState({ account_id: id });
                                } else {
                                  var num = rowInfo.node.children.length + 1;
                                  var id = rowInfo.node.subtitle + "-" + num;
                                  this.setState({ account_id: id });
                                }
                                this.setState({ account_name: "" });
                                this.setState({ addDebit: 0 });
                                this.setState({ addCredit: 0 });
                                this.setState({ AddRecord: rowInfo });
                                this.setState({ AddShow: true });
                              }}
                            >
                              <AddCircleIcon style={{ color: "#4CAF50" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    // Owner Equity
                    if (
                      rowInfo.node.title == "Owner's Equity" ||
                      ((rowInfo.node.subtitle + "").includes("O-1") &&
                        rowInfo.node.subtitle.split("-").length == 3)
                    ) {
                      this.state.add[rowInfo.treeIndex] = "";

                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Add"
                              onClick={(event) => {
                                if (
                                  rowInfo.node.children == null ||
                                  rowInfo.node.children.length == 0
                                ) {
                                  var id = rowInfo.node.subtitle + "-1";
                                  this.setState({ account_id: id });
                                } else {
                                  var num = rowInfo.node.children.length + 1;
                                  var id = rowInfo.node.subtitle + "-" + num;
                                  this.setState({ account_id: id });
                                }
                                this.setState({ account_name: "" });
                                this.setState({ addDebit: 0 });
                                this.setState({ addCredit: 0 });
                                this.setState({ AddRecord: rowInfo });
                                this.setState({ AddShow: true });
                              }}
                            >
                              <AddCircleIcon style={{ color: "#4CAF50" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    // Long Term Liabilities Limit
                    if (
                      (rowInfo.node != null &&
                        (rowInfo.node.subtitle + "").includes("L-2") &&
                        rowInfo.node.subtitle.split("-").length == 4) ||
                      (rowInfo.node != null &&
                        (rowInfo.node.subtitle + "").includes("O-1") &&
                        rowInfo.node.subtitle.split("-").length == 4)
                    ) {
                      return {
                        buttons: [
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <IconButton
                              label="Edit"
                              style={{ color: "white" }}
                              onClick={(event) => {
                                this.setState({
                                  editId: rowInfo.node.details.id,
                                });
                                this.setState({ editName: rowInfo.node.title });
                                this.setState({
                                  editDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  editDate: rowInfo.node.details.date,
                                });
                                this.setState({
                                  editCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ editAccount: true });
                              }}
                            >
                              <EditIcon style={{ color: "#FFC107" }} />
                            </IconButton>

                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon style={{ color: "#2196F3" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    // Expenses Limit
                    if (
                      (rowInfo.node != null &&
                        (rowInfo.node.subtitle + "").includes("E-") &&
                        rowInfo.node.subtitle.split("-").length == 4) ||
                      (rowInfo.node != null &&
                        (rowInfo.node.subtitle + "").includes("R-") &&
                        rowInfo.node.subtitle.split("-").length == 4)
                    ) {
                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Edit"
                              style={{ color: "white" }}
                              onClick={(event) => {
                                this.setState({
                                  editId: rowInfo.node.details.id,
                                });
                                this.setState({ editName: rowInfo.node.title });
                                this.setState({
                                  editDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  editDate: rowInfo.node.details.date,
                                });
                                this.setState({
                                  editCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ editAccount: true });
                              }}
                            >
                              <EditIcon style={{ color: "#FFC107" }} />
                            </IconButton>
                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon style={{ color: "#2196F3" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    if (
                      (rowInfo.node != null &&
                        (rowInfo.node.subtitle + "").includes("E-") &&
                        rowInfo.node.subtitle.split("-").length == 4) ||
                      (rowInfo.node != null &&
                        (rowInfo.node.subtitle + "").includes("L-1") &&
                        rowInfo.node.subtitle.split("-").length == 4)
                    ) {
                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Edit"
                              style={{ color: "white" }}
                              onClick={(event) => {
                                this.setState({
                                  editId: rowInfo.node.details.id,
                                });
                                this.setState({ editName: rowInfo.node.title });
                                this.setState({
                                  editDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  editDate: rowInfo.node.details.date,
                                });
                                this.setState({
                                  editCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ editAccount: true });
                              }}
                            >
                              <EditIcon style={{ color: "#FFC107" }} />
                            </IconButton>

                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon style={{ color: "#2196F3" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    // In Main Head
                    if (
                      (rowInfo.node.title == "Expenses" ||
                        (rowInfo.parentNode != null &&
                          rowInfo.parentNode.title == "Expenses") ||
                        rowInfo.node.title == "Long Term Liabilities" ||
                        (rowInfo.parentNode != null &&
                          rowInfo.parentNode.title ==
                            "Long Term Liabilities") ||
                        rowInfo.node.title == "Short Term Loan" ||
                        rowInfo.node.title == "Other Income") &&
                      (rowInfo.path.length == 1 || rowInfo.path.length == 2)
                    ) {
                      this.state.add[rowInfo.treeIndex] = "";

                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Add"
                              onClick={(event) => {
                                if (
                                  rowInfo.node.children == null ||
                                  rowInfo.node.children.length == 0
                                ) {
                                  var id = rowInfo.node.subtitle + "-1";
                                  this.setState({ account_id: id });
                                } else {
                                  var num = rowInfo.node.children.length + 1;
                                  var id = rowInfo.node.subtitle + "-" + num;
                                  this.setState({ account_id: id });
                                }
                                this.setState({ account_name: "" });
                                this.setState({ addDebit: 0 });
                                this.setState({ addCredit: 0 });
                                this.setState({ AddRecord: rowInfo });
                                this.setState({ AddShow: true });
                              }}
                            >
                              <AddCircleIcon style={{ color: "#4CAF50" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    if (
                      rowInfo.parentNode == null ||
                      rowInfo.path.length == 2
                    ) {
                      return {
                        buttons: [
                          <div>
                            {/* <button label='Add'
                          onClick={(event) => {  
                            console.log(rowInfo)
                            if(rowInfo.node.children==null || rowInfo.node.children.length==0){
                            var id=rowInfo.node.subtitle+"-1";
                            this.setState({account_id:id})
                            console.log("Hello")
                          }else{
                            var num=rowInfo.node.children.length+1
                            var id=rowInfo.node.subtitle+"-"+num;
                            this.setState({account_id:id})
                            console.log("Hello")
                            
                          }
                          this.setState({account_name:''})
                          this.setState({addDebit:0})
                          this.setState({addCredit:0})
                          this.setState({ AddShow: true }) 
                           }}>Add</button> */}
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    if (
                      rowInfo.parentNode.title == "Vendors" ||
                      rowInfo.node.title == "Inventory"
                    ) {
                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon style={{ color: "#2196F3" }} />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }
                    if (
                      rowInfo.path.length == 4 ||
                      rowInfo.parentNode.title == "Long Term Liabilities"
                    ) {
                      return {
                        buttons: [
                          <div>
                            <IconButton
                              label="Edit"
                              style={{ color: "white" }}
                              onClick={(event) => {
                                this.setState({
                                  editId: rowInfo.node.details.id,
                                });
                                this.setState({ editName: rowInfo.node.title });
                                this.setState({
                                  editDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  editDate: rowInfo.node.details.date,
                                });
                                this.setState({
                                  editCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ editAccount: true });
                              }}
                            >
                              <EditIcon style={{ color: "#FFC107" }} />
                            </IconButton>

                            <IconButton
                              label="Details"
                              onClick={(event) => {
                                this.setState({
                                  viewId: rowInfo.node.details.id,
                                });
                                this.setState({ viewName: rowInfo.node.title });
                                this.setState({
                                  viewDebit: rowInfo.node.details.debit,
                                });
                                this.setState({
                                  viewCredit: rowInfo.node.details.credit,
                                });
                                this.setState({ viewAccount: true });
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </div>,
                        ],
                        style: {
                          height: "50px",
                        },
                      };
                    }

                    if (rowInfo.node.title == "Account Receivables") {
                      return;
                    }
                    if (rowInfo.node.title == "Credit Sales") {
                      return;
                    }
                    if (rowInfo.node.title == "Cash Sales") {
                      return;
                    }

                    this.state.add[rowInfo.treeIndex] = "";

                    return {
                      buttons: [
                        <div>
                          <IconButton
                            label="Add"
                            onClick={(event) => {
                              if (
                                rowInfo.node.children == null ||
                                rowInfo.node.children.length == 0
                              ) {
                                var id = rowInfo.node.subtitle + "-1";
                                this.setState({ account_id: id });
                              } else {
                                var num = rowInfo.node.children.length + 1;
                                var id = rowInfo.node.subtitle + "-" + num;
                                this.setState({ account_id: id });
                              }
                              this.setState({ account_name: "" });
                              this.setState({ addDebit: 0 });
                              this.setState({ addCredit: 0 });
                              this.setState({ AddRecord: rowInfo });
                              this.setState({ AddShow: true });
                            }}
                          >
                            <AddCircleIcon style={{ color: "#4CAF50" }} />
                          </IconButton>
                        </div>,
                      ],
                      style: {
                        height: "50px",
                      },
                    };
                  }}
                />
              </div>
            </Grid>

            <Modal
              open={this.state.AddShow}
              onClose={() => this.setState({ AddShow: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid lg={12}>
                  <form onSubmit={this.Submit} className="Notifications">
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={12}>
                        <b>Account ID</b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.account_id}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={12}>
                        <b>Account Name</b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.account_name}
                          required
                          onChange={(e) => {
                            this.setState({ account_name: e.target.value });
                          }}
                        />
                      </Grid>
                    </Grid>

                    <h3 bold style={{ textAlign: "center" }}>
                      Opening Balance
                    </h3>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={12}>
                        <b>Date</b>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            value={this.state.startDate}
                            onChange={(startDate) => {
                              this.setState({ startDate: startDate });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={5}>
                        <b>
                          Debit ({this.numberWithCommas(this.state.addDebit)})
                        </b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.addDebit}
                          type="number"
                          onChange={(e) => {
                            var debit = e.target.value;
                            if (this.state.addCredit > 0 && debit > 0) {
                              this.setState({ addCredit: 0 });
                            }
                            this.setState({ addDebit: e.target.value });
                          }}
                          min="0"
                          steps="any"
                          required
                          placeholder="Debit..."
                        />
                      </Grid>
                      <Grid sm={2}></Grid>
                      <Grid sm={5}>
                        <b>
                          Credit ({this.numberWithCommas(this.state.addCredit)})
                        </b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.addCredit}
                          type="number"
                          onChange={(e) => {
                            var credit = e.target.value;
                            if (this.state.addDebit > 0 && credit > 0) {
                              this.setState({ addDebit: 0 });
                            }

                            this.setState({ addCredit: e.target.value });
                          }}
                          min="0"
                          steps="any"
                          required
                          placeholder="Credit..."
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid sm={{ span: 10, offset: 8 }}>
                        <br />
                        <Button variant="contained" type="submit">
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Box>
            </Modal>

            {/* //////////////////////// Edit Model */}
            <Modal
              open={this.state.editAccount}
              onClose={() => this.setState({ editAccount: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid lg={12}>
                  <form onSubmit={this.Update} className="Notifications">
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={12}>
                        <b>Account ID</b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.editId}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={12}>
                        <b>Account Name</b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.editName}
                          required
                          onChange={(e) => {
                            this.setState({ editName: e.target.value });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <h3 bold style={{ textAlign: "center" }}>
                      Opening Balance
                    </h3>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={12}>
                        <b>Date</b>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            value={this.state.editDate}
                            onChange={(editDate) => {
                              this.setState({ editDate: editDate });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <Grid container controlId="formHorizontalEmail">
                      <Grid sm={5}>
                        <b>
                          Debit ({this.numberWithCommas(this.state.editDebit)})
                        </b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.editDebit}
                          type="number"
                          onChange={(e) => {
                            var debit = e.target.value;
                            if (this.state.editCredit > 0 && debit > 0) {
                              this.setState({ editCredit: 0 });
                            }
                            this.setState({ editDebit: e.target.value });
                          }}
                          min="0"
                          steps="any"
                          required
                          placeholder="Debit..."
                        />
                      </Grid>
                      <Grid sm={2}></Grid>
                      <Grid sm={5}>
                        <b>
                          Credit ({this.numberWithCommas(this.state.editCredit)}
                          )
                        </b>
                        <br />
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          value={this.state.editCredit}
                          type="number"
                          onChange={(e) => {
                            var credit = e.target.value;
                            if (this.state.editDebit > 0 && credit > 0) {
                              this.setState({ editDebit: 0 });
                            }

                            this.setState({ editCredit: e.target.value });
                          }}
                          min="0"
                          steps="any"
                          required
                          placeholder="Credit..."
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid sm={{ span: 10, offset: 8 }}>
                        <br />
                        <Button variant="contained" type="submit">
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Box>
            </Modal>

            <Modal
              open={this.state.viewAccount}
              onClose={() => this.setState({ viewAccount: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid container>
                  <Grid lg={12}>
                    <form className="Notifications">
                      <Grid container controlId="formHorizontalEmail">
                        <Grid sm={12}>
                          <b>Account ID</b>
                          <br />
                          <TextField
                            id="filled-basic"
                            variant="filled"
                            value={this.state.viewId}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container controlId="formHorizontalEmail">
                        <Grid sm={12}>
                          <b>Account Name</b>
                          <br />
                          <TextField
                            id="filled-basic"
                            variant="filled"
                            value={this.state.viewName}
                            disabled
                          />
                        </Grid>
                      </Grid>

                      <Grid container controlId="formHorizontalEmail">
                        <Grid sm={4}>
                          <b>Debit</b>
                          <TextField
                            id="filled-basic"
                            variant="filled"
                            value={this.numberWithCommas(this.state.viewDebit)}
                            disabled
                          />
                        </Grid>
                        <Grid sm={2}></Grid>
                        <Grid sm={4}>
                          <b>Credit</b>
                          <TextField
                            id="filled-basic"
                            variant="filled"
                            value={this.numberWithCommas(this.state.viewCredit)}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <Modal
              open={this.state.alert}
              onClose={() => this.setState({ alert: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {this.state.title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {this.state.body}
                </Typography>
              </Box>
            </Modal>
          </Grid>
        )}
      </div>
    );
  }
}
