import React, { useEffect } from "react";
import { forwardRef } from "react";
import { Divider } from "@mui/material";
import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@mui/material";

import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PreviewIcon from "@mui/icons-material/Preview";
// ServerURL
import url from "../../serverURL.js";
export default function MinimumStock() {
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  useEffect(() => {
    fetch(url + "inventry/item/reaching-min-stock")
      .then((res) => res.json())
      .then((result) => {
        setRows(result);
        setLoading(false);
      });
  }, []);

  const truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };

  return (
    <>
      {/* <Divider /> */}
      <MaterialTable
        icons={tableIcons}
        title="Items Reached Minimum Stock"
        columns={[
          {
            title: "Product Code",
            field: "code",
          },
          {
            title: "Product Name",
            field: "name",
          },
          {
            title: "Product Category",
            field: "category",
            render: (rowData) => {
              const truncatedCategory = truncateText(rowData.category, 18);
              return (
                <Tooltip
                  title={
                    <Typography fontSize="1.5em">{rowData.category}</Typography>
                  }
                >
                  <span>{truncatedCategory}</span>
                </Tooltip>
              );
            },
          },
          {
            title: "Minimum Capacity",
            field: "min_stock",
            cellStyle: (rowData) => ({
              color: "#009ACD",
              fontWeight: "bold",
            }),
          },
          {
            title: "Remaining in Stock",
            field: "stock",
            cellStyle: (rowData) => ({
              color: "red",
              fontWeight: "bold",
            }),
          },
        ]}
        isLoading={loading}
        data={rows}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
            whiteSpace: "nowrap",
            fontSize: "0.8em",
          },

          rowStyle: (rowData, index) => ({
            backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
            fontSize: "0.8em",
          }),
          search: true,
          exportButton: true,
          exportAllData: true,
          showTitle: true,
          paging: true,
          pageSize: 10,
          pageSizeOptions: false,
          showFirstLastPageButtons: false,
        }}
      />
    </>
  );
}
const rows = [
  {
    code: 123,
    name: "Ace Bat",
    category: "Imported",
    minimum: 20,
    remaining: 11,
  },
  {
    code: 456,
    name: "KC  Bat",
    category: "Good",
    minimum: 10,
    remaining: 7,
  },
];
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};
