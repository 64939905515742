import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PreviewIcon from "@mui/icons-material/Preview";
// Alert Thing
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// ServerURL
import url from "src/serverURL.js";
import CreateItem from "./addCategoryComponent";
import MaterialTable from "material-table";
import DeleteCategory from "./deleteCategory";

import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@mui/material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};

export default function AddItem() {
  const [rows, setRows] = useState([]);

  const [name, setName] = useState("");

  const [loading, setLoading] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [deleteCat, setDeleteCat] = React.useState(false);
  const [deleteCatData, setDeleteCatData] = React.useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [errorText, setErrorText] = React.useState("Invalid Details");
  const [alertText, setAlertText] = React.useState(
    "Category Added Successfully"
  );

  // Function to open modal
  const handleOpenModal = () => {
    console.log("Open Modal function calls");
    if (!modalOpen) {
      setModalOpen(true);
    }
  };

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClick = (data) => {
    if (data != undefined && data != null) {
      setAlertText(data);
    }
    setOpen(true);

    Reload();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClickError = (errorText) => {
    console.log("Error Details", errorText);
    setErrorText(errorText);
    setError(true);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };

  useEffect(() => {
    setLoading(true);
    fetch(url + "inventry/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        const box = categories.filter((data) => ({
          name: data.name,
          code: data.code,
          category: data.category,
        }));
        setRows(box);
        setLoading(false);
      });
  }, []);

  const Reload = () => {
    setLoading(true);
    fetch(url + "inventry/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        const box = categories.filter((data) => ({
          name: data.name,
          code: data.code,
          category: data.category,
        }));
        setLoading(false);
        setRows(box);
      });
  };
  const deleteCategory = (data) => {
    setLoading(true);

    console.log("Called");
    console.log(data);

    if (data == undefined && data == "") {
      return;
    }
    var data = {
      code: data.code,
    };
    fetch(url + "inventry/category", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 400) {
        handleClickError();
      } else {
        handleClick("Category Deleted");
        Reload();
      }
      setDeleteCat(false);
    });
  };

  const generateSerialNumber = (rowData) => {
    const index = rows.indexOf(rowData);
    return index + 1;
  };

  const truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <>
          {deleteCat ? (
            <DeleteCategory
              deleteCatData={deleteCatData}
              setDeleteCat={setDeleteCat}
              deleteCategory={deleteCategory}
            />
          ) : null}
          <div>
            {modalOpen && (
              <CreateItem
                handleClickError={handleClickError}
                handleClick={handleClick}
                handleCloseModal={handleCloseModal}
                isOpen={modalOpen}
              />
            )}
          </div>
        </>
        <div>
          {/* <TextField
            required
            label="Date"
            variant="filled"
            disabled
            value={getDate()}
          />
          <TextField
            required
            id="filled-required"
            label="Name"
            variant="filled"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          /> */}

          {/* <br /> */}
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {alertText}
            </Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleCloseError}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorText}
            </Alert>
          </Snackbar>
          <div
            style={{ padding: 10, marginTop: "0.5em", marginBottom: "0.5em" }}
          >
            {/* <Button variant="contained" to="#" onClick={submit}> */}
            <Button
              variant="contained"
              to="#"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Add New Category
            </Button>
          </div>
        </div>
        {/* {loading ? (
          <CircularProgress />
        ) : ( */}
        <MaterialTable
          icons={tableIcons}
          title="All Categories"
          columns={[
            {
              title: "Category Code",
              field: "code",
            },
            {
              title: "Catgeory Name",
              field: "name",
              render: (rowData) => {
                const truncatedName = truncateText(rowData.name, 18);
                return (
                  <Tooltip
                    title={
                      <Typography fontSize="1.5em">{rowData.name}</Typography>
                    }
                  >
                    <span>{truncatedName}</span>
                  </Tooltip>
                );
              },
            },
          ]}
          data={rows}
          isLoading={loading}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
              paddingRight: "1em",
              fontSize: "0.8em",
            },
            rowStyle: (rowData, index) => ({
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
              fontSize: "0.8em",
            }),
            actionsColumnIndex: -1,
            paging: true,
            pageSize: 10,
            pageSizeOptions: false,
            showFirstLastPageButtons: false,
            exportButton: true,
            exportAllData: true,
          }}
          actions={[
            {
              icon: () => <DeleteOutline />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                setDeleteCatData(rowData);
                setDeleteCat(true);
              },
            },
          ]}
        />
        {/* )} */}
      </Box>
    </>
  );
}
