import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import url from "src/serverURL";
import { Typography } from "@mui/material";

class ViewImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      extension: "",
      open: true,
    };
    console.log("Props are:", props.viewData);
  }
  handleClickOpen = () => () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.viewClose();
  };

  componentDidMount() {
    fetch(url + "inventry/payment/image/" + this.props.viewData.image, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Image Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        this.setState({
          file: this.props.viewData.datatype + "base64," + response.data,
        });
      });
  }

  render() {
    console.log("Datatype:", this.props.viewData.datatype);

    return (
      <>
        <Dialog
          open={this.state.open}
          fullWidth={true}
          maxWidth="sm"
          onClose={this.handleClose}
        >
          <DialogTitle>View Payment Document (Image, Pdf etc)</DialogTitle>
          <DialogContent>
            {this.props.viewData.datatype ? (
              <>
                {this.props.viewData.datatype.includes(
                  "data:application/pdf"
                ) ? (
                  <iframe
                    src={this.state.file}
                    height="400px"
                    width="100%"
                  ></iframe>
                ) : (
                  <img
                    src={this.state.file}
                    style={{
                      height: "400px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="Receipt Image"
                  />
                )}
              </>
            ) : (
              <>
                <Typography>No File Exist</Typography>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default ViewImageComponent;
