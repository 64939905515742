import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PreviewIcon from "@mui/icons-material/Preview";
import AddIcon from "@mui/icons-material/Add";
// Alert Thing
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
// ServerURL
import url from "src/serverURL.js";
import MaterialTable from "material-table";
import EditItem from "./edit";
import CreateItem from "./addItemComponent";

import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@mui/material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};

export default function AddItem() {
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [category, setCategories] = useState([]);

  const [loading, setLoading] = React.useState(true);

  // -->> Success and Error Hooks
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("Invalid Details");
  const [alertText, setAlertText] = React.useState("Item Added Successfully !");

  // -->> Edit Hooks
  const [editFlag, setEditFlag] = React.useState(false);
  const [editData, setEditData] = React.useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  // Function to open modal
  const handleOpenModal = () => {
    console.log("Open Modal function calls");
    if (!modalOpen) {
      setModalOpen(true);
    }
  };

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // -->> Click Function for Success
  const handleClick = (data) => {
    if (data != undefined && data != null) {
      setAlertText(data);
    }
    setOpen(true);
    Reload();
  };

  // -->> Click Function for Error
  const handleClickError = (errorText) => {
    setErrorText(errorText);
    setError(true);
  };

  // -->> Function to close the Snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // -->> Everytime when app renders this useEffect function will run
  useEffect(() => {
    setLoading(true);
    fetch(url + "inventry/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        const box = categories.filter((data) => ({
          name: data.name,
          code: data.code,
          category: data.category,
        }));
        setCategories(box);
      });

    fetch(url + "inventry/item", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        setRows(categories);
        setLoading(false);
      });
  }, []);

  // -->> Reload Function
  const Reload = () => {
    setLoading(true);
    fetch(url + "inventry/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        const box = categories.filter((data) => ({
          name: data.name,
          code: data.code,
          category: data.category,
        }));
        setCategories(box);
      });

    fetch(url + "inventry/item", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        setRows(categories);
        setLoading(false);
      });
  };
  // -->> Close Edit Dialog
  const editClose = () => {
    setEditFlag(false);
  };

  const generateSerialNumber = (rowData) => {
    const index = rows.indexOf(rowData);
    return index + 1;
  };

  const truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        {editFlag ? (
          <EditItem
            editData={editData}
            editClose={editClose}
            handleClickError={handleClickError}
            handleClick={handleClick}
          />
        ) : null}

        <div>
          {modalOpen && (
            <CreateItem
              handleClickError={handleClickError}
              handleClick={handleClick}
              handleCloseModal={handleCloseModal}
              isOpen={modalOpen}
            />
          )}
        </div>

        <div>
          {/* {!loading ? (
            <>
              
            </>
          ) : null} */}
          {/* <br /> */}
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {alertText}
            </Alert>
          </Snackbar>

          <Snackbar
            open={error}
            autoHideDuration={3000}
            onClose={handleCloseError}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleCloseError}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorText}
            </Alert>
          </Snackbar>

          <div
            style={{ padding: 10, marginTop: "0.5em", marginBottom: "0.5em" }}
          >
            <Button
              variant="contained"
              onClick={handleOpenModal}
              startIcon={<AddIcon />}
            >
              Add New Item
            </Button>
            {/* <Button variant="contained" to="#" onClick={submit}>
              Add Item
            </Button> */}
          </div>
        </div>
        {/* {loading ? (
          <CircularProgress />
        ) : ( */}
        <MaterialTable
          icons={tableIcons}
          title="All Items"
          columns={[
            {
              title: "Sr.",
              render: (rowData) => generateSerialNumber(rowData),
            },
            { title: "Code", field: "code" },
            {
              title: "Name",
              field: "name",
              render: (rowData) => {
                const truncatedName = truncateText(rowData.name, 18);
                return (
                  <Tooltip
                    title={
                      <Typography fontSize="1.5em">{rowData.name}</Typography>
                    }
                  >
                    <span>{truncatedName}</span>
                  </Tooltip>
                );
              },
            },
            {
              title: "Category",
              field: "category",
              render: (rowData) => {
                const truncatedCategory = truncateText(rowData.category, 18);
                return (
                  <Tooltip
                    title={
                      <Typography fontSize="1.5em">
                        {rowData.category}
                      </Typography>
                    }
                  >
                    <span>{truncatedCategory}</span>
                  </Tooltip>
                );
              },
            },
            { title: "Purchase Price", field: "cost" },
            { title: "Sale Price", field: "rate" },
          ]}
          data={rows}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
              fontSize: "0.8em",
            },
            actionsColumnIndex: -1,
            rowStyle: (rowData, index) => ({
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
              fontSize: "0.8em",
            }),
            paging: true,
            pageSize: 10,
            pageSizeOptions: false,
            showFirstLastPageButtons: false,
            sorting: true,
            exportButton: true,
            exportAllData: true,
          }}
          isLoading={loading}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setEditData(rowData);
                setEditFlag(true);
              },
            },
            {
              icon: () => <DeleteOutline />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                const isConfirmed = window.confirm(
                  "Are you sure you want to delete this item?"
                );
                if (isConfirmed) {
                  var data = {
                    code: rowData.code,
                  };
                  fetch(url + "inventry/item", {
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                  }).then((res) => {
                    if (res.status == 400) {
                      handleClickError("Item Not Deleted !");
                      return;
                    }
                    handleClick("Deleted Successfully!");
                    handleClose();
                  });
                }
              },
            },
          ]}
        />
        {/* )} */}
      </Box>
    </>
  );
}
