import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
// import aiodock from "../hitech.png";
import aiodock from "../wise vision.png";
import FBR from "../fbr-pakistan.png";

// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 5,
  },
  logo: {
    width: 180,
    height: 80,
  },
  FBR_logo: {
    width: 60,
    height: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // margin: 0
  },
  section: {
    // margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tableHeader: {
    fontSize: 5,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    padding: 5,
    // backgroundColor: "#EEEEEE",
    marginTop: 7,
    fontFamily: "Helvetica-Bold",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableRow: {
    fontSize: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderBottom: "1px dashed #e0e0e0",
  },
  tableRowOdd: {
    backgroundColor: "#DDDDDD",
  },
  tableTotal: {
    fontSize: 12,
    fontWeight: "bold",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEEE",
    marginTop: 10,
  },
});
// Create styles for the PDF
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     padding: 10,
//   },
//   logo: {
//     width: 180,
//     height: 80,
//     textAlign: "center",
//   },
//   FBR_logo: {
//     width: 60,
//     height: 30,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     // margin: 0
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
//   title: {
//     fontSize: 16,
//     fontFamily: "Helvetica-Bold",
//     fontWeight: "bold",
//     marginBottom: 10,
//   },
//   subtitle: {
//     fontSize: 14,
//     marginBottom: 5,
//   },
//   tableHeader: {
//     fontSize: 10,
//     display: "flex",
//     flexDirection: "row",
//     fontWeight: "bold",
//     padding: 5,
//     backgroundColor: "#EEEEEE",
//   },
//   tableRow: {
//     fontSize: 10,
//     display: "flex",
//     flexDirection: "row",
//     padding: 5,
//   },
//   tableRowOdd: {
//     backgroundColor: "#DDDDDD",
//   },
//   tableTotal: {
//     fontSize: 12,
//     fontWeight: "bold",
//     padding: 5,
//     display: "flex",
//     flexDirection: "column",
//     backgroundColor: "#EEEEEE",
//     marginTop: 10,
//   },
// });

const separator = (numb) => {
  var str = (numb + "").split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

const InvoicePdf = ({
  invoiceNo,
  client,
  accountTitle,
  paymentMethod,
  paymentTitle,
  paymentId,
  totalAmount,
  chequeNo,
  date,
  advanceTax,
  remarks,
}) => {
  return (
    <Document>
      <Page size={{ width: "415PX" }} style={styles.page}>
        <View style={styles.section}>
          {/* ****************************************************************************************** */}
          {/* Company Logo  */}
          <View style={styles.logo}>
            <Image
              style={{ fontSize: 12, textAlign: "center", width: "100%" }}
              src={aiodock}
            />
          </View>

          {/* ****************************************************************************************** */}
          {/* Company Information */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              bottom: 0,
              position: "relative",
              paddingLeft: 6,
              paddingRight: 6,
              marginTop: 5,
            }}
            fixed
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",

                textAlign: "center",
              }}
            >
              <Text
                style={{
                  // fontFamily: "Helvetica-Bold",
                  // textAlign: "center",
                  marginTop: 1,
                  fontSize: 6,
                }}
              >
                {
                  "Address: Office No. 14, Ground Floor, Munawar Centre, Mall Road, Lahore"
                }
              </Text>
              {/* Phone Number */}
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 6,
                }}
              >
                Ph: 0333-1431402
              </Text>
              {/* STRN */}
              {/* <Text
                style={{
                  marginTop: 5,
                  fontSize: 6,
                }}
              >
                STRN # 0000
              </Text> */}
              {/* NTN */}
              {/* <Text
                style={{
                  marginTop: 5,
                  fontSize: 6,
                }}
              >
                NTN # 0000
              </Text> */}
            </View>
          </View>
          {/* ****************************************************************************************** */}

          <View style={{ border: "1px solid black", marginTop: 10 }}>
            {/* ****************************************************************************************** */}
            {/* Recipt Number & Date */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 3,
                  // fontFamily: "Helvetica-Bold",
                }}
              >
                <View>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                    }}
                  >
                    {"Payment No: "}
                  </Text>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                      textAlign: "right",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {invoiceNo}
                  </Text>
                </View>
              </Text>

              {/* Date */}
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  // paddingBottom: 7,
                  paddingTop: 3,
                  paddingRight: 3,
                  textAlign: "right",
                  // fontFamily: "Helvetica-Bold",
                }}
              >
                <View>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                      textAlign: "right",
                    }}
                  >
                    {"Date: "}
                  </Text>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                      textAlign: "right",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {new Date(date).toDateString()}
                  </Text>
                </View>
              </Text>
            </View>

            {/* ******************************************************************************* */}
            {/* Account Title & Type */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {/* Account Title */}
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingLeft: 3,
                }}
              >
                <View>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                    }}
                  >
                    {"Account Title: "}
                  </Text>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                      textAlign: "right",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {"\n"+accountTitle}
                  </Text>
                </View>
              </Text>

              {/* Account Type*/}
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  // paddingBottom: 7,
                  // paddingTop: 3,
                  paddingRight: 3,
                  textAlign: "right",
                }}
              >
                <View>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                      textAlign: "right",
                    }}
                  >
                    {"Account Type: "}
                  </Text>
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      // paddingBottom: 7,
                      paddingTop: 3,
                      paddingRight: 3,
                      textAlign: "right",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {"\n"+paymentMethod}
                  </Text>
                </View>
              </Text>
            </View>

            {/* ******************************************************************************* */}
            {/* Cash Account */}
            {paymentMethod === "CASH" ? (
              <View>
                <View
                  style={{
                    // textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {/* Cash Account */}
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      paddingBottom: 7,
                      paddingLeft: 3,
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          width: "50%",
                          color: "black",
                          fontSize: 5,
                          // paddingBottom: 7,
                          paddingTop: 3,
                          paddingRight: 3,
                        }}
                      >
                        {"Cash Account: "}
                      </Text>
                      <Text
                        style={{
                          width: "50%",
                          color: "black",
                          fontSize: 5,
                          // paddingBottom: 7,
                          paddingTop: 3,
                          paddingRight: 3,
                          textAlign: "right",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {"\n"+paymentTitle}
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
            ) : (
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      paddingBottom: 7,
                      paddingLeft: 3,
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          width: "50%",
                          color: "black",
                          fontSize: 5,
                          // paddingBottom: 7,
                          paddingTop: 3,
                          paddingRight: 3,
                        }}
                      >
                        {"Bank Account: "}
                      </Text>
                      <Text
                        style={{
                          width: "50%",
                          color: "black",
                          fontSize: 5,
                          // paddingBottom: 7,
                          paddingTop: 3,
                          paddingRight: 3,
                          textAlign: "right",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {"\n"+paymentTitle}
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
            )}
            {paymentMethod == "BANK" && (
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {/* Cheque No */}
                  <Text
                    style={{
                      width: "50%",
                      color: "black",
                      fontSize: 5,
                      paddingBottom: 7,
                      paddingLeft: 3,
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          width: "50%",
                          color: "black",
                          fontSize: 5,
                          // paddingBottom: 7,
                          paddingTop: 3,
                          paddingRight: 3,
                        }}
                      >
                        {"Cheque No: "}
                      </Text>
                      <Text
                        style={{
                          width: "50%",
                          color: "black",
                          fontSize: 5,
                          // paddingBottom: 7,
                          paddingTop: 3,
                          paddingRight: 3,
                          textAlign: "right",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {chequeNo}
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
            )}
          </View>
          {/* ******************************************************************************* */}
          {/* Advance Tax */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                borderColor: "black",
                borderWidth: 1,
                borderBottom: "none",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Advance Tax"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {"Rs " + separator(parseInt(advanceTax)) + "/-"}
              </Text>
            </View>
          </View>

          {/* ******************************************************************************* */}
          {/* Amount */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginBottom: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                borderColor: "black",
                borderWidth: 1,
              }}
            >
              <Text
                style={{
                  width: "50%",
                  // borderColor: "black",
                  // borderWidth: 1,
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Amount"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  // borderColor: "black",
                  // borderWidth: 0.25,
                  // fontFamily: "Helvetica-Bold",
                  // backgroundColor: "#eeeeee",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {" "}
                {"Rs " + separator(totalAmount) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* fbr logo */}
          {/* <View style={styles.FBR_logo}>
            <Image style={{ width: "100%", textAlign: "center" }} src={FBR} />
          </View> */}

          {/* ******************************************************************************* */}
          {/* Remarks */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                width: "100%",
                color: "black",
                fontSize: 5,
                paddingTop: 3,
                paddingLeft: 3,
                fontFamily: "Helvetica-Bold",
              }}
            >
              Remarks:{" "}
            </Text>
          </View>

          {remarks != "" ? (
            <View
              style={{
                textAlign: "left",
                width: "100%",
                marginTop: 5,
                // paddingLeft: 40,
                // paddingRight: 30,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  width: "100%",
                  borderWidth: 0.5,
                  backgroundColor: "#eeeeee",
                  padding: 5,
                  // fontSize: 10,
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 3,
                }}
              >
                {remarks}
              </Text>
            </View>
          ) : (
            <Text
            style={{
              textAlign: "left",
              width: "100%",
              borderWidth: 0.5,
              backgroundColor: "#eeeeee",
              padding: 5,
              // fontSize: 10,
              color: "black",
              marginTop: 5,
              fontSize: 5,
              paddingBottom: 7,
              paddingTop: 3,
              paddingLeft: 3,
            }}
          >
            {"No Remarks"}
          </Text>
          )}

          {/* ******************************************************************************* */}
          {/* Thank You Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // backgroundColor:"yellow",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "100%",
                  fontSize: 6,
                  fontFamily: "Helvetica-Bold",
                  textAlign: "center",
                  color: "#333333",
                }}
              >
                Thank you for choosing Wise Vision!
              </Text>
            </View>
          </View>

          {/* ******************************************************************************* */}
          {/* Software designed by: */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              bottom: 0,
              position: "relative",
              // marginTop: 10,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            fixed
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <Text
                style={{
                  textAlign: "center",
                  fontSize: 6,
                  marginTop: 10,
                }}
              >
                {"Software designed by: aiodock.com"}
              </Text> */}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;
