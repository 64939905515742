import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import aiodock from "../wise vision.png";
import FBR from "../fbr-pakistan.png";

// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 5,
  },
  logo: {
    width: 180,
    height: 80,
  },
  FBR_logo: {
    width: 60,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // margin: 0
  },
  section: {
    // margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tableHeader: {
    fontSize: 5,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    padding: 5,
    // backgroundColor: "#EEEEEE",
    marginTop: 7,
    fontFamily: "Helvetica-Bold",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableRow: {
    fontSize: 5,
    display: "flex",
    flexDirection: "row",
    padding: 5,
    borderBottom: "1px dashed #e0e0e0",
  },
  tableRowOdd: {
    backgroundColor: "#DDDDDD",
  },
  tableTotal: {
    fontSize: 12,
    fontWeight: "bold",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEEE",
    marginTop: 10,
  },
});

const separator = (numb) => {
  var str = (numb + "").split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

const InvoicePdf = ({
  clientName,
  invoiceNo,
  products,
  client,
  discountTotal,
  date,
  gstTotal,
  netBillTotal,
  payable,
  address
}) => {
  // console.log("Address", address);
  // console.log("Client Name", clientName);
  return (
    <Document>
      <Page size={{ width: "415px" }} style={styles.page}>
        <View style={styles.section}>
          {/* ****************************************************************************************** */}
          {/* Company Logo  */}
          <View style={styles.logo}>
            <Image
              style={{ fontSize: 12, textAlign: "center", width: "100%" }}
              src={aiodock}
            />
          </View>

          {/* ****************************************************************************************** */}
          {/* Company Information */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              bottom: 0,
              position: "relative",
              paddingLeft: 6,
              paddingRight: 6,
              marginTop: 5,
            }}
            fixed
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",

                textAlign: "center",
              }}
            >
              <Text
                style={{
                  // fontFamily: "Helvetica-Bold",
                  // textAlign: "center",
                  marginTop: 1,
                  fontSize: 6,
                }}
              >
                {
                  "Address: Office No. 14, Ground Floor, Munawar Centre, Mall Road, Lahore"
                }
              </Text>
              {/* Phone Number */}
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 6,
                }}
              >
                Ph: 0333-1431402
              </Text>
              {/* STRN */}
              {/* <Text
                style={{
                  marginTop: 5,
                  fontSize: 6,
                }}
              >
                STRN # 0000
              </Text> */}
              {/* NTN */}
              {/* <Text
                style={{
                  marginTop: 5,
                  fontSize: 6,
                }}
              >
                NTN # 0000
              </Text> */}
            </View>
          </View>
          {/* ****************************************************************************************** */}

          <View style={{ border: "1px solid black", marginTop: 10 }}>
            {/* ****************************************************************************************** */}
            {/* Date & Invoice Number */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 3,
                }}
              >
                {"Purchase From:"}
                {clientName}
              </Text>
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingRight: 3,
                  textAlign: "right",
                }}
              >
                {"Invoice No.:"}
                {invoiceNo}
              </Text>
            </View>

            {/* ******************************************************************************* */}
            {/* Client Address & Date */}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  // paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 3,
                }}
              >
                {"Address:"}
                {address}
              </Text>
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  // paddingBottom: 7,
                  paddingTop: 3,
                  paddingRight: 3,
                  textAlign: "right",
                }}
              >
                {"Date:"}
                {new Date(date).toDateString()}
              </Text>
            </View>

            {/* ******************************************************************************* */}
            {/* Table */}
            <View style={styles.tableHeader}>
              <Text style={{ width: "20%" }}>Qty</Text>
              <Text style={{ width: "20%" }}>Price</Text>
              <Text style={{ width: "20%" }}>GST</Text>
              <Text style={{ width: "20%" }}>Disc</Text>
              <Text style={{ width: "20%" }}>Amount</Text>
            </View>

            {products.map((product, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={{ width: "100%" }}>
                  <Text style={{ fontSize: 6 }}>{product.name}</Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 5,
                    }}
                  >
                    <Text style={{ width: "20%", fontSize: 6 }}>
                      {product.quantity}
                    </Text>
                    <Text style={{ width: "20%", fontSize: 6 }}>
                      {separator(parseInt(product.rate))}
                    </Text>

                    <Text style={{ width: "20%", fontSize: 6 }}>
                      {separator(
                        parseInt(
                          (product.rate * product.quantity * product.gst) / 100
                        )
                      )}
                    </Text>
                    <Text style={{ width: "20%", fontSize: 6 }}>
                      {separator(product.discount)}
                    </Text>
                    <Text style={{ width: "20%", fontSize: 6 }}>
                      {separator(parseInt(product.amount))}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
          {/* ******************************************************************************* */}
          {/* Net Amount Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                borderColor: "black",
                borderWidth: 1,
                borderBottom: "none",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total Amount"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {separator(parseInt(netBillTotal)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Net GST Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                borderColor: "black",
                borderWidth: 1,
                borderBottom: "none",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total GST"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {separator(parseInt(gstTotal)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Net Discount Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                borderColor: "black",
                borderWidth: 1,
                borderBottom: "none",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total Discount"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {separator(parseInt(discountTotal)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Net Payable Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginBottom: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                borderColor: "black",
                borderWidth: 1,
              }}
            >
              <Text
                style={{
                  width: "50%",
                  // borderColor: "black",
                  // borderWidth: 1,
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total Payable"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  // borderColor: "black",
                  // borderWidth: 0.25,
                  // fontFamily: "Helvetica-Bold",
                  // backgroundColor: "#eeeeee",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {" "}
                {separator(parseInt(payable)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* fbr logo */}
          {/* <View style={styles.FBR_logo}>
            <Image style={{ width: "100%", textAlign: "center" }} src={FBR} />
          </View> */}

          {/* ******************************************************************************* */}
          {/* Thank You Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // backgroundColor:"yellow",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "100%",
                  fontSize: 6,
                  fontFamily: "Helvetica-Bold",
                  textAlign: "center",
                  color: "#333333",
                }}
              >
                Thank you for choosing Wise Vision!
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              bottom: 0,
              position: "relative",
              // marginTop: 10,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            fixed
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* <Text
                style={{
                  // fontFamily: "Helvetica-Bold",
                  textAlign: "center",
                  marginTop: 1,
                  fontSize: 6,
                }}
              >
                {
                  "Address: Office No. 317, Hamdan Heights, Islamabad Expy, Phase 5 Ghauri Town, Islamabad."
                }
              </Text> */}
              {/* <Text
                style={{
                  textAlign: "center",
                  fontSize: 6,
                  marginTop: 10,
                }}
              >
                {"Software designed by: aiodock.com"}
              </Text> */}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;
