import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PreviewIcon from "@mui/icons-material/Preview";
import AddIcon from "@mui/icons-material/Add";

import { Typography } from "@mui/material";
// Alert Thing
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import url from "../../serverURL.js";
import CreateItem from "./addClientComponent.js";
import MaterialTable from "material-table";
import EditItem from "./edit";
import Tooltip from "@material-ui/core/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};

export default function AddItem() {
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState({});
  const [category, setCategories] = useState([]);

  const [loading, setLoading] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  //Edit Hooks
  const [editFlag, setEditFlag] = React.useState(false);
  const [editData, setEditData] = React.useState(false);

  // After Update
  const [editLoading, setEditLoading] = React.useState(false);
  //
  const [alertText, setAlertText] = React.useState("Client Added Successfully");
  const [errorText, setErrorText] = React.useState("Invalid Details");

  const [modalOpen, setModalOpen] = useState(false);

  // Function to open modal
  const handleOpenModal = () => {
    console.log("Open Modal function calls");
    if (!modalOpen) {
      setModalOpen(true);
    }
  };

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClick = (data) => {
    if (data != undefined && data != null) {
      setAlertText(data);
    }
    setOpen(true);
    Reload();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClickError = (errorText) => {
    setErrorText(errorText);
    setError(true);
  };
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };

  const deleteClient = (id) => {
    var result = window.confirm("Are you sure you want to delete this Client?");
    if (result) {
      fetch(url + "inventry/client", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      }).then((res) => {
        if (res.status == 400) {
          handleClickError();

          return;
        }
        handleClick("Sucessfully Deleted");
      });
    }
  };

  const submit = () => {
    /* eslint-disable */
    if (
      form.name === undefined ||
      form.type === undefined ||
      form.type === "select" ||
      form.name === "" ||
      form.name.trim() == ""
    ) {
      handleClickError();
      return;
    }
    setEditLoading(true);
    var data = {
      name: form.name == undefined ? "" : form.name,
      mobile: form.mobile == undefined ? "" : form.mobile,
      type: form.type == undefined ? "" : form.type,
      email: form.email == undefined ? "" : form.email,
      ntn: form.ntn == undefined ? "" : form.ntn,
      stn: form.stn == undefined ? "" : form.stn,
      address: form.address == undefined ? "" : form.address,
      debit: form.debit == undefined ? "" : form.debit,
      credit: form.credit == undefined ? "" : form.credit,
      userId: localStorage.getItem("id"),
    };
    fetch(url + "inventry/client", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        setEditLoading(false);
        handleClickError();

        return;
      }
      setEditLoading(false);

      setForm({});
      handleClick();
    });
  };

  useEffect(() => {
    setLoading(true);
    fetch(url + "inventry/client", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((clients) => {
        const box = clients.filter((data) => ({
          name: data.name,
          id: data.id,
          type: data.type,
        }));
        setRows(box);
        setLoading(false);
      });
  }, []);

  const Reload = () => {
    setLoading(true);

    fetch(url + "inventry/client", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((clients) => {
        const box = clients.filter((data) => ({
          name: data.name,
          id: data.id,
          type: data.type,
        }));
        setRows(box);
        setLoading(false);
      });
  };
  const editClose = () => {
    setEditFlag(false);
  };

  const truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          {modalOpen && (
            <CreateItem
              handleClickError={handleClickError}
              handleClick={handleClick}
              handleCloseModal={handleCloseModal}
              isOpen={modalOpen}
            />
          )}
        </div>
        {editFlag ? (
          <EditItem
            editData={editData}
            editClose={editClose}
            handleClickError={handleClickError}
            handleClick={handleClick}
          />
        ) : null}
        {!editLoading ? (
          <div>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {alertText}
              </Alert>
            </Snackbar>
            <Snackbar
              open={error}
              autoHideDuration={6000}
              onClose={handleCloseError}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={handleCloseError}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorText}
              </Alert>
            </Snackbar>
            <div style={{ padding: 10 }}>
              {/* <Button variant="contained" to="#" onClick={submit}> */}
              <Button
                variant="contained"
                to="#"
                startIcon={<AddIcon />}
                onClick={handleOpenModal}
              >
                Add Client
              </Button>
            </div>
          </div>
        ) : (
          <CircularProgress />
        )}

        {/* {loading ? (
          <CircularProgress />
        ) : ( */}
        <MaterialTable
          icons={tableIcons}
          title="Clients"
          columns={[
            {
              title: "Client ID",
              field: "id",
            },
            {
              title: "Client Title",
              field: "name",
              render: (rowData) => {
                const truncatedName = truncateText(rowData.name, 18);
                return (
                  <Tooltip
                    title={
                      <Typography fontSize="1.5em">{rowData.name}</Typography>
                    }
                  >
                    <span>{truncatedName}</span>
                  </Tooltip>
                );
              },
            },
            {
              title: "Client Type",
              field: "type",
            },
          ]}
          options={{
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
              fontSize: "0.8em",
            },
            actionsColumnIndex: -1,
            rowStyle: (rowData, index) => ({
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
              fontSize: "0.8em",
            }),
            paging: true,
            pageSize: 10,
            pageSizeOptions: false,
            showFirstLastPageButtons: false,
          }}
          data={rows}
          isLoading={loading}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setEditData(rowData);
                setEditFlag(true);
              },
            },

            {
              icon: () => <Delete />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteClient(rowData.id);
              },
            },
          ]}
        />
        {/* )} */}
      </Box>
    </>
  );
}
