import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Document, Page, View, Image, Text, Link, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// @mui
import { Box, Stack, Container, Typography, Modal, Grid } from '@mui/material';
import logo from './logo.png';
// components

export default function LeaveCertificate(props) {
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const [openPDFModal, setOpenPDFModal] = useState(true);
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });

  const styles = StyleSheet.create({
    title: {
      margin: 20,
      textAlign: 'center',
      backgroundColor: '#e4e4e4',
      textTransform: 'uppercase',
      fontFamily: 'Oswald',
    },
    header: {
      textAlign: 'center',
      marginBottom: 20,
      color: '#374E5C',
      width: '30%',
    },
    leave: {
      marginTop: 10,
      backgroundColor: '#374E5C',
      color: 'white',
      fontSize: 25,
      textAlign: 'center',
    },
    body: {
      margin: 10,
      flexGrow: 1,
    },
    text: {
      marginBottom: '15px',
      marginLeft: '40px',
    },
    row: {
      flexGrow: 1,
      flexDirection: 'row',
    },
  });

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });
  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  console.log('DATA', props.data);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openPDFModal} onClose={props.onClose}>
              <Box sx={style} height="80%">
                <Typography sx={{ textAlign: 'center' }} id="modal-modal-title" variant="h3" component="h2">
                  Employee Info
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {/* <form> */}
                  <PDFViewer width="100%" height="100%" showToolbar style={{ width: '100%', height: 400 }}>
                    <Document>
                      <Page size="A4">
                        <View style={styles.header} fixed>
                          <Image src={logo} style={{ height: '100px', width: '400px' }} />
                        </View>

                        <Text style={styles.leave}> LEAVE APPLICATION FORM </Text>
                        <View style={style.body}>
                          <Text style={styles.title}> Personal Details</Text>
                          <Text style={styles.text}> Name: {props.data.name}</Text>
                          <Text style={styles.text}> Designation: {props.data.designation}</Text>
                          <Text style={styles.text}> Department: {props.data.company}</Text>
                        </View>

                        <View style={styles.body}>
                          <Text style={styles.title}>Leave Details</Text>
                            <Text style={styles.text}> Leave From: 06/02/2022 </Text>
                            <Text style={styles.text}> Leave To: 13/02/2022</Text>
                            <Text style={styles.text}> No. of Days: {props.data.days}</Text>
                            <Text style={styles.text}> Description: </Text>
                            <Text style={styles.text}> ______________________________________________</Text>
                            <Text style={styles.text}> ______________________________________________</Text>
                        </View>

                        <View style={styles.body}>
                          <Text style={styles.title}>
                            For Office Use 
                          </Text>
                          <Text style={styles.text}> Your application is {props.data.leaves}.</Text>
                          <Text style={[styles.text, {textAlign:'right'}]}> Name: HR Manager</Text>
                          <Text style={[styles.text, {textAlign:'right'}]}> Signature:___________</Text>
                        </View>
                      </Page>
                    </Document>
                  </PDFViewer>
                  {/* </form> */}
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
