import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// @mui

import {
  Box,
  FormHelperText,
  Stack,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
} from '@mui/material';
// components

import Iconify from '../../../components/iconify/Iconify';

export default function CreateEmployee(props) {
  // date n timne
  const [joining, setJoining] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [attendanceFrom, setAttendanceFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [attendanceTo, setAttendanceTo] = React.useState(dayjs('2014-08-18T21:11:54'));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [formData, setFormData] = useState({
    Joining: '',
    Name: '',
    Branch: '',
    CNIC: '',
    FatherName: '',
    Address: '',
    Phone: '',
    Designation: '',
    Department: '',
    BasicSalary: '',
    HRM: '',
    Conveyance: '',
    Medical: '',
    GrossSalary: '',
    OverTime: '',
    ProvidentFund: '',
    AnnualLeavesAllowed: '',
    Gender: '',
    MartialStatus: '',
    image: '',
    imageExtension: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Encode selected image in base64
    let base64Image = '';
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        base64Image = reader.result.split(',')[1];

        // Add base64-encoded image to form data
        setFormData((formData) => ({
          ...formData,
          Joining: joining.toISOString(),
          image: base64Image,
          imageExtension: Extension,
        }));
        // console.log('Base 64 Image:', base64Image);
        // Submit form data to server
        fetch('http://192.168.18.135:3000/add', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.error(error));
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  console.log('You form has been submitted.', formData);
  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
      Joining: joining.toISOString(),
    }));
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [Extension, setExtension] = useState('');

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    setExtension(event.target.files[0].name.split('.').pop());
  };
 
  console.log('Image Extension is', Extension);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openModal} onClose={props.onClose}>
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  New Employee
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <form onSubmit={handleSubmit}>
                    {/* <form> */}
                    <Grid lg={12} item container spacing={2}>
                      <Grid item xs={12} lg={4}>
                        <MobileDatePicker
                          id="stabdard-date-input"
                          label="Joined"
                          inputFormat="MM/DD/YYYY"
                          value={joining}
                          renderInput={(params) => <TextField {...params} variant="standard" />}
                          onChange={(newValue) => {
                            setJoining(newValue);
                          }}
                        />
                        <br />
                        <TextField
                          id="stabdard-branch-input"
                          label="Branch"
                          name="Branch"
                          value={formData.Branch}
                          // type="text"
                          autoComplete="current-text"
                          variant="standard"
                          onChange={handleChangeData}
                        />{' '}
                        <br />
                        <TextField
                          id="stabdard-name-input"
                          label="Name"
                          name="Name"
                          type="text"
                          value={formData.Name}
                          autoComplete="current-text"
                          variant="standard"
                          onChange={handleChangeData}
                        />{' '}
                        <br />
                        <TextField
                          id="standard-cnic-input"
                          label="CNIC"
                          name="CNIC"
                          value={formData.CNIC}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-password"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          id="standard-father-input"
                          label="Father Name"
                          name="FatherName"
                          value={formData.FatherName}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-father"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          style={{ width: '80%' }}
                          id="standard-add-input"
                          label="Address"
                          name="Address"
                          value={formData.Address}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-address"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          id="standard-phone-input"
                          label="Phone"
                          name="Phone"
                          value={formData.Phone}
                          onChange={handleChangeData}
                          type="number"
                          autoComplete="current-phone"
                          variant="standard"
                        />
                        <br />
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value="female"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Female"
                              name="Gender"
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="Male"
                              name="Gender"
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Male"
                            />
                          </RadioGroup>
                        </FormControl>{' '}
                        <br />
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">Martial Status</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value="married"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Married"
                              name="MartialStatus"
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Married"
                            />
                            <FormControlLabel
                              value="Unmarried"
                              name="MartialStatus"
                              onChange={handleChangeData}
                              control={<Radio />}
                              label="Unmarried"
                            />
                          </RadioGroup>
                        </FormControl>{' '}
                        <br />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="standard-designation-input"
                          label="Designation"
                          name="Designation"
                          value={formData.Designation}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-designation"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          id="standard-department-input"
                          label="Department"
                          type="text"
                          name="Department"
                          value={formData.Department}
                          onChange={handleChangeData}
                          autoComplete="current-department"
                          variant="standard"
                        />
                        <br />
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Attendance Timing
                        </Typography>{' '}
                        <MobileTimePicker
                          value={attendanceFrom}
                          onChange={(newValue) => {
                            setAttendanceFrom(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" sx={{ width: 100 }} {...params} />}
                        />{' '}
                        &nbsp; <b>TO</b> &nbsp;
                        <MobileTimePicker
                          value={attendanceTo}
                          onChange={(newValue) => {
                            setAttendanceTo(newValue);
                          }}
                          renderInput={(params) => <TextField size="small" sx={{ width: 100 }} {...params} />}
                        />{' '}
                        <br />
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Salary &nbsp;
                          <sub>( Package Structure )</sub>
                        </Typography>{' '}
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Basic</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            type="number"
                            name="BasicSalary"
                            value={formData.BasicSalary}
                            onChange={handleChangeData}
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">H.R.M</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            type="number"
                            name="HRM"
                            value={formData.HRM}
                            onChange={handleChangeData}
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Conveyance</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            type="number"
                            name="Conveyace"
                            value={formData.Conveyance}
                            onChange={handleChangeData}
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Medical</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            name="Medical"
                            type="number"
                            value={formData.Medical}
                            onChange={handleChangeData}
                          />
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Gross Salary</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            label="Amount"
                            size="small"
                            type="number"
                            name="GrossSalary"
                            value={formData.GrossSalary}
                            onChange={handleChangeData}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Box mt={2} mb={2} height={120} width={120} border={2} textAlign="center">
                          {imageUrl && selectedImage && <img src={imageUrl} alt={selectedImage.name} height="100px" />}
                        </Box>
                        <input
                          accept="image/"
                          type="file"
                          id="select-image"
                          style={{ display: 'none' }}
                          onChange={handleImageChange}
                        />
                        <label htmlFor="select-image">
                          <Button size="small" color="primary" component="span">
                            Upload Image
                          </Button>{' '}
                        </label>
                        <br />
                        <br />
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Overtime</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            aria-describedby="standard-amount-helper-text"
                            inputProps={{
                              'aria-label': 'amount',
                            }}
                            label="Amount"
                            size="small"
                            type="number"
                            name="OverTime"
                            value={formData.OverTime}
                            onChange={handleChangeData}
                          />
                          <FormHelperText id="standard-weight-helper-text">Rate per Hour</FormHelperText>
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Provident Fund</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Rs.</InputAdornment>}
                            aria-describedby="standard-amount-helper-text"
                            inputProps={{
                              'aria-label': 'amount',
                            }}
                            label="Amount"
                            size="small"
                            name="ProvidentFund"
                            type="number"
                            value={formData.ProvidentFund}
                            onChange={handleChangeData}
                          />
                          <FormHelperText id="standard-weight-helper-text">Monthly Deductable</FormHelperText>
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">No. of Leaves</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">Leaves #</InputAdornment>}
                            aria-describedby="standard-amount-helper-text"
                            inputProps={{
                              'aria-label': 'amount',
                            }}
                            label="Amount"
                            size="small"
                            name="AnnualLeavesAllowed"
                            type="number"
                            value={formData.AnnualLeavesAllowed}
                            onChange={handleChangeData}
                          />
                          <FormHelperText id="standard-weight-helper-text">Anually Allowed</FormHelperText>
                        </FormControl>
                        <br />
                        <Button type="submit" variant="contained" color="primary">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
