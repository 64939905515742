import React, {
  useState,
  useEffect,
  useRef,
  Component,
  forwardRef,
} from "react";
import "whatwg-fetch";
import MaterialTable from "material-table";
import { Card, CardHeader, CardBody } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import url from "src/serverURL.js";
import { Grid, Typography } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
class stockManagement extends Component {
  constructor() {
    super();
    this.state = {
      categories: {},
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date("1/1/2023"),
      date_to: new Date(),
      final_flag: "",
      total_balance: 0,
      cash: true,
      bank: false,
      load: false,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      submit: true,

      totalWorth: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  fetchData = () => {
    const data = {
      from: this.state.date_from,
      to: this.state.date_to,
    };

    // Make the API call
    fetch(url + "inventry/reporting/stockManagement", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((transactions) => {
        console.log("Transactions are:", transactions);
        if (!transactions || !transactions.Stock) {
          this.setState({ submit: false, totalWorth: 0, rows: [] });
          return;
        }
        let worth = 0;
        const result = Object.keys(transactions.Stock).map((key) => {
          const balance =
            transactions.Stock[key].opening +
            transactions.Stock[key].purchase +
            transactions.Stock[key].sales_return -
            (transactions.Stock[key].sale +
              transactions.Stock[key].purchase_return);

          worth += parseFloat(transactions.Stock[key].rate) * balance;

          return {
            ...transactions.Stock[key],
            balance: balance,
            rate: parseFloat(transactions.Stock[key].rate).toFixed(2),
            worth: (parseFloat(transactions.Stock[key].rate) * balance).toFixed(
              2
            ),
          };
        });

        this.setState({ submit: false, totalWorth: worth, rows: result });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.fetchData();
    fetch(url + "inventry/item/categories")
      .then((res) => res.json())
      .then((categories) => {
        this.setState({ categories });
      });
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, 2000);
  }
  onSubmit(e) {
    this.setState({ submit: true });
    // Validate date range
    const fromDate = new Date(this.state.date_from);
    const toDate = new Date(this.state.date_to);

    if (fromDate > toDate) {
      // Display an alert or handle the validation error accordingly
      alert("Date To cannot be earlier than Date From");
      return;
    }

    e.preventDefault();
    this.fetchData();
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };

  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };
  render() {
    const { rows } = this.state;
    const dataWithCategories = rows.map((row) => ({
      ...row,
      category: this.state.categories[row.name] || "---",
    }));

    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <Grid container>
        <Grid md={12}>
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <form onSubmit={this.onSubmit} className="Notifications">
                <Grid
                  container
                  spacing={2}
                  controlId="exampleForm.ControlSelect2"
                >
                  <Grid item sm={7}>
                    <br />
                    <h1 style={{ fontSize: "1.5em" }}>Stock Management</h1>
                  </Grid>
                  <Grid item sm={2}>
                    <b>From :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_from}
                        onChange={(date_from) => {
                          this.setState({ date_from: date_from });
                        }}
                        maxDate={this.state.date_to}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={2}>
                    <b>To :</b>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={this.state.date_to}
                        onChange={(date_to) => {
                          this.setState({ date_to: date_to });
                        }}
                        minDate={this.state.date_from}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={1}>
                    <br />
                    <Button variant="contained" size="md" block type="submit">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <Grid
                container
                style={{ marginTop: "1em" }}
                controlId="formHorizontalEmail"
              >
                <Grid md={12}>
                  <TableContainer component={Paper}>
                    <MaterialTable
                      title="Stock Details"
                      columns={[
                        {
                          title: "Category",
                          field: "category",
                          render: (rowData) => {
                            const truncatedCategory = truncateText(
                              rowData.category,
                              18
                            );
                            return (
                              <Tooltip
                                title={
                                  <Typography fontSize="1.5em">
                                    {rowData.category}
                                  </Typography>
                                }
                              >
                                <span>{truncatedCategory}</span>
                              </Tooltip>
                            );
                          },
                        },
                        { title: "Code", field: "code" },
                        // { title: "Category", field: "category" },
                        {
                          title: "Item Title",
                          field: "name",
                          render: (rowData) => {
                            const truncatedName = truncateText(
                              rowData.name,
                              18
                            );
                            return (
                              <Tooltip
                                title={
                                  <Typography fontSize="1.5em">
                                    {rowData.name}
                                  </Typography>
                                }
                              >
                                <span>{truncatedName}</span>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Opening",
                          field: "opening",
                          type: "numeric",
                          render: (rowData) => {
                            if (!isNaN(parseFloat(rowData.opening))) {
                              return parseFloat(rowData.opening).toFixed(2);
                            } else {
                              return "---";
                            }
                          },
                        },
                        {
                          title: "Purchase",
                          field: "purchase",
                          type: "numeric",
                        },
                        {
                          title: "Purchase Return",
                          field: "purchase_return",
                          type: "numeric",
                        },
                        { title: "Sale", field: "sale", type: "numeric" },
                        {
                          title: "Sales Return",
                          field: "sales_return",
                          type: "numeric",
                        },
                        {
                          title: "Balance",
                          field: "balance",
                          type: "numeric",
                        },
                        {
                          title: "Unit Cost",
                          field: "rate",
                          type: "numeric",
                        },
                        {
                          title: "Worth",
                          field: "worth",
                          type: "numeric",
                        },
                        // {
                        //   title: "Balance",
                        //   field: "balance",
                        //   type: "numeric",
                        // },
                        // { title: "Worth", field: "worth", type: "numeric" },
                      ]}
                      data={dataWithCategories}
                      isLoading={this.state.submit}
                      options={{
                        headerStyle: {
                          backgroundColor: "#01579b",
                          color: "#FFF",
                          whiteSpace: "nowrap",
                          fontSize: "0.8em",
                        },

                        rowStyle: (rowData, index) => ({
                          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#FFF",
                          height: "5em",
                          fontSize: "0.8em",
                        }),
                        search: true,
                        exportButton: true,
                        exportAllData: true,
                        showTitle: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: false,
                        showFirstLastPageButtons: false,
                        sorting: true,
                      }}
                      icons={tableIcons}
                    />
                  </TableContainer>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginTop: "1em" }}
                controlId="exampleForm.ControlSelect2"
              >
                <Grid sm={10}></Grid>

                <Grid sm={2}>
                  <b class="Total Worth">Total Worth</b>
                  <TextField
                    fullWidth
                    id="filled-basic"
                    variant="filled"
                    type="text"
                    value={this.numberWithCommas(
                      this.state.totalWorth.toFixed(2)
                    )}
                    style={{
                      fontWeight: "bold",
                      backgroundColor:
                        this.state.totalWorth < 0 ? "red" : "inherit",
                      color: this.state.totalWorth < 0 ? "#fff" : "inherit",
                    }}
                    required
                    placeholder="Total Worth..."
                  />
                </Grid>
              </Grid>

              <Modal
                open={this.state.add}
                onClose={() => this.setState({ add: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {this.state.title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {this.state.body}
                  </Typography>
                </Box>
              </Modal>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default stockManagement;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward style={{ color: "#fff", opacity: 1 }} {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
