import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";
import { MDBDataTable } from "mdbreact";
import { Card, CardHeader, CardBody } from "reactstrap";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Modal from "@mui/material/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Grid, Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// ServerURL
import url from "../../serverURL.js";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
class Payment extends Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Title",
          field: "name",
          sort: "asc",
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
        },
      ],
      rows: [],
      id: 0,
      options: [],
      name: "",
      all_accounts: {},
      mobile: 0,
      cheque_date: new Date(),
      wht_tax: 0,
      email: "",
      _typeahead: null,
      cash_accounts: [],
      cash_data: null,
      bank_data: null,
      ac_title_data: null,
      cheque_no: "",
      bank_accounts: [],
      all_payment: [],
      cash: true,
      bank: false,
      ntn: 0,
      stn: 0,
      address: "",
      type: "customer",
      amount: 0,
      debit: 0,
      credit: 0,
      paymentNo: "111",
      remarks: "",
      original_record: [],
      category: "",
      categories: [],
      add: false,
      title: "",
      body: "",
      date: new Date(),
      open: true,
      scroll: "paper",
      extension: "",
      file: "",
      purchase_invoiceno: "",
      purchases: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.Reload = this.Reload.bind(this);
  }

  handlePicture = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size exceeds 5 MB. Please choose a smaller file.");
      event.target.value = "";
      return;
    }

    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      const filePath = fileInput.value;
      const fileArr = filePath.split(".");
      const ext = fileArr[fileArr.length - 1];

      const base64 = await this.convertBase64(file);
      this.setState({
        extension: ext,
        file: base64,
      });
    } else {
      console.error("Element with ID 'file-upload' not found");
    }
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////
  handleClickOpen = (scrollType) => () => {
    this.setState({
      open: true,
      scroll: scrollType,
    });
  };
  handleClose = () => {
    // this.setState({
    //   open: false,
    // });
    this.props.createClose();
    this.props.Reload();
  };
  descriptionElementRef = React.createRef();
  componentDidMount() {
    if (this.state.open) {
      const { current: descriptionElement } = this.descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////

  Reload() {}
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  handleChange_Cheque = (date) => {
    this.setState({
      cheque_date: date,
    });
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentWillMount() {
    fetch(url + "inventry/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((accounts) => {
        console.log(accounts);
        var cash = [];
        var bank = [];
        var pay = [];
        accounts.map((acc) => {
          if (acc.type == "CASH") {
            cash.push(acc.title);
          }
          if (acc.type == "BANK") {
            bank.push(acc.title);
          }
          pay[acc.title] = {
            id: acc.id,
            title: acc.title,
            type: acc.type,
          };
        });
        this.setState({ all_payment: pay });
        this.setState({ cash_accounts: cash });
        this.setState({ bank_accounts: bank });
      });

    fetch(url + "inventry/payment/PaymentNo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((counter) => {
        this.setState({ paymentNo: counter.PaymentNo });
      });

    fetch(url + "inventry/jv/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        var cli = [];
        var accounts = {};
        client.map((acc) => {
          if (acc.type == "vendor") {
            cli.push(acc.name);
            var obj = {
              title: acc.name,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.name] = obj;
          } else if (acc.type == "customer") {
            return;
          } else {
            if (
              ((acc.id + "").includes("O-1") &&
                (acc.id + "").split("-").length == 3) ||
              acc.type == "Expenses" ||
              acc.type == "Long Term Liabilities" ||
              acc.type == "Current Liabilities" ||
              acc.type == "Revenue" ||
              acc.type == "Assets" ||
              acc.title == "Cash Sales" ||
              acc.title == "Credit Sales" ||
              acc.title == "Cost of Sales" ||
              acc.title == "Inventory"
            ) {
              return;
            }
            cli.push(acc.title);
            var obj = {
              title: acc.title,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.title] = obj;
          }
        });
        console.log(accounts);
        this.setState({ all_accounts: accounts });
        this.setState({ options: cli });
      });
  }
  componentWillReceiveProps() {
    this.Reload();
  }
  onSubmit(e) {
    e.preventDefault();

    if (
      this.state.amount <= 0 ||
      this.state.amount == undefined ||
      this.state.amount == null ||
      this.state.amount == ""
    ) {
      this.setState({ title: "Amount" });
      this.setState({ body: "Amount is Invalid !" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.ac_title_data == undefined ||
      this.state.ac_title_data == null
    ) {
      this.setState({ title: "A/C Title" });
      this.setState({ body: "A/C Title is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.purchase_invoiceno == "" ||
      this.state.amount == undefined ||
      this.state.amount == null ||
      this.state.amount == ""
    ) {
      this.setState({ title: "Invoice No." });
      this.setState({ body: "Select Purchase Invoice !" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.cash &&
      (this.state.cash_data == undefined || this.state.cash_data == null)
    ) {
      this.setState({ title: "Cash" });
      this.setState({ body: "Cash Account is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.bank &&
      (this.state.bank_data == undefined || this.state.bank_data == null)
    ) {
      this.setState({ title: "Bank" });
      this.setState({ body: "Bank Account is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.bank) {
      if (this.state.cheque_no == "") {
        this.setState({ title: "Cheque" });
        this.setState({ body: "Cheque No is Invalid!" });
        this.setState({ add: true });
        return;
      }
    }
    if (this.state.date == null) {
      this.setState({ title: "Date" });
      this.setState({ body: "Date is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.cheque_date == null) {
      this.setState({ title: "Cheque Date" });
      this.setState({ body: "Cheque Date is Invalid!" });
      this.setState({ add: true });
      return;
    }
    var method = "";
    var title = "";
    var id = "";
    if (this.state.cash) {
      method = "CASH";
      title = this.state.cash_data.title;
      id = this.state.cash_data.id;
    } else {
      method = "BANK";
      title = this.state.bank_data.title;
      id = this.state.bank_data.id;
    }
    var paymentNoId = this.state.paymentNo;

    var data = {
      id: this.state.paymentNo,
      ac_title: this.state.ac_title_data.title,
      ac_id: this.state.ac_title_data.id,
      ac_type: this.state.ac_title_data.type,
      date: this.state.date,
      amount: this.state.amount,
      payment_method: method,
      payment_title: title,
      purchase_invoiceno: this.state.purchase_invoiceno,
      payment_id: id,
      cheque_no: this.state.cheque_no,
      cheque_date: this.state.cheque_date,
      wht_tax: this.state.wht_tax,
      remarks: this.state.remarks,
      extension: this.state.extension,
      file: this.state.file,
      userId: localStorage.getItem("id"),
    };
    // console.log(data)

    fetch(url + "inventry/payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        this.setState({ title: "Alert" });
        this.setState({ body: "Payment Already Exists!!" });
        this.setState({ add: true });
      } else {
        document
          .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
          .click();
        document
          .getElementsByClassName("MuiAutocomplete-clearIndicator")[1]
          .click();

        this.setState({ cash_data: null });
        this.setState({ bank_data: null });
        this.setState({ ac_title_data: null });
        this.setState({ amount: 0 });
        this.setState({ wht_tax: 0 });
        this.setState({ cheque_no: "" });
        this.setState({ date: new Date() });
        this.setState({ cheque_date: new Date() });
        this.setState({ remarks: "" });
        this.setState({ title: "Payment " + paymentNoId });
        this.setState({ body: "Payment Added!!" });
        this.setState({ add: true });

        fetch(url + "inventry/payment/PaymentNo", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status == 400) {
              alert("No Category Found");
            } else {
              return res.json();
            }
          })
          .then((counter) => {
            this.setState({ paymentNo: counter.PaymentNo });
            setTimeout(() => {
              this.handleClose();
            }, 2000);
          });
      }
    });
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
      instance.focus();
    } catch (e) {
      try {
        const instance = this._typeahead_2.getInstance();
        instance.clear();
        instance.focus();
      } catch (e) {
        console.log(e);
      }
    }
  };

  Accounts = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="filtering-example"
          clearButton={true}
          autoFocus={true}
          ignoreDiacritics={true}
          onChange={(event, text) => {
            var text = text + "";
            var id = this.state.all_accounts[text];
            //console.log("Data")
            this.setState({ ac_title_data: id });

            fetch(url + "inventry/payment/invoices", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                id: id,
              }),
            })
              .then((res) => {
                if (res.status === 400) {
                  alert("No Category Found");
                } else {
                  return res.json();
                }
              })
              .then((invoiceData) => {
                console.log("Invoice Information is :", invoiceData);

                const invoiceNumbers = invoiceData.map(
                  (invoice) => invoice.invoiceno
                );

                this.setState({
                  purchases: invoiceNumbers,
                });
              });
          }}
          options={this.state.options}
          getOptionLabel={(option) => truncateText(option, 18)} // Truncating the option label
          placeholder="A/C Title..."
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="A/C Title..." />
          )}
        />
      </div>
    );
  };

  PurchaseInvoices = () => {
    const truncateText = (text, maxChars) => {
      if (text.length > maxChars) {
        return text.slice(0, maxChars) + " ...";
      } else {
        return text;
      }
    };
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="filtering-example"
          clearButton={true}
          autoFocus={true}
          size="small"
          ignoreDiacritics={true}
          onChange={(event, text) => {
            this.setState({ purchase_invoiceno: text });
          }}
          options={this.state.purchases}
          getOptionLabel={(option) => truncateText(option, 20)} // Truncating the option label
          placeholder="Purchase Invoice No...."
          renderInput={(params) => (
            <TextField {...params} label="Purchase Invoice No..." />
          )}
        />
      </div>
    );
  };

  

  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  render() {
    return (
      <>
        <Dialog
          open={this.state.open}
          fullWidth={true}
          maxWidth="sm"
          onClose={this.handleClose}
          // scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>Create Payment</DialogTitle>
          <DialogContent>
            <Row>
              <Grid item md={12}>
                {/* <AppBar
              position="static"
              style={{ backgroundColor: "#01579B", color: "white" }}
            >
              <Toolbar>
                <div></div>

                <Typography
                  variant="h6"
                  style={{ flexGrow: 1, textAlign: "center" }}
                >
                  Create Payment
                </Typography>
                <Button
                  color="inherit"
                  style={{ borderColor: "#fff", border: "1px solid white" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Toolbar>
            </AppBar> */}

                <form onSubmit={this.onSubmit} className="Notifications">
                  <Grid container spacing={1} controlId="formHorizontalEmail">
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em" }}>Date</b>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            value={this.state.date}
                            onChange={(date) => {
                              this.setState({ date: date });
                            }}
                            renderInput={(params) => (
                              <TextField size="small" fullWidth {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em" }}>Payment No</b>
                      <div>
                        <TextField
                          required
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          fullWidth
                          size="small"
                          value={this.state.paymentNo}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em", color: "red" }}>
                        A/C Title:
                      </b>
                      {this.Accounts()}
                    </Grid>
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em" }}>
                        Amount{" "}
                        <b style={{ color: "red" }}>
                          {" "}
                          {this.numberWithCommas(this.state.amount)}
                        </b>
                      </b>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        value={this.state.amount}
                        onFocus={() => {
                          if (this.state.amount === 0) {
                            this.setState({ amount: "" });
                          }
                        }}
                        onBlur={() => {
                          if (this.state.amount === "") {
                            this.setState({ amount: 0 });
                          }
                        }}
                        onChange={(e) => {
                          this.setState({ amount: e.target.value });
                        }}
                        placeholder="Amount..."
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em" }}>Account Selection</b>
                      <Grid container>
                        <Grid item sm={6}>
                          <Form.Check
                            custom
                            inline
                            checked={this.state.cash}
                            onChange={() => {
                              this.Clear_Text();
                              this.setState({ cash: !this.state.cash });
                              this.setState({ bank: !this.state.bank });
                            }}
                            type="checkbox"
                            label={<b style={{ fontSize: "1em" }}> CASH </b>}
                            name="cash"
                            id="cash"
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Form.Check
                            custom
                            inline
                            checked={this.state.bank}
                            onChange={() => {
                              this.Clear_Text();
                              this.setState({ cash: !this.state.cash });

                              this.setState({ bank: !this.state.bank });
                            }}
                            type="checkbox"
                            label={<b style={{ fontSize: "1em" }}> BANK </b>}
                            name="bank"
                            id="bank"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em" }}>WHT Tax</b>
                      <TextField
                        required
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                        fullWidth
                        size="small"
                        value={this.state.wht_tax}
                        onFocus={() => {
                          if (this.state.wht_tax === 0) {
                            this.setState({ wht_tax: "" });
                          }
                        }}
                        onBlur={() => {
                          if (this.state.wht_tax === "") {
                            this.setState({ wht_tax: 0 });
                          }
                        }}
                        onChange={(e) => {
                          this.setState({ wht_tax: e.target.value });
                        }}
                        placeholder="WHT Tax..."
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <b style={{ fontSize: "1em", color: "green" }}>
                        {this.state.cash ? "Cash Account" : "Bank Account"}
                      </b>

                      {this.state.cash ? (
                        <div>
                          <Autocomplete
                            caseSensitive={false}
                            id="filtering-example"
                            ignoreDiacritics={true}
                            ref={(ref) => (this._typeahead_1 = ref)}
                            onChange={(event, text) => {
                              var text = text + "";
                              //console.log(name)
                              var id = this.state.all_payment[text];
                              //console.log("Payment Cash")
                              //console.log(id)
                              this.setState({ cash_data: id });
                            }}
                            options={this.state.cash_accounts}
                            placeholder="Cash Accounts..."
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} label="Cash Accounts..." />
                            )}
                          />
                        </div>
                      ) : (
                        <div>
                          <Autocomplete
                            caseSensitive={false}
                            id="filtering"
                            size="small"
                            ref={(ref) => (this._typeahead_2 = ref)}
                            ignoreDiacritics={true}
                            options={this.state.bank_accounts}
                            onChange={(event, text) => {
                              if (this.state.bank) {
                                var text = text + "";
                                //console.log(name)
                                var id = this.state.all_payment[text];
                                //console.log("Payment Bank")
                                this.setState({ bank_data: id });
                              }
                            }}
                            placeholder="Bank Accounts..."
                            renderInput={(params) => (
                              <TextField {...params} label="Cash Accounts..." />
                            )}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      <p>
                        <b style={{ fontSize: "1em", color: "blue" }}>
                          Purchase Invoices
                        </b>{" "}
                        - {this.state.purchase_invoiceno}
                      </p>
                      {this.PurchaseInvoices()}
                    </Grid>
                    <Grid item sm={6}>
                    <div>
                        <input
                          id="file-upload"
                          type="file"
                          accept="image/*,application/pdf"
                          onChange={this.handlePicture}
                        />
                      </div>
                    </Grid>
                    {this.state.cash ? (
                      <></>
                    ) : (
                      <>
                        <Grid item sm={6}>
                          <b style={{ fontSize: "1em" }}>Cheque No:</b>
                          <TextField
                            size="small"
                            required
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            value={this.state.cheque_no}
                            fullWidth
                            onFocus={() => {
                              if (this.state.cheque_no === 0) {
                                this.setState({ cheque_no: "" });
                              }
                            }}
                            onBlur={() => {
                              if (this.state.cheque_no === "") {
                                this.setState({ cheque_no: 0 });
                              }
                            }}
                            onChange={(e) => {
                              this.setState({
                                cheque_no: e.target.value,
                              });
                            }}
                            placeholder="Cheque No..."
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <b style={{ fontSize: "1em" }}>Date of Cheque No:</b>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={this.state.date}
                                onChange={(date) => {
                                  this.setState({ date: date });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Grid>
                      </>
                    )}
                    <Grid item sm={12}>
                      <b style={{ fontSize: "1em" }}>Remarks</b>
                      <br />
                      <Form.Control
                        as="textarea"
                        rows="3"
                        value={this.state.remarks}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          this.setState({ remarks: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
                <Modal
                  open={this.state.add}
                  onClose={() => this.setState({ add: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {this.state.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {this.state.body}
                    </Typography>
                  </Box>
                </Modal>
              </Grid>
            </Row>{" "}
          </DialogContent>
          <DialogActions>
            <Button
              // fullWidth
              variant="contained"
              size="lg"
              block
              type="submit"
              onClick={this.onSubmit}
            >
              Save Record
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default Payment;
