import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import NotFound from "./pages/Page404";
import DashboardApp from "./pages/DashboardApp";

// Integration
import ItemNavigation from "./layouts/itemManagement/navigation";
import ProfitLossNavigation from "./layouts/profit&Loss/navigation";
import ClientManagement from "./layouts/clientManagement/clientManage";
import Purchase from "./layouts/purchase/purchaseManage";
import Sale from "./layouts/sale/saleManage";
import SaleReturn from "./layouts/saleReturn/saleReturnManage";
import PurchaseReturn from "./layouts/purchaseReturn/purchaseReturnManage";
import Receipt from "./layouts/receipt/receiptManage";
import Payment from "./layouts/payment/paymentManage";
import JV from "./layouts/jv/JVManage";
import GeneralLedger from "./layouts/reporting/generalLedger";
// import ChartofAccounts from "./layouts/reporting/chartOfAccounts";
import ChartofAccounts from "./layouts/chartOfAccounts/navigation";
import TrailBalance from "./layouts/reporting/trailBalance";
import ProfitLoss from "./layouts/reporting/profitLoss";
import BalanceSheetNavigation from "./layouts/balanceSheet/navigation";
import PurchaseReport from "./layouts/reporting/purchaseReport";
import SaleReport from "./layouts/reporting/saleReport";
import ProfitAnalysis from "./layouts/reporting/profitAnalysis";
import StockManagement from "./layouts/reporting/stockManagement";
import CashInHand from "./layouts/reporting/cashInHand";
import MinimumStock from "./layouts/itemManagement/minimumStock";
import ReceivedReport from "./layouts/reporting/receivedReport";
import PaymentReport from "./layouts/reporting/paymentReport";

import Configuration from "./layouts/configuration/Config";
import UserRoles from "./layouts/userRoles/addUser"

// Daily Report
import DailyReport from "./layouts/dailyReport/daily";

// HR
import Employee from "./layouts/hr/employee/HrMainPage";
import Leave from "./layouts/hr/leaves/LeaveMainPage";
import Attendance from "./layouts/hr/attendance/Attendance";
// ----------------------------------------------------------------------

import TopSales from "./layouts/itemManagement/topSales";

export default function Router() {
  return useRoutes([
    localStorage.getItem("id") != null &&
    localStorage.getItem("id") != undefined
      ? {
          path: "/dashboard",
          element: <DashboardLayout />,
          children: [
            { path: "app", element: <TopSales /> },
            { path: "hrm/employee", element: <Employee /> },
            { path: "hrm/leaves", element: <Leave /> },
            { path: "hrm/attendance", element: <Attendance /> },

            {
              path: "topSales",
              element: (
                <span>
                  {" "}
                  <TopSales />{" "}
                </span>
              ),
            },
            {
              path: "manageItem",
              element: (
                <span>
                  {" "}
                  <ItemNavigation />{" "}
                </span>
              ),
            },
            {
              path: "clientManage",
              element: (
                <span>
                  {" "}
                  <ClientManagement />{" "}
                </span>
              ),
            },
            {
              path: "purchase",
              element: (
                <span>
                  <Purchase />{" "}
                </span>
              ),
            },
            {
              path: "sale",
              element: (
                <span>
                  {" "}
                  <Sale />{" "}
                </span>
              ),
            },
            {
              path: "salereturn",
              element: (
                <span>
                  {" "}
                  <SaleReturn />{" "}
                </span>
              ),
            },
            {
              path: "purchasereturn",
              element: (
                <span>
                  {" "}
                  <PurchaseReturn />{" "}
                </span>
              ),
            },
            {
              path: "receipt",
              element: (
                <span>
                  {" "}
                  <Receipt />{" "}
                </span>
              ),
            },
            {
              path: "payment",
              element: (
                <span>
                  {" "}
                  <Payment />{" "}
                </span>
              ),
            },
            {
              path: "jv",
              element: (
                <span>
                  {" "}
                  <JV />{" "}
                </span>
              ),
            },
            {
              path: "userRoles",
              element: (
                <span>
                  {" "}
                  <UserRoles />{" "}
                </span>
              ),
            },
            {
              path: "configuration",
              element: (
                <span>
                  {" "}
                  <Configuration />{" "}
                </span>
              ),
            },
            {
              path: "generalLedger",
              element: (
                <span>
                  {" "}
                  <GeneralLedger />{" "}
                </span>
              ),
            },
            {
              path: "chartOfAccounts",
              element: (
                <span>
                  {" "}
                  <ChartofAccounts />{" "}
                </span>
              ),
            },
            {
              path: "trailBalance",
              element: (
                <span>
                  {" "}
                  <TrailBalance />{" "}
                </span>
              ),
            },
            {
              path: "profitLoss",
              element: (
                <span>
                  {" "}
                  <ProfitLossNavigation />{" "}
                </span>
              ),
            },
            {
              path: "balanceSheet",
              element: (
                <span>
                  {" "}
                  <BalanceSheetNavigation />{" "}
                </span>
              ),
            },
            {
              path: "purchaseReport",
              element: (
                <span>
                  {" "}
                  <PurchaseReport />{" "}
                </span>
              ),
            },
            {
              path: "saleReport",
              element: (
                <span>
                  {" "}
                  <SaleReport />{" "}
                </span>
              ),
            },
            { path: "profitAnalysis", element: <ProfitAnalysis /> },
            {
              path: "stock",
              element: (
                <span>
                  {" "}
                  <StockManagement />{" "}
                </span>
              ),
            },
            { path: "cashInHand", element: <CashInHand /> },
            { path: "dailyReport", element: <DailyReport /> },
            { path: "minimumStock", element: <MinimumStock /> },
            { path: "receivedReport", element: <ReceivedReport /> },
            { path: "paymentReport", element: <PaymentReport /> },
            { path: "logout", element: <Logout /> },
          
          ],
        }
      : {},
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "/",
          element:
            localStorage.getItem("id") == null ||
            localStorage.getItem("id") == undefined ? (
              <Login />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: "login",
          element:
            localStorage.getItem("id") == null ||
            localStorage.getItem("id") == undefined ? (
              <Login />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
