import * as React from 'react';
import { useState, useEffect } from 'react';
// date and time
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// @mui

import {
  Card,
  Box,
  Autocomplete,
  Stack,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Typography,
  TextField,
  Modal,
  Grid,
} from '@mui/material';
// components


export default function ApplyLeave(props) {
  // date n timne
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveFrom, setLeaveFrom] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [leaveTo, setLeaveTo] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [result, setResult] = useState(null);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // ---------------------Modal-------------------------------
  const [openModal, setOpenModal] = useState(true);
  //   const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const [formData, setFormData] = useState({
    Name: '',
    Address: '',
    Phone: '',
    Designation: '',
    Department: '',
    Leave_From: '',
    Leave_To: '',
    NoOfDays: '',
    Description: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://192.168.18.135:3000/addApplications', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
        setFormData({
          Name: '',
          Address: '',
          Phone: '',
          Designation: '',
          Department: '',
          Leave_From: '',
          Leave_To: '',
          NoOfDays: '',
          Description: '',
        });
      })
      .catch(() => {
        setResult({
          success: false,
          message: 'Something went wrong. Try again later',
        });
      });

    console.log('You Leave Form has beem submitted:', formData);
  };
  // const handleChangeData = (event) => {
  //   const { name, value } = event.target;
  //   if (name === 'Leave_From') {
  //     setLeaveFrom(value);
  //   } else if (name === 'Leave_To') {
  //     setLeaveTo(value);
  //   }
  
  //   setFormData((formData) => ({
  //     ...formData,
  //     [name]: value,
  //     Leave_From: leaveFrom.toISOString(),
  //     Leave_To: leaveTo.toISOString(),
  //   }));
  // };
  const handleChangeData = (event) => {
  const { name, value } = event.target;
  if (name === 'Leave_From') {
    setLeaveFrom(dayjs(value));
  } else if (name === 'Leave_To') {
    setLeaveTo(dayjs(value));
  }

  setFormData((formData) => ({
    ...formData,
    [name]: value,
    Leave_From: leaveFrom.toISOString(),
    Leave_To: leaveTo.toISOString(),
  }));
};


  //   ----- Image -----
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Modal open={openModal} onClose={props.onClose}>
              <Box sx={style}>
                <Typography sx={{ textAlign: 'center' }} id="modal-modal-title" variant="h3" component="h2">
                  Leave Application Form
                </Typography >
                <Typography>
                  {result && <p className={`${result.success ? 'success' : 'error'}`}>{result.message}</p>}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <form onSubmit={handleSubmit}>
                    <Grid lg={12} item container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          sx={{ width: 250 }}
                          id="stabdard-name-input"
                          label="Name"
                          name="Name"
                          value={formData.Name}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-text"
                          variant="standard"
                        />{' '}
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="standard-designation-input"
                          label="Designation"
                          type="text"
                          name="Designation"
                          value={formData.Designation}
                          onChange={handleChangeData}
                          autoComplete="current-designation"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="standard-department-input"
                          label="Department"
                          type="text"
                          name="Department"
                          value={formData.Department}
                          onChange={handleChangeData}
                          autoComplete="current-department"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="standard-phone-input"
                          label="Phone"
                          name="Phone"
                          value={formData.Phone}
                          onChange={handleChangeData}
                          type="text"
                          autoComplete="current-phone"
                          variant="standard"
                        />
                        <br />
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">Leave Status</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value="full"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Full Day"
                              control={<Radio />}
                              name="LeaveStatus"
                              onChange={handleChangeData}
                              label="Full Day"
                            />
                            <FormControlLabel
                              value="Short Day"
                              control={<Radio />}
                              name="LeaveStatus"
                              onChange={handleChangeData}
                              label="Short Day"
                            />
                          </RadioGroup>
                        </FormControl>
                        <br />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Leave From
                        </Typography>{' '}
                        <DateTimePicker
                          value={leaveFrom}
                          name='Leave_From'
                          type='date'
                          onChange={(newValue) => {
                            setLeaveFrom(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="small" name='Leave_From' type="date" sx={{ width: 300 }} {...params} />
                          )}
                        />{' '}
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Leave To
                        </Typography>{' '}
                        <DateTimePicker
                          value={leaveTo}
                          name='Leave_From'
                          type='date'
                          onChange={(newValue) => {
                            setLeaveTo(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="small" type="date" name='Leave_From' sx={{ width: 300 }} {...params} />
                          )}
                        />{' '}
                        <br />
                        <br />
                        <TextField
                          sx={{ width: 250 }}
                          id="outlined-days-input"
                          label="No. of Days"
                          type="number"
                          name="NoOfDays"
                          value={formData.NoOfDays}
                          onChange={handleChangeData}
                          autoComplete="current-days"
                          variant="outlined"
                          size="small"
                        />
                        <br />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Description
                        </Typography>{' '}
                        <TextField
                          sx={{ width: 300 }}
                          id="standard-description-input"
                          type="text"
                          name="Description"
                          value={formData.Description}
                          onChange={handleChangeData}
                          variant="outlined"
                          placeholder="Reason of Leave"
                          multiline
                          rows={3}
                          rowsMax={10}
                        />
                        <br />
                        <br />
                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Box>
            </Modal>
          </Stack>
        </LocalizationProvider>
      </Container>
    </>
  );
}
