import * as React from "react";
import { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";

// ServerURL
import url from "src/serverURL.js";
const useStyles = makeStyles({
  dialog: {
    position: "fixed",
    top: "0",
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddItem({
  handleClick,
  handleClickError,
  handleCloseModal,
  isOpen,
}) {
  const [form, setForm] = useState({});
  const [category, setCategories] = useState([]);
  // State for modal
  const [openModal, setOpenModal] = useState(false);

  // Function to open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const classes = useStyles();

  /// -->> Function to manage all the Inputs
  const handleInputChange = (fieldName, value) => {
    // Remove leading spaces and allow only one space
    const sanitizedValue = value.replace(/^\s+/g, "").replace(/\s+/g, " ");
    setForm({
      ...form,
      [fieldName]: sanitizedValue,
    });
  };

  // -->> Date Function
  const getDate = () => {
    return `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`;
  };

  // -->> Submit Data onto the Server
  const submit = () => {
    if (form.name === undefined || form.name.trim() === "") {
      handleClickError("Invalid Product Name");
      return;
    }
    if (form.category === undefined || form.category === "") {
      handleClickError("Please Select a Category");
      return;
    }
    if (form.description === undefined || form.description.trim() === "") {
      handleClickError("Invalid Description");
      return;
    }
    if (
      form.min_stock === "" ||
      form.min_stock === undefined ||
      parseInt(form.min_stock) < 1
    ) {
      handleClickError(
        "Minimum Stock Quantity must be greater than or equal to 1"
      );
      return;
    }
    if (form.cost === undefined || form.cost.trim() === "") {
      handleClickError("Invalid Purchase Price");
      return;
    }
    if (form.rate === undefined || form.rate.trim() === "") {
      handleClickError("Invalid Sale Price");
      return;
    }
    if (parseFloat(form.rate) < parseFloat(form.cost)) {
      handleClickError(
        "Sale Price must be greater than or equal to Purchase Price."
      );
      return;
    }

    var data = {
      name: form.name,
      category: form.category,
      //   stock: form.stock === undefined ? "" : form.stock,
      barcode: form.barcode === undefined ? "" : form.barcode,
      cost: form.cost === undefined ? "" : form.cost,
      description: form.description === undefined ? "" : form.description,
      min_stock: form.min_stock === undefined ? "" : form.min_stock,
      rate: form.rate === undefined ? "" : form.rate,
      userId: localStorage.getItem("id"),
    };
    fetch(url + "inventry/item", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 409) {
        handleClickError("Item Already Exists !");
        return;
      }
      if (res.status == 400) {
        handleClickError();
        return;
      }
      setForm({});
      handleClick("Item Added Successfully !");
      handleCloseModal();
    });
  };
  useEffect(() => {
    fetch(url + "inventry/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((categories) => {
        const box = categories.filter((data) => ({
          name: data.name,
          code: data.code,
          category: data.category,
        }));
        setCategories(box);
      });
  }, []);

  const truncateText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + " ...";
    } else {
      return text;
    }
  };

  return (
    <>
      <Dialog
        // open={openModal}
        open={isOpen}
        // onClose={handleCloseModal}
        // fullWidth
        // maxWidth="lg"
        // classes={{ paper: classes.dialog }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent style={{ maxWidth: "500px" }}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <TextField
                label="Date"
                variant="filled"
                disabled
                value={getDate()}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                required
                label="Name"
                variant="filled"
                value={form.name === undefined ? "" : form.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl sx={{ minWidth: 215 }}>
                <TextField
                  margin="normal"
                  id="outlined-select-currency"
                  select
                  label="Select Category"
                  variant="filled"
                  style={{ marginTop: 0 }}
                  value={form.category}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      category: e.target.value,
                    });
                  }}
                  // autoFocus
                >
                  {/* <MenuItem value={undefined}>Select</MenuItem> */}
                  <MenuItem disabled value={undefined}>
                    Select Category
                  </MenuItem>
                  {/* {category.map((row) => {
                    return <MenuItem value={row.name}>{row.name}</MenuItem>;
                  })} */}
                  {category.map((row) => (
                    <MenuItem key={row.name} value={row.name}>
                      {truncateText(row.name, 18)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                required
                label="Description"
                variant="filled"
                value={form.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
                inputProps={{ maxLength: 32 }}
              />{" "}
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                label="Barcode"
                required
                type="text"
                variant="filled"
                value={form.barcode}
                onChange={(e) => handleInputChange("barcode", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                label="Minimum Stock Quantity"
                required
                variant="filled"
                value={form.min_stock}
                type="number"
                onChange={(e) => handleInputChange("min_stock", e.target.value)}
                inputProps={{ maxLength: 32 }}
              />{" "}
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                label="Purchase Price"
                required
                variant="filled"
                type="number"
                value={form.cost}
                onChange={(e) => handleInputChange("cost", e.target.value)}
              />{" "}
            </Grid>
            <Grid item md={6}>
              <TextField
                id="filled-required"
                label="Sale Price"
                required
                variant="filled"
                type="number"
                value={form.rate}
                onChange={(e) => handleInputChange("rate", e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={submit} variant="contained">
            Add Item
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
